export const GET_CATEGORIES = "category.GET_CATEGORIES";
export const GET_SUB_CATEGORIES = "category.GET_SUB_CATEGORIES";
export const GET_SUB_SUB_CATEGORIES = "category.GET_SUB_SUB_CATEGORIES";

export const GET_CATEGORY_DETAILS = "category.GET_CATEGORY_DETAILS";
export const SET_ALL_CATEGORIES = "SET_ALL_CATEGORIES";
export const SET_SUB_SUB_CATEGORIES = "SET_SUB_SUB_CATEGORIES";
export const SET_SUB_CATEGORIES = "SET_SUB_CATEGORIES";

export function getAllCategories() {
  return (dispatch) => {
    dispatch({
      type: GET_CATEGORIES,
    });
  };
}

export function getSubCategories(categoryId) {
  return (dispatch) => {
    dispatch({
      type: GET_SUB_CATEGORIES,
      data: categoryId,
    });
  };
}

export function getCategoryDetails(categoryId) {
  return (dispatch) => {
    dispatch({
      type: GET_CATEGORY_DETAILS,
      data: categoryId,
    });
  };
}
export function getSubSubCategories(subCategoryId) {
  return (dispatch) => {
    dispatch({
      type: GET_SUB_SUB_CATEGORIES,
      data: subCategoryId,
    });
  };
}

export function setAllCategories(categories = {}) {
  return (dispatch) => {
    dispatch({
      type: SET_ALL_CATEGORIES,
      data: categories,
    });
  };
}

export function setSubCategories(categories = {}) {
  return (dispatch) => {
    dispatch({
      type: SET_SUB_CATEGORIES,
      data: categories,
    });
  };
}

export function setSubSubCategories(categories = {}) {
  return (dispatch) => {
    dispatch({
      type: SET_SUB_SUB_CATEGORIES,
      data: categories,
    });
  };
}
