import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import "semantic-ui-css/semantic.min.css";

import ScrollToTop from "components/ScrollToTop";
import RespHeader from "containers/Header";
import Footer from "containers/Footer";

import Routes from "Routes";
import { store } from "stores/UserStore";

import "styles/index.scss";
import "./App.scss";

function App() {
  return (
    <Provider store={store}>
      <Router>
        <ScrollToTop />
        <div className="main">
          <RespHeader />
          <Routes />
          <Footer />
        </div>
      </Router>
    </Provider>
  );
}

export default App;
