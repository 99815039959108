import { authorizedGet, authorizedPost } from "api";
import {
  GET_CONTRACT,
  ACCEPT_CONTRACT,
  WITHDRAW_CONTRACT,
  UPDATE_CONTRACT,
  RAISE_DISPUTE,
} from "api/job";
import { GET_PAYMENT_LINK, VERIFY_PAYMENT } from "api/payment";
import { SUBMIT_FOR_REVIEW, UPDATE_REVIEW } from "api/milestone";

export const getContract = (id) =>
  authorizedGet(`${GET_CONTRACT.replace(":id", id)}`);

export const acceptContract = (id, data) =>
  authorizedPost(`${ACCEPT_CONTRACT.replace(":id", id)}`, data);

export const withdrawContract = (id, data) =>
  authorizedPost(`${WITHDRAW_CONTRACT.replace(":id", id)}`, data);

export const updateContract = (id, data) =>
  authorizedPost(`${UPDATE_CONTRACT.replace(":id", id)}`, data);

export const getPaymentLink = (data) =>
  authorizedPost(`${GET_PAYMENT_LINK}`, data);

export const verifyPayment = (data) => authorizedPost(VERIFY_PAYMENT, data);

export const submitForReview = (data) =>
  authorizedPost(SUBMIT_FOR_REVIEW, data);

export const acceptRejectReview = (data) => authorizedPost(UPDATE_REVIEW, data);

export const raiseDispute = (id, data) =>
  authorizedPost(`${RAISE_DISPUTE.replace(":id", id)}`, data);
