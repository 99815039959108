import React from "react";
import { Link } from "react-router-dom";
import { Button, Popup } from "semantic-ui-react";

import { getNotifications, dismissNotification } from "../../actions";

import "./Notifications.scss";

function Notifications({ userId }) {
  const [active, setActive] = React.useState(false);
  const [notifications, setNotifications] = React.useState([]);

  const getNotifcationsAction = React.useCallback(() => {
    getNotifications(userId).then(({ data }) => {
      setNotifications(data.notifications);
    });
  }, [userId]);

  const dismissNotificationAction = React.useCallback((notificationId) => {
    setNotifications((notification) =>
      notification.filter((n) => n._id !== notificationId)
    );
    dismissNotification(notificationId)
      .then(() => {})
      .catch((e) => console.log("error occurred while dismissing"));
  }, []);

  React.useEffect(() => {
    const timer = setInterval(getNotifcationsAction, 120000);
    return () => clearInterval(timer);
  }, [getNotifcationsAction]);

  return (
    <>
      <Popup
        basic
        hoverable
        open={active}
        position="top right"
        on="click"
        mouseLeaveDelay={10000}
        style={{ zIndex: "999999", padding: 0 }}
        onClose={() => setActive(false)}
        trigger={
          <div className="notifications-bell">
            <Button
              icon="bell outline"
              className="notifications-bell-button"
              onClick={() => setActive(!active)}
            />
            {!!notifications.length && (
              <div className="notifications-bell-text">
                {notifications.length}
              </div>
            )}
          </div>
        }
      >
        <Popup.Content className="notifications-content">
          {!notifications.length ? (
            <div className="notifications-empty">No notifications</div>
          ) : (
            <>
              {notifications.map((n) => (
                <div className="notifications-message" key={`notify-${n._id}`}>
                  <div className="notifications-message-text">{n.message}</div>
                  <Link
                    className="notifications-message-link"
                    to={n.redirectLink}
                  >
                    {n.notificationText}
                  </Link>
                  <Button
                    className="notifications-message-close"
                    icon="close"
                    onClick={() => dismissNotificationAction(n._id)}
                  />
                </div>
              ))}
            </>
          )}
        </Popup.Content>
      </Popup>
    </>
  );
}

export default Notifications;
