import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { Dropdown } from "semantic-ui-react";

import { PROFILE_LINKS } from "../../constants";
import "./HeaderProfile.scss";

function HeaderProfile({ isProfileSetupDone, handleLogout }) {
  const history = useHistory();

  return (
    <Dropdown className="headerProfile" text="My account">
      <Dropdown.Menu>
        {isProfileSetupDone &&
          PROFILE_LINKS.map((link) => (
            <Dropdown.Item onClick={() => history.push(link.url)}>
              <div>{link.text}</div>
            </Dropdown.Item>
          ))}
        <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

HeaderProfile.propTypes = {
  isProfileSetupDone: PropTypes.bool.isRequired,
  handleLogout: PropTypes.func.isRequired,
};

export default HeaderProfile;
