import {
  getIsClient,
  getUserId,
  getEmail,
  getName,
  getPhone
} from "selectors/userSelector";

export const stateToProps = (state) => {
  return {
    isClient: getIsClient(state),
    userId: getUserId(state),
    name: getName(state),
    email: getEmail(state),
    phone: getPhone(state),
  };
};
