import React from "react";

import Loader from "components/Loader";

import "./Confirming.scss";

function Confirming() {
  return (
    <>
      <Loader />
      <h3 className="confirming-message">Confirming...</h3>
    </>
  );
}

export default Confirming;
