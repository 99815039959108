import React from "react";
import { useSelector } from "react-redux";
import debounce from "lodash.debounce";
import { Loader } from "semantic-ui-react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { getAllCategory } from "containers/JobPost/actions/index";

import Filters from "./components/Filters";
import JobList from "./components/JobList";
import JobDetailWrapper from "./components/JobDetailWrapper";

import { getAllJobPosts } from "./actions";
import { stateToProps } from "./maps";
import { PAGE_SIZE } from "./constants";

import "./JobSearch.scss";

function JobSearch() {
  const { userId } = useSelector(stateToProps);
  const [posts, setPosts] = React.useState([]);
  const [isFetching, setIsFetching] = React.useState(false);
  const [isVisible, setIsVisible] = React.useState(false);
  const [selectedJobId, setSelectedJobId] = React.useState("");
  // eslint-disable-next-line no-unused-vars
  const [activePage, setActivePage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1);
  const [filters, setFilters] = React.useState({
    experienceLevel: [],
    projectLengthSelected: [],
    budgetSelected: [],
    categoriesSelected: [],
  });
  const [filterCategories, setFilterCategories] = React.useState([]);
  const [searchText, setSearchText] = React.useState("");

  React.useEffect(() => {
    getAllCategory().then((response) => {
      setFilterCategories(
        response.data.data.map(({ title, _id }) => ({
          text: title,
          value: _id,
        }))
      );
    });

    return () =>
      document.getElementsByTagName("body")[0].classList.remove("no-scroll");
  }, []);

  const getJobPosts = React.useCallback(() => {
    const query = {
      userId,
      currentPage: activePage,
      pageSize: PAGE_SIZE,
    };

    if (filters.experienceLevel.length) {
      query.experienceLevel = filters.experienceLevel.join(",");
    }

    if (filters.projectLengthSelected.length) {
      query.projectLengthSelected = filters.projectLengthSelected.join(",");
    }

    if (filters.budgetSelected.length) {
      query.budgetSelected = filters.budgetSelected.join(",");
    }

    if (filters.categoriesSelected.length) {
      query.categoriesSelected = filters.categoriesSelected.join(",");
    }

    if (searchText) {
      query.search = searchText;
    }

    setIsFetching(true);

    getAllJobPosts(query)
      .then((response) => {
        setIsFetching(false);
        setPosts(response.data.posts);
        setTotalPages(response.data.totalPages);
      })
      .catch((error) => {
        try {
          toast.error(
            `Fetching job posts failed! ${error.response.data.error}`
          );
        } catch (e) {
          toast.error(`Fetching job posts failed!`);
        }

        setIsFetching(false);
      });
  }, [
    activePage,
    filters.budgetSelected,
    filters.categoriesSelected,
    filters.experienceLevel,
    filters.projectLengthSelected,
    searchText,
    userId,
  ]);

  React.useEffect(() => {
    getJobPosts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePage, filters]);

  const handleFilters = React.useCallback(
    (e, data, key) => {
      if (key === "categoriesSelected") {
        setFilters({
          ...filters,
          [key]: data,
        });

        return;
      }

      const { value } = data;
      let filterArray = [...filters[key]];

      if (filterArray.includes(value)) {
        filterArray = filterArray.filter((arrayValue) => arrayValue !== value);
      } else {
        filterArray.push(value);
      }

      setFilters({
        ...filters,
        [key]: filterArray,
      });
    },
    [filters]
  );

  const debouncedSearchCall = debounce(getJobPosts, 1000);

  React.useEffect(() => {
    debouncedSearchCall();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  const handleOnSearch = (e, data) => {
    setSearchText(data.value);
  };

  const handleOutsideClick = React.useCallback(() => {
    document.getElementsByTagName("body")[0].classList.remove("no-scroll");
    setIsVisible(false);
  }, []);

  const handleJobClick = React.useCallback((job) => {
    document.getElementsByTagName("body")[0].classList.add("no-scroll");
    setSelectedJobId(job._id);
    setIsVisible(true);
  }, []);

  const handlePageChange = React.useCallback((_, data) => {
    const { activePage } = data;
    setActivePage(activePage);
  }, []);

  const visibleClassName = isVisible ? "active" : "";
  return (
    <>
      <div className="jobSearch">
        <ToastContainer
          className="toast"
          position="bottom-center"
          autoClose={2500}
          hideProgressBar
        />
        <div className={`jobSearch-wrapper ${visibleClassName}`}>
          <Filters
            {...filters}
            filterCategories={filterCategories}
            handleOnChange={handleFilters}
          />
          <JobList
            jobPosts={posts}
            activePage={activePage}
            totalPages={totalPages}
            handleOnClick={handleJobClick}
            handlePageChange={handlePageChange}
            handleOnSearch={handleOnSearch}
          >
            <Loader active={isFetching} />
          </JobList>
        </div>
      </div>
      <JobDetailWrapper
        visible={isVisible}
        selectedJobId={selectedJobId}
        onOutSideClick={handleOutsideClick}
      />
    </>
  );
}

export default JobSearch;
