import React from "react";
import { Button, Input } from "semantic-ui-react";

import "./ChatPane.scss";

function ChatPane({ activeChat, currentUserId, onMessageSubmit }) {
  const [message, setMessage] = React.useState("");
  const chatboxRef = React.createRef();

  const handleMessageSubmit = React.useCallback(() => {
    onMessageSubmit(message, () => {
      setMessage("");
    });
  }, [message, onMessageSubmit]);

  React.useEffect(() => {
    if (chatboxRef && chatboxRef.current) {
      const element = chatboxRef.current;
      element.scroll({
        top: element.scrollHeight,
        left: 0,
        behavior: "smooth",
      });
    }
  }, [activeChat?.messages?.length]);

  return (
    <div className="chatPane">
      {!activeChat ? (
        <div className="chatPane-empty">Select a chat from the left side</div>
      ) : (
        <>
          <div className="chatPane-message-wrapper" ref={chatboxRef}>
            {activeChat?.messages.map((msg) => (
              <div
                className={`chatPane-message ${
                  msg.postedByUser === currentUserId ? "right" : "left"
                }`}
              >
                {msg.message}
              </div>
            ))}
          </div>
          <div className="chatPane-composer">
            <Input
              name="message"
              type="text"
              className="chatPane-composer-input"
              placeholder="Enter your message here"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
            <Button
              className="primary chatPane-composer-button"
              onClick={handleMessageSubmit}
            >
              Send
            </Button>
          </div>
        </>
      )}
    </div>
  );
}

export default ChatPane;
