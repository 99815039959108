import React from "react";
import { Switch, Route } from "react-router-dom";

import ROUTES from "constants/Routes";

import ViewFreelancer from "containers/ViewFreelancer";
import FreelancerDetail from "containers/FreelancerDetail";

function Freelancer() {
  return (
    <Switch>
      <Route path={ROUTES.VIEW_SPECIFIC_FREELANCER}>
        <FreelancerDetail />
      </Route>
      <Route path={ROUTES.VIEW_ALL_FREELANCERS}>
        <ViewFreelancer />
      </Route>
    </Switch>
  );
}

export default Freelancer;
