import {
  getIsLoggedIn,
  getIsProfileSetupDone,
} from "selectors/userSelector";

export const stateToProps = (state) => {
  return {
    isLoggedIn: getIsLoggedIn(state),
    isProfileSetupDone: getIsProfileSetupDone(state),
  };
};
