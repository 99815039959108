// icons
import WebApp from "static/icons/web-app.svg";
import DesignCreativity from "static/icons/design-creativity.png";
import Writing from "static/icons/writing.svg";
import SalesMarketing from "static/icons/sales-marketing.svg";
import DigitalMarketing from "static/icons/digital-marketing.png";
import Photography from "static/icons/photography.png";
import DataEntry from "static/icons/data-entry.png";
import EverythingElse from "static/icons/everything-else.png";

// clients
import Oyo from "static/images/clients/oyo.png";
import HindustanTimes from "static/images/clients/hindustan-times.png";
import SistemaBio from "static/images/clients/sistema-bio.png";
import AkbarTravels from "static/images/clients/akbartravels.png";
import Allianz from "static/images/clients/allianz.png";
import Byju from "static/images/clients/byju.png";
import Coforge from "static/images/clients/coforge.png";
import CpKukreja from "static/images/clients/cpkukreja.png";
import Fasa from "static/images/clients/fasa.png";
import FieldAssist from "static/images/clients/fieldassist.png";
import HoliSol from "static/images/clients/holisol.jpg";
import Instamojo from "static/images/clients/instamojo.png";
import Koovs from "static/images/clients/koovs.png";
import Mailmodo from "static/images/clients/mailmodo.png";
import MamaEarth from "static/images/clients/mamaearth.png";
import Paytm from "static/images/clients/paytm.png";
import ProProfs from "static/images/clients/proprofs.jpeg";
import Shipsy from "static/images/clients/shipsy.jpg";

// working steps
import PostRequirements from "static/icons/post-requirements.svg";
import ConnectExperts from "static/icons/connect-experts.svg";
import Tasks from "static/icons/tasks.svg";
import RealTimeManagement from "static/icons/real-time-management.svg";

// features icon
import Price from "static/icons/price.svg";
import Trust from "static/icons/trust.svg";
import Payments from "static/icons/payments.svg";
import File from "static/icons/file.svg";

// stats icon
import Number from "static/icons/number-freelancers.svg";
import Support from "static/icons/business-support.svg";
import HappyClients from "static/icons/happy-clients.svg";
import World from "static/icons/world.svg";

// TODO: MOVE TO BACKEND
export const DISPLAY_CATEGORIES = [
  { id: "web-app-dev", text: "Web & App Development", icon: WebApp },
  {
    id: "design-creativity",
    text: "Design & Creativity",
    icon: DesignCreativity,
  },
  {
    id: "content-translation",
    text: "Content & Translation",
    icon: Writing,
  },
  {
    id: "sales-marketing",
    text: "Sales & Marketing",
    icon: SalesMarketing,
  },
  {
    id: "digital-marketing",
    text: "Digital Marketing",
    icon: DigitalMarketing,
  },
  { id: "photography", text: "Photography", icon: Photography },
  { id: "data-entry", text: "Data Entry", icon: DataEntry },
  {
    id: "everything-else",
    text: "Everything Else",
    icon: EverythingElse,
  },
];

export const DISPLAY_CLIENTS = [
  { id: "oyo", icon: Oyo, text: "Oyo" },
  { id: "hindustan-times", icon: HindustanTimes, text: "Hindustan Times" },
  { id: "akbar-travels", icon: AkbarTravels, text: "Akbar Travels" },
  { id: "allianz", icon: Allianz, text: "Allianz" },
  { id: "byju", icon: Byju, text: "Byju" },
  { id: "coforge", icon: Coforge, text: "CoForge" },
  { id: "cpkukreja", icon: CpKukreja, text: "CP Kukreja Architect" },
  { id: "sistema-bio", icon: SistemaBio, text: "Sistema Bio" },
  { id: "fasa", icon: Fasa, text: "Fasa" },
  { id: "field-assist", icon: FieldAssist, text: "FieldAssist" },
  { id: "holisol", icon: HoliSol, text: "Holisol" },
  { id: "koovs", icon: Koovs, text: "koovs" },
  { id: "instamojo", icon: Instamojo, text: "Instamojo" },
  { id: "mailmodo", icon: Mailmodo, text: "Mailmodo" },
  { id: "mamaearth", icon: MamaEarth, text: "Mamaearth" },
  { id: "paytm", icon: Paytm, text: "Paytm" },
  { id: "proprofs", icon: ProProfs, text: "ProProfs" },
  { id: "shipsy", icon: Shipsy, text: "Shipsy" },
];

export const DISPLAY_TESTIMONIALS = [
  {
    id: "testimonial-1",
    name: "Sneha Dalakoti",
    position: "Team Lead, Koovs.com",
    review:
      "It was nice working with ExpertRight, their deliveries were quick and prompt and their process of onboarding freelancers was hassle free. ExpertRight.com does not charge you for any service. No hidden cost, No middle man fee. You Pay only the Bid amount.",
  },
  {
    id: "testimonial-2",
    name: "Bhuwan Dua",
    position: "Head Of BD West, Oyo Rooms",
    review:
      "They helped us onboard right set of freelancers who provided us required support as and when required. Entire process was smooth, hassle free and truly humanized. ExpertRight.com does not charge you for any service. No hidden cost, No middle man fee. You Pay only the Bid amount.",
  },
  {
    id: "testimonial-3",
    name: "Yatin Sharma",
    position: "Deputy Manager, Hindustan Times",
    review:
      "We experienced possibly the very best services from Missionkya (Now ExpertRight). On time deliveries, great quality and deep dive research. I think its the best overall solution point in best rate. ExpertRight.com does not charge you for any service. No hidden cost, No middle man fee. You Pay only the Bid amount.",
  },
];

export const DISPLAY_WORKING_STEPS = [
  {
    id: "post-requirements",
    title: "Post your requirement",
    paragraph: "With details at the ExpertRight platform",
    icon: PostRequirements,
  },
  {
    id: "connect-experts",
    title: "Connect with Experts",
    paragraph:
      "Connect with various professionals and match the right talent for your needs",
    icon: ConnectExperts,
  },
  {
    id: "define-tasks",
    title: "Collaborate easily",
    paragraph: "Conclude on deliverables, budget and terms",
    icon: Tasks,
  },
  {
    id: "real-time-management",
    title: "Real Time Management",
    paragraph:
      "Manage your project from our ExpertRight Project Management Tool",
    icon: RealTimeManagement,
  },
];

export const DISPLAY_FEATURES = [
  {
    id: "feat-1",
    title: "Dedicated Project Moderator",
    subtext:
      "ExpertRight assist you with selection of service provider & assigns a dedicated project moderator to manage your project for you. For Free!",
    icon: Price,
  },
  {
    id: "feat-2",
    title: "Not Just The Match Making!",
    subtext:
      "We take care of end-to-end project delivery, keeps a bird eye view on every project & task & follow a feedback loop.",
    icon: Trust,
  },
  {
    id: "feat-3",
    title: "Control Project & Payments",
    subtext:
      "Your money sits safe in our deposit. We release the payment to freelancer's account only after successful completion of milestone and approval from your side.",
    icon: Payments,
  },
  {
    id: "feat-4",
    title: "Ease your file",
    subtext:
      "Broadcast your project and get it done from our trusted community member. We do chores while you focus on other important stuffs of your life.",
    icon: File,
  },
];

export const DISPLAY_STATS = [
  {
    id: "stat-1",
    stat: "25,000+",
    text: "Number of Freelancers & Agencies",
    icon: Number,
  },
  {
    id: "stat-2",
    stat: "32+",
    text: "Business support services",
    icon: Support,
  },
  {
    id: "stat-3",
    stat: "1150+",
    text: "Happy and super happy clients",
    icon: HappyClients,
  },
  {
    id: "stat-4",
    stat: "150+",
    text: "Cities in India & Abroad where our experts are located",
    icon: World,
  },
];
