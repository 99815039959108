import React from "react";
import PropTypes from "prop-types";
import { Input } from "semantic-ui-react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Section from "components/Section";

import "./CompanyInfo.scss";

function CompanyInfo({
  companyName,
  companyWebsite,
  employeeStrength,
  keyClients,
  companyTags,
  companyLinkedin,
  handleOnUpdate,
  setUser,
  setProfileData,
}) {
  return (
    <>
      <ToastContainer
        className="toast"
        position="bottom-center"
        autoClose={2500}
        hideProgressBar
      />
      <Section>
        <Section.Header
          key="contact-info-header-1"
          heading="Company Information"
        />
        <Section.ReadOnly key="contact-info-s1-r">
          <div className="item">
            <h4 className="heading">Name</h4>
            <p className="para">{companyName}</p>
          </div>
          <div className="item">
            <h4 className="heading">Website</h4>
            <p className="para">{companyWebsite}</p>
          </div>
          <div className="item">
            <h4 className="heading">Employee Strength</h4>
            <p className="para">{employeeStrength}</p>
          </div>
          <div className="item">
            <h4 className="heading">Key Clients</h4>
            <p className="para">{keyClients}</p>
          </div>
          <div className="item">
            <h4 className="heading">Company Tags</h4>
            <p className="para">{companyTags}</p>
          </div>
          <div className="item">
            <h4 className="heading">Company Linkedin</h4>
            <p className="para">{companyLinkedin}</p>
          </div>
        </Section.ReadOnly>
      </Section>
    </>
  );
}

export default CompanyInfo;
