import { bindActionCreators } from "redux";

import {
  getIsProfileSetupDone,
  getIsClient,
  getUserId,
  getPhone,
  getEmail,
  getName,
  getProvider,
  // getImageUrl,
} from "selectors/userSelector";
import { getProfile } from "selectors/profileSelector";
import { setProfileData } from "actions/user";
import { setUser } from "actions/auth";

export const stateToProps = (state) => {
  return {
    isProfileSetupDone: getIsProfileSetupDone(state),
    isClient: getIsClient(state),
    userProfile: getProfile(state),
    userId: getUserId(state),
    phone: getPhone(state),
    email: getEmail(state),
    name: getName(state),
    provider: getProvider(state),
  };
};

export const dispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setProfileData,
      setUser,
    },
    dispatch
  );
};
