import React from "react";

import { DISPLAY_WORKING_STEPS } from "../../constants";
import "./HowItWorks.scss";

function HowItWorks() {
  return (
    <div className="howItWorks">
      <div className="howItWorks-wrapper">
        <h2 className="howItWorks-title">How it works</h2>
        <p className="howItWorks-subHeading">
          ExpertRight delivers Professionals
        </p>
        <div className="howItWorks-steps">
          {DISPLAY_WORKING_STEPS.map((step) => (
            <div key={step.id} className="howItWorks-steps-item">
              <div className="howItWorks-steps-item-image">
                <img src={step.icon} alt="" />
              </div>
              <h4 className="howItWorks-steps-item-title">{step.title}</h4>
              <p className="howItWorks-steps-item-paragraph">
                {step.paragraph}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default HowItWorks;
