import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

import ROUTES from "constants/Routes";
import { stateToProps } from "./maps";

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated or your profile setup is not done or user is a freelancer.
function RedirectToDashboardRoute({ children, ...rest }) {
  const { isLoggedIn, isProfileSetupDone } = useSelector(stateToProps);

  const handleRender = React.useCallback(() => {
    if (isLoggedIn && isProfileSetupDone) {
      return <Redirect to={{ pathname: ROUTES.DASHBOARD }} />;
    }

    return children;
  }, [isLoggedIn, isProfileSetupDone, children]);

  return <Route {...rest} render={handleRender} />;
}

export default RedirectToDashboardRoute;
