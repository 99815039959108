import axios from "axios";

import { SIGNUP_URL, SIGNUP_GOOGLE_URL, SIGNUP_FACEBOOK_URL } from "api/auth";

export const userSignup = ({ email, password, redirectUrl }) => {
  return axios.post(SIGNUP_URL, { email, password, redirectUrl });
};

export const userGoogleSignUpAPI = ({ id, accessToken, name, email }) => {
  return axios.post(SIGNUP_GOOGLE_URL, { id, accessToken, name, email });
};
export const userFacebookSignUpAPI = ({ id, accessToken, name, email }) => {
  return axios.post(SIGNUP_FACEBOOK_URL, { id, accessToken, name, email });
};
