import { authorizedPost, authorizedGet } from "api";

import { GET_ALL_CHATS, INITIATE_CHAT } from "api/chat";

export const getAllChats = (userId) => {
  return authorizedGet(GET_ALL_CHATS.replace(":userId", userId));
};

export const initiateChat = (data) => {
  return authorizedPost(INITIATE_CHAT, data);
};

// export const postMessage = (roomId, data) => {
//   return authorizedPost(POST_MESSAGE.replace(":roomId", roomId), data);
// };
