import {
  getIsLoggedIn,
  getIsProfileSetupDone,
  getIsClient,
} from "selectors/userSelector";

export const stateToProps = (state) => {
  return {
    isLoggedIn: getIsLoggedIn(state),
    isProfileSetupDone: getIsProfileSetupDone(state),
    isClient: getIsClient(state),
  };
};
