import React from "react";
import { Switch, Route } from "react-router-dom";

import ROUTES from "constants/Routes";

import PrivateRoute from "components/PrivateRoute";
import UserPrivateRoute from "components/UserPrivateRoute";
import RedirectToDashboardRoute from "components/RedirectToDashboardRoute";

import JobRoutes from "components/JobRoutes";

import HomePage from "containers/HomePage";
import ContactPage from "containers/ContactUs";
import ForgotPassword from "containers/ForgotPassword";
import ResetPassword from "containers/ForgotPassword/components/ResetPassword";
import Login from "containers/Login";
import Signup from "containers/Signup";
import ActivateAccount from "containers/ActivateAccount";
import SubCategoryForm from "containers/SubCategoryForm";
import Settings from "containers/Settings";
import PaymentHistory from "containers/PaymentHistory";

//chat
// import Chat from "containers/Chat";
import NewChatWrapper from "components/NewChatWrapper";
import ProfileSetup from "containers/ProfileSetup";
import Dashboard from "containers/Dashboard";

import PrivacyPolicy from "pages/PrivacyPolicy";
import RefundPolicy from "pages/RefundPolicy";
import TermsAndConditions from "pages/TermsAndConditions";
import AboutUs from "pages/AboutUs";
import Freelancer from "pages/Freelancer";

function Routes() {
  return (
    <Switch>
      <Route path={ROUTES.PRIVACY_POLICY} exact>
        <PrivacyPolicy />
      </Route>
      <Route path={ROUTES.REFUND_POLICY} exact>
        <RefundPolicy />
      </Route>
      <Route path={ROUTES.TERMS_AND_CONDITIONS} exact>
        <TermsAndConditions />
      </Route>
      <Route path={ROUTES.ABOUT_US} exact>
        <AboutUs />
      </Route>
      <Route path={ROUTES.ACTIVATE_ACCOUNT}>
        <ActivateAccount />
      </Route>
      <Route path={ROUTES.RESET_PASSWORD}>
        <ResetPassword />
      </Route>
      <Route path={ROUTES.CONTACT_US} exact>
        <ContactPage />
      </Route>
      <Route path={ROUTES.LOGIN} exact>
        <Login />
      </Route>
      <Route path={ROUTES.SIGNUP} exact>
        <Signup />
      </Route>
      <Route path={ROUTES.FORGOT_PASSWORD} exact>
        <ForgotPassword />
      </Route>
      <UserPrivateRoute path={ROUTES.VIEW_ALL_FREELANCERS}>
        <Freelancer />
      </UserPrivateRoute>
      <UserPrivateRoute path={ROUTES.JOB_POST}>
        <JobRoutes />
      </UserPrivateRoute>
      <UserPrivateRoute path={ROUTES.PROFILE} exact>
        <Settings />
      </UserPrivateRoute>
      <UserPrivateRoute path={ROUTES.PAYMENT_HISTORY} exact>
        <PaymentHistory />
      </UserPrivateRoute>
      <UserPrivateRoute path={ROUTES.CHAT} exact>
        <NewChatWrapper />
      </UserPrivateRoute>
      <PrivateRoute path={ROUTES.PROFILE_SETUP} exact>
        <ProfileSetup />
      </PrivateRoute>
      <Route path={ROUTES.HIRE_CATEGORY}>
        <SubCategoryForm />
      </Route>
      <UserPrivateRoute path={ROUTES.DASHBOARD} exact>
        <Dashboard />
      </UserPrivateRoute>
      <RedirectToDashboardRoute path={ROUTES.HOME}>
        <HomePage />
      </RedirectToDashboardRoute>
    </Switch>
  );
}

export default Routes;
