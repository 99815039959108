import React, { useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import Auth from "components/Auth";
import ROUTES from "constants/Routes";

import SignupForm from "./components/SignupForm";
import SignupSubmit from "./components/SignupSubmit";

import { stateToProps } from "./maps";
import "./Signup.scss";

function Signup() {
  const { isLoggedIn, isProfileSetupDone } = useSelector(stateToProps);
  const [submitted, setSubmitted] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const history = useHistory();
  let location = useLocation();
  const { from } = location.state || { from: { pathname: "/" } };

  if (isLoggedIn) {
    if (!isProfileSetupDone) history.replace(ROUTES.PROFILE_SETUP);
    else history.replace(from);
  }

  const onSubmit = useCallback(({ success, message }) => {
    if (success) {
      setSubmitted(true);
      setSuccessMessage(message);
    }
  }, []);

  return (
    <Auth>
      {!submitted ? (
        <SignupForm onSubmit={onSubmit} />
      ) : (
        <SignupSubmit successMessage={successMessage} />
      )}
    </Auth>
  );
}

export default Signup;
