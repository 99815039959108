import React from "react";
import { useSelector } from "react-redux";
import { Table, Loader } from "semantic-ui-react";
import { ToastContainer, toast } from "react-toastify";

import ROUTES from "constants/Routes";

import { getPaymentHistory } from "./actions";
import { stateToProps } from "./maps";

function PaymentHistory() {
  const { userId, isClient } = useSelector(stateToProps);

  const [payments, setPayments] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    if (!isClient) return;

    setIsLoading(true);

    getPaymentHistory(userId)
      .then(({ data }) => {
        setPayments(data.payments);
        setIsLoading(false);
      })
      .catch((e) =>
        toast.error(
          "Error occurred while fetching payment history. Please refresh."
        )
      );
  }, [userId, isClient]);

  return (
    <div className="formWrapper">
      <ToastContainer
        className="toast"
        position="bottom-center"
        autoClose={2500}
        hideProgressBar
      />
      <div className="formWrapper-wrapper">
        <h2 className="formWrapper-heading">Payment History</h2>
        <p className="formWrapper-subHeading">
          All of the payments related to milestone can be found here
        </p>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Milestone</Table.HeaderCell>
              <Table.HeaderCell>Amount</Table.HeaderCell>
              <Table.HeaderCell>Method</Table.HeaderCell>
              <Table.HeaderCell>Description</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Loader active={isLoading} />
            {payments.length ? (
              payments.map((payment) => (
                <Table.Row>
                  <Table.Cell>
                    <a
                      href={ROUTES.JOB_POST_HIRE.replace(
                        ":id",
                        payment.notes.contractId
                      )}
                    >
                      {payment.milestone}
                    </a>
                  </Table.Cell>
                  <Table.Cell>Rs {payment.amount/100}</Table.Cell>
                  <Table.Cell>{payment.method}</Table.Cell>
                  <Table.Cell>{payment.description}</Table.Cell>
                </Table.Row>
              ))
            ) : (
              <div>No Data</div>
            )}
          </Table.Body>
        </Table>
      </div>
    </div>
  );
}

export default PaymentHistory;
