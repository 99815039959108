import { bindActionCreators } from "redux";

import { logoutUser } from "actions/auth";
import {
  getIsLoggedIn,
  getIsProfileSetupDone,
  getIsClient,
  getUserId,
} from "selectors/userSelector";

export const stateToProps = (state) => {
  return {
    isLoggedIn: getIsLoggedIn(state),
    isProfileSetupDone: getIsProfileSetupDone(state),
    isClient: getIsClient(state),
    userId: getUserId(state),
  };
};

export const dispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      logoutUser,
    },
    dispatch
  );
};
