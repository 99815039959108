const ACTIVATE_ACCOUNT = "/activate-account/:token";
const RESET_PASSWORD = "/reset-password/:token";

const BLOG = "https://blog.expertright.com/";
const BROWSE_PROJECT = "/browse-projects";
const CAREERS = "/careers";
const CATEGORIES = "/category";
const CONTACT_US = "/contact-us";
const DASHBOARD = "/dashboard";
const FAQs = "/faqs";
const ABOUT_US = "/about-us"

const VIEW_ALL_FREELANCERS = "/freelancers";
const VIEW_SPECIFIC_FREELANCER = "/freelancers/:id";

const FORGOT_PASSWORD = "/forgot-password";
const HIRE_CATEGORY = "/hire/:categoryId";
const HOME = "/";
const HOW_IT_WORKS = "/how-it-works";
// const CONTACT_US = "/contact-us";

const JOB_POST = "/jobs";
const JOB_POST_NEW = `${JOB_POST}/new`;
const JOB_POST_DETAIL = `${JOB_POST}/post/:id`;
const JOB_POST_APPLY = `${JOB_POST}/apply/:id`;
const JOB_POST_SEARCH = `${JOB_POST}/search`;
const JOB_POST_HIRE = `${JOB_POST}/hire/:id`;
const JOB_PROPOSAL = `${JOB_POST}/proposal/:id`;

const PRIVACY_POLICY = "/privacy-policy";
const REFUND_POLICY = "/refund-policy";

const LOGIN = "/login";
const PROFILE_SETUP = "/settings/setup";
const SIGNUP = "/signup";
const TERMS_AND_CONDITIONS = "/terms-conditions";
const PROFILE = "/settings/profile";
//chat route
const CHAT = "/chat";

// Payment routes
const PAYMENT_HISTORY = "/payment-history"

// social website links
const SOCIAL_FACEBOOK_LINK = "https://www.facebook.com/ExpertRight";
const SOCIAL_TWITTER_LINK = "https://twitter.com/@expertright_hq";
const SOCIAL_LINKEDIN_LINK =
  "https://www.linkedin.com/company/missionkya/?viewAsMember=true";
const SOCIAL_INSTAGRAM_LINK = "https://www.instagram.com/expertright/";

export default {
  ABOUT_US,
  ACTIVATE_ACCOUNT,
  RESET_PASSWORD,
  
  BLOG,
  BROWSE_PROJECT,
  CAREERS,
  CATEGORIES,
  CONTACT_US,
  DASHBOARD,
  FAQs,

  FORGOT_PASSWORD,
  HIRE_CATEGORY,
  HOME,
  HOW_IT_WORKS,

  JOB_POST,
  JOB_POST_NEW,
  JOB_POST_DETAIL,
  JOB_POST_APPLY,
  JOB_POST_SEARCH,
  JOB_POST_HIRE,
  JOB_PROPOSAL,

  LOGIN,
  PRIVACY_POLICY,
  REFUND_POLICY,
  PROFILE_SETUP,
  SIGNUP,
  TERMS_AND_CONDITIONS,
  PROFILE,
  CHAT,

  PAYMENT_HISTORY,

  VIEW_ALL_FREELANCERS,
  VIEW_SPECIFIC_FREELANCER,

  SOCIAL_FACEBOOK_LINK,
  SOCIAL_TWITTER_LINK,
  SOCIAL_LINKEDIN_LINK,
  SOCIAL_INSTAGRAM_LINK,
};
