import React from "react";
import PropTypes from "prop-types";
import { Input } from "semantic-ui-react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Section from "components/Section";

import "./ContactInfo.scss";

function ContactInfo({
  name,
  phone,
  email,
  address,
  handleOnUpdate,
  setUser,
  setProfileData,
}) {
  const [editedContactData, setEditedContactData] = React.useState({});
  const [editedAddressData, setEditedAddressData] = React.useState({});
  const [isContactInfoSubmitting, setIsContactInfoSubmitting] =
    React.useState(false);
  const [isAddressSubmitting, setIsAddressSubmitting] = React.useState(false);
  const [errors, setErrors] = React.useState({});

  const resetContactData = React.useCallback(() => {
    setErrors({});
    setEditedContactData({});
  }, [setEditedContactData]);
  const resetAddressData = React.useCallback(() => {
    setErrors({});
    setEditedAddressData({});
  }, [setEditedAddressData]);

  const handleOnChange = React.useCallback(
    (e, inputData) => {
      const { name, value } = inputData;

      if (name === "email") return;
      if (name === "phone" && value.length > 10) return;

      if (name === "address") {
        setEditedAddressData({ ...editedAddressData, [name]: value });
        return;
      }

      setEditedContactData({ ...editedContactData, [name]: value });
    },
    [editedContactData, editedAddressData]
  );

  const onUpdateContactInfo = React.useCallback(
    (callback) => {
      if (!Object.keys(editedContactData).length) {
        callback();
        return;
      }

      let errors = {};

      if (
        Object.prototype.hasOwnProperty.call(editedContactData, "name") &&
        editedContactData.name === ""
      ) {
        errors = { ...errors, name: true };
      }
      if (
        Object.prototype.hasOwnProperty.call(editedContactData, "email") &&
        editedContactData.email === ""
      ) {
        errors = { ...errors, email: true };
      }
      if (
        Object.prototype.hasOwnProperty.call(editedContactData, "phone") &&
        (editedContactData.phone === "" ||
          editedContactData.phone.length !== 10)
      ) {
        errors = { ...errors, phone: true };
      }

      if (Object.keys(errors).length) {
        setErrors({ ...errors });
        return;
      }

      setIsContactInfoSubmitting(true);

      handleOnUpdate(
        editedContactData,
        "updateContactInfo",
        (success, response) => {
          setIsContactInfoSubmitting(false);
          if (success) {
            resetContactData();
            setUser({ ...editedContactData });
            toast.success(response);
            callback();
          } else {
            toast.error(response);
          }
        }
      );
    },
    [editedContactData, resetContactData, handleOnUpdate, setUser]
  );

  const onUpdateAddressInfo = React.useCallback(
    (callback) => {
      if (!Object.prototype.hasOwnProperty.call(editedAddressData, "address")) {
        callback();
        return;
      }

      setIsAddressSubmitting(true);
      handleOnUpdate(
        editedAddressData,
        "updateAddressInfo",
        (success, response) => {
          setIsAddressSubmitting(false);
          if (success) {
            setProfileData({ ...editedAddressData });
            resetAddressData();
            toast.success(response);
            callback();
          } else {
            toast.error(response);
          }
        }
      );
    },
    [editedAddressData, handleOnUpdate, resetAddressData, setProfileData]
  );

  const onInputFocus = React.useCallback(
    (e) => {
      const errorsPresent = { ...errors };
      if (e.target.name in errorsPresent) {
        delete errorsPresent[e.target.name];
      }

      setErrors(errorsPresent);
    },
    [errors]
  );

  const nameValue = Object.prototype.hasOwnProperty.call(
    editedContactData,
    "name"
  )
    ? editedContactData["name"]
    : name;
  const emailValue = Object.prototype.hasOwnProperty.call(
    editedContactData,
    "email"
  )
    ? editedContactData["email"]
    : email;
  const phoneValue = Object.prototype.hasOwnProperty.call(
    editedContactData,
    "phone"
  )
    ? editedContactData["phone"]
    : phone;
  const addressValue = Object.prototype.hasOwnProperty.call(
    editedAddressData,
    "address"
  )
    ? editedContactData["address"]
    : address;
  return (
    <>
      <ToastContainer
        className="toast"
        position="bottom-center"
        autoClose={2500}
        hideProgressBar
      />
      <Section>
        <Section.Header
          key="contact-info-header-1"
          heading="Basic Information"
        />
        <Section.ReadOnly key="contact-info-s1-r">
          <div className="item">
            <h4 className="heading">Name</h4>
            <p className="para">{name}</p>
          </div>
          <div className="item">
            <h4 className="heading">Email</h4>
            <p className="para">{email}</p>
          </div>
          <div className="item">
            <h4 className="heading">Phone</h4>
            <p className="para">+91 {phone}</p>
          </div>
        </Section.ReadOnly>
        <Section.Editable
          key="contact-info-s1-e"
          isUpdating={isContactInfoSubmitting}
          onUpdate={onUpdateContactInfo}
          onCancel={resetContactData}
        >
          <div>
            <div className="field">
              <label>Name</label>
              <Input
                name="name"
                className="maxWidth-60"
                placeholder="Enter your name"
                value={nameValue}
                error={errors.name}
                onFocus={onInputFocus}
                onChange={handleOnChange}
                spellCheck={false}
              />
            </div>
            <div className="field">
              <label>Email</label>
              <Input
                name="email"
                className="maxWidth-60"
                placeholder="Enter your email address"
                value={emailValue}
                error={errors.email}
                onFocus={onInputFocus}
                onChange={handleOnChange}
              />
            </div>
            <div className="field">
              <label>Phone</label>
              <Input
                name="phone"
                label="+91"
                className="maxWidth-60 number"
                placeholder="Enter phone number"
                type="number"
                value={phoneValue}
                error={errors.phone}
                onFocus={onInputFocus}
                onChange={handleOnChange}
              />
            </div>
          </div>
        </Section.Editable>
      </Section>
      <Section>
        <Section.Header
          key="contact-info-header-2"
          heading="Location Information"
        />
        <Section.ReadOnly key="contact-info-s2-r">
          <div className="item">
            <h4 className="heading">Address</h4>
            <p className="para">{address || "No address added yet"}</p>
          </div>
        </Section.ReadOnly>
        <Section.Editable
          key="contact-info-s2-e"
          isUpdating={isAddressSubmitting}
          onUpdate={onUpdateAddressInfo}
          onCancel={resetAddressData}
        >
          <div className="field">
            <label>Address</label>
            <Input
              name="address"
              className="maxWidth-60"
              placeholder="Enter address"
              errors={errors.address}
              value={addressValue}
              onFocus={onInputFocus}
              onChange={handleOnChange}
            />
          </div>
        </Section.Editable>
      </Section>
    </>
  );
}

ContactInfo.propTypes = {
  name: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  address: PropTypes.string,
  handleOnUpdate: PropTypes.func.isRequired,
  setUser: PropTypes.func.isRequired,
  setProfileData: PropTypes.func.isRequired,
};

ContactInfo.defaultProps = {
  name: "",
  email: "",
  phone: "",
  address: "",
};

export default ContactInfo;
