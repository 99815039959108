import React from "react";
import PropTypes from "prop-types";
import { Link, useHistory } from "react-router-dom";
import {
  Accordion,
  Icon,
  Label,
  Button,
  Modal,
  Loader,
  Input,
} from "semantic-ui-react";

import { PROPOSAL_STATUS } from "constants/Proposal";
import { JOB_STATUS } from "constants/Job";
import ROUTES from "constants/Routes";
import { firstLetterCapitalise } from "utils";
import { getProposalStatusColor } from "utils/job";

import { PROPOSAL_ACTIONS } from "../../constants";

import "./JobDetailProposals.scss";

function JobDetailProposals({ job, proposals, handleAcceptReject, userId }) {
  const history = useHistory();
  const [activeIndex, setActiveIndex] = React.useState(-1);
  const [isLoaderActive, setIsLoaderActive] = React.useState(false);
  const [bidValue, setBidValue] = React.useState(-1);

  const handleAccordionClick = React.useCallback((event, data, bid) => {
    if (!data.active) {
      setActiveIndex(data.index);
      setBidValue(bid);
    } else {
      setActiveIndex(-1);
      setBidValue(-1);
    }
  }, []);

  const handleRejectClick = (e, data) => {
    const { id, freelanceId } = data;

    if (!id || isLoaderActive) return;

    setIsLoaderActive(true);

    handleAcceptReject(PROPOSAL_ACTIONS.REJECT, id, freelanceId, bidValue, () =>
      setIsLoaderActive(false)
    );
  };

  const handleAcceptClick = (e, data) => {
    const { id, freelanceId } = data;

    if (!id || isLoaderActive) return;

    setIsLoaderActive(true);

    handleAcceptReject(PROPOSAL_ACTIONS.ACCEPT, id, freelanceId, bidValue, () =>
      setIsLoaderActive(false)
    );
  };

  const goToChat = async (proposal) => {
    history.push({
      pathname: "/chat",
      state: {
        usersInvolved: [proposal.userId._id, userId],
        chatInitiator: userId,
      },
    });
  };

  return (
    <>
      <Loader active={isLoaderActive} />
      {proposals.length ? (
        <Accordion className="jobDetailProposals">
          {proposals.map((proposal, index) => {
            const createdDate = new Date(proposal.createdAt);
            return (
              <>
                <Accordion.Title
                  active={activeIndex === index}
                  index={index}
                  onClick={(event, data) =>
                    handleAccordionClick(event, data, proposal.bid)
                  }
                >
                  <div className="jobDetailProposals-title">
                    <p className="jobDetailProposals-title-date">
                      Submitted on
                      <br />{" "}
                      {`${createdDate.toDateString()} ${createdDate.getHours()}:${createdDate.getMinutes()}:${createdDate.getSeconds()}`}
                    </p>
                    <p className="jobDetailProposals-title-cover">
                      {proposal.cover}
                    </p>
                    <div className="jobDetailProposals-title-bid">
                      Bid <br /> <p>{proposal.bid}</p>
                    </div>
                    <div className="jobDetailProposals-title-status">
                      <Label color={getProposalStatusColor(proposal.status)}>
                        {firstLetterCapitalise(proposal.status)}
                      </Label>
                    </div>
                    <Icon name="dropdown" />
                  </div>
                </Accordion.Title>
                <Accordion.Content active={activeIndex === index}>
                  <div className="jobDetailProposals-content">
                    <div className="jobDetailProposals-content-name-heading">
                      Name
                    </div>
                    <p className="jobDetailProposals-content-name">
                      {proposal.userId.name}{" "}
                      <Link
                        className="jobDetailProposals-content-name-link"
                        to={ROUTES.VIEW_SPECIFIC_FREELANCER.replace(
                          ":id",
                          proposal.userId._id
                        )}
                        target="_blank"
                      >
                        Open Profile in new window
                      </Link>
                    </p>
                    <h4 className="jobDetailProposals-content-cover-heading">
                      Duration
                    </h4>
                    <p className="jobDetailProposals-content-name">
                      {proposal.duration}
                    </p>
                    <h4 className="jobDetailProposals-content-cover-heading">
                      Cover
                    </h4>
                    <p className="jobDetailProposals-content-cover">
                      {proposal.cover}
                    </p>
                    {job.additionalQuestion1 ||
                    job.additionalQuestion2 ||
                    job.additionalQuestion3 ? (
                      <div className="jobDetailProposals-content-question-answer-heading">
                        Questions asked:
                      </div>
                    ) : null}
                    {job.additionalQuestion1 && (
                      <div className="jobDetailProposals-content-question-answer">
                        <h4>{job.additionalQuestion1}</h4>
                        <p>{proposal.additionalAnswer1}</p>
                      </div>
                    )}
                    {job.additionalQuestion2 && (
                      <div className="jobDetailProposals-content-question-answer">
                        <h4>{job.additionalQuestion2}</h4>
                        <p>{proposal.additionalAnswer2}</p>
                      </div>
                    )}
                    {job.additionalQuestion3 && (
                      <div className="jobDetailProposals-content-question-answer">
                        <h4>{job.additionalQuestion3}</h4>
                        <p>{proposal.additionalAnswer3}</p>
                      </div>
                    )}
                    {job.status === JOB_STATUS.OPEN &&
                    proposal.status === PROPOSAL_STATUS.OPEN ? (
                      <div className="jobDetailProposals-content-cta">
                        <Modal
                          className="jobDetailProposals-modal"
                          trigger={
                            <Button className="alternative warning">
                              Reject
                            </Button>
                          }
                          content="Are you sure you want to reject this proposal?"
                          actions={[
                            "Cancel",
                            {
                              key: "reject",
                              content: "Reject Proposal",
                              negative: true,
                              id: proposal._id,
                              freelanceId: proposal.userId._id,
                              onClick: handleRejectClick,
                            },
                          ]}
                        />
                        <Modal
                          className="jobDetailProposals-modal"
                          trigger={
                            <Button
                              className="alternative success"
                              style={{ marginRight: "12px" }}
                            >
                              Accept
                            </Button>
                          }
                          content={
                            <div className="jobDetailProposals-terms">
                              <h3>Terms for the hire</h3>
                              <h4>Bid for the project</h4>
                              <p>
                                This is the price you and {proposal.userId.name}{" "}
                                have agreed upon
                              </p>
                              <Input
                                name="bid"
                                labelPosition="right"
                                type="number"
                                placeholder="Amount"
                                className="jobProposalBody-terms-answer"
                                value={bidValue}
                                onChange={(e) => setBidValue(e.target.value)}
                              >
                                <Label basic>₹</Label>
                                <input className="side-padding" />
                                <Label>.00</Label>
                              </Input>
                              <div className="jobDetailProposals-terms-checkbox">
                                By accepting, you understand and agree to the
                                ExpertRight Terms of Service, including the User
                                Agreement and Privacy Policy.
                              </div>
                            </div>
                          }
                          actions={[
                            "Cancel",
                            {
                              key: "accept",
                              content: `Agree Terms & Hire ${proposal.userId.name}`,
                              positive: true,
                              id: proposal._id,
                              freelanceId: proposal.userId._id,
                              onClick: handleAcceptClick,
                            },
                          ]}
                        />
                        <Button
                          icon
                          labelPosition="left"
                          basic
                          primary
                          onClick={() => goToChat(proposal)}
                        >
                          <Icon name="chat" className="chat" />
                          Message
                        </Button>
                      </div>
                    ) : proposal.status === PROPOSAL_STATUS.ACCEPTED ? (
                      <Button
                        primary
                        style={{ marginTop: "24px" }}
                        onClick={() =>
                          history.push(
                            ROUTES.JOB_POST_HIRE.replace(
                              ":id",
                              proposal.contractId
                            )
                          )
                        }
                      >
                        Go to Contract
                      </Button>
                    ) : null}
                  </div>
                </Accordion.Content>
              </>
            );
          })}
        </Accordion>
      ) : (
        <div className="jobDetailProposals-proposal-empty">
          This job has no proposals yet!
        </div>
      )}
    </>
  );
}

JobDetailProposals.propTypes = {
  job: PropTypes.shape({}),
  proposals: PropTypes.arrayOf(PropTypes.shape({})),
};

JobDetailProposals.defaultProps = {
  job: {},
  proposals: [],
};

export default JobDetailProposals;
