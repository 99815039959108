import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import Auth from "components/Auth";

import Confirmed from "./components/Confirmed";
import Confirming from "./components/Confirming";
import { activateUserAccount } from "./actions";
import "./ActivateAccount.scss";

function ActivateAccount() {
  const [loading, setLoading] = useState(true);
  const [isValid, setIsValid] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState("");
  const [errorMessage, setErrorMessage] = useState(
    "Expired Link! Signup again."
  );

  const { token } = useParams();

  useEffect(() => {
    if (!token) return;

    activateUserAccount({ token })
      .then((response) => {
        setRedirectUrl(response.data ? response.data.redirectUrl : "");
        setLoading(false);
        setIsValid(true);
      })
      .catch((error) => {
        try {
          setErrorMessage(error.response.data.error);
        } catch (e) {}
        setLoading(false);
        setIsValid(false);
      });
  }, [token]);

  return (
    <Auth>
      {loading ? (
        <Confirming />
      ) : (
        <Confirmed
          isValid={isValid}
          errorMessage={errorMessage}
          redirectUrl={redirectUrl}
        />
      )}
    </Auth>
  );
}

export default ActivateAccount;
