import React from "react";
import { Button, Modal, Input, Checkbox, Label } from "semantic-ui-react";

import "./MilestoneModal.scss";

function MilestoneModal({ active, onClose, getPaymentLink }) {
  const [title, setTitle] = React.useState("");
  const [topicOne, setTopicOne] = React.useState("");
  const [topicTwo, setTopicTwo] = React.useState("");
  const [topicThree, setTopicThree] = React.useState("");
  const [topicFour, setTopicFour] = React.useState("");
  const [topicFive, setTopicFive] = React.useState("");
  const [topicSix, setTopicSix] = React.useState("");
  const [topicSeven, setTopicSeven] = React.useState("");
  const [requireDocs, setRequireDocs] = React.useState(false);
  const [milestoneValue, setMilestoneValue] = React.useState();
  const [isLoading, setIsLoading] = React.useState(false);

  const resetModal = React.useCallback(() => {
    setTitle("");
    setTopicOne("");
    setTopicTwo("");
    setTopicThree("");
    setTopicFour("");
    setTopicFive("");
    setTopicSix("");
    setTopicSeven("");
    setRequireDocs(false);
    setMilestoneValue();
    setIsLoading(false);
    onClose();
  }, [onClose]);

  const handlePayment = React.useCallback(() => {
    setIsLoading(true);
    getPaymentLink(
      milestoneValue,
      {
        title,
        topics: [
          topicOne,
          topicTwo,
          topicThree,
          topicFour,
          topicFive,
          topicSix,
          topicSeven,
        ],
        requireDocs,
      },
      (success) => (success ? resetModal() : setIsLoading(false))
    );
  }, [
    getPaymentLink,
    milestoneValue,
    requireDocs,
    title,
    topicFive,
    topicFour,
    topicOne,
    topicSeven,
    topicSix,
    topicThree,
    topicTwo,
    resetModal,
  ]);

  return (
    <Modal onClose={resetModal} open={active} closeIcon>
      <Modal.Header>Add Milestone</Modal.Header>
      <Modal.Content>
        <div className="step1-field">
          <Input
            name="title"
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <label htmlFor="title">Milestone title*</label>
        </div>
        <div className="milestoneModal-topicsHeading">
          List topics to deliver (maximum 7)
        </div>
        <div className="milestoneModal-topics">
          <div className="step1-field">
            <Input
              name="topicOne"
              type="text"
              value={topicOne}
              onChange={(e) => setTopicOne(e.target.value)}
            />
            <label htmlFor="topicOne">Topic 1</label>
          </div>
          <div className="step1-field">
            <Input
              name="topicTwo"
              type="text"
              value={topicTwo}
              onChange={(e) => setTopicTwo(e.target.value)}
            />
            <label htmlFor="topicTwo">Topic 2</label>
          </div>
          <div className="step1-field">
            <Input
              name="topicThree"
              type="text"
              value={topicThree}
              onChange={(e) => setTopicThree(e.target.value)}
            />
            <label htmlFor="topicThree">Topic 3</label>
          </div>
          <div className="step1-field">
            <Input
              name="topicFour"
              type="text"
              value={topicFour}
              onChange={(e) => setTopicFour(e.target.value)}
            />
            <label htmlFor="topicFour">Topic 4</label>
          </div>
          <div className="step1-field">
            <Input
              name="topicFive"
              type="text"
              value={topicFive}
              onChange={(e) => setTopicFive(e.target.value)}
            />
            <label htmlFor="topicFive">Topic 5</label>
          </div>
          <div className="step1-field">
            <Input
              name="topicSix"
              type="text"
              value={topicSix}
              onChange={(e) => setTopicSix(e.target.value)}
            />
            <label htmlFor="topicSix">Topic 6</label>
          </div>
          <div className="step1-field">
            <Input
              name="topicSeven"
              type="text"
              value={topicSeven}
              onChange={(e) => setTopicSeven(e.target.value)}
            />
            <label htmlFor="topicSeven">Topic 7</label>
          </div>
        </div>
        <Checkbox
          className="myProfile-experience-checkbox milestoneModal-checkbox"
          checked={requireDocs}
          name="requireDocs"
          onChange={() => setRequireDocs((bool) => !bool)}
          label="Create google docs for the topics"
        />
        <div className="step1-field">
          <Input
            name="bid"
            labelPosition="right"
            type="number"
            placeholder="Amount"
            className="jobProposalBody-terms-answer"
            value={milestoneValue}
            onChange={(e) => setMilestoneValue(e.target.value)}
          >
            <Label basic>₹</Label>
            <input className="side-padding" />
            <Label>.00</Label>
          </Input>
          <label htmlFor="topicSeven">Add payment related to Milestone</label>
        </div>
        <Button className="primary" onClick={handlePayment} loading={isLoading}>
          Pay {milestoneValue} & Add
        </Button>
      </Modal.Content>
    </Modal>
  );
}

export default MilestoneModal;
