import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { Checkbox, Dropdown } from "semantic-ui-react";

import { FILTER_TYPES, FILTER_BY_FILTERS } from "../../constants";
import "./Filters.scss";

function Filters({
  experienceLevel,
  projectLengthSelected,
  budgetSelected,
  categoriesSelected,
  handleOnChange,
  filterCategories,
}) {
  const onChange = useCallback(
    (e, data) => {
      handleOnChange("", data.value, "categoriesSelected");
    },
    [handleOnChange]
  );
  return (
    <div className="filters">
      <div className="filters-wrapper">
        <h3 className="filters-title">Filter by:</h3>
      </div>
      <div className="filters-types">
        {FILTER_BY_FILTERS.map(filter => {
          switch (filter.type) {
            case FILTER_TYPES.CHECKBOX:
              return (
                <div id={filter.id} className="filters-types-item">
                  <p className="filters-types-item-title">{filter.title}</p>
                  <div className="filters-types-item-checkbox">
                    {filter.options.map((choice) => {
                      let checked = false;

                      if (filter.key === "experienceLevel") {
                        checked = experienceLevel.includes(choice.value);
                      } else if (filter.key === "projectLengthSelected") {
                        checked = projectLengthSelected.includes(choice.value);
                      } else if (filter.key === "budgetSelected") {
                        checked = budgetSelected.includes(choice.value);
                      } else if (filter.key === "categoriesSelected") {
                        checked = categoriesSelected.includes(choice.value);
                      }

                      return (
                        <Checkbox
                          id={choice.id}
                          label={choice.text}
                          value={choice.value}
                          checked={checked}
                          onChange={(e, data) =>
                            handleOnChange(e, data, filter.key)
                          }
                        />
                      );
                    })}
                  </div>
                </div>
              );

            case FILTER_TYPES.SELECT:
              return (
                <div className="filters-types-item">
                  <p className="filter-types-item-title">{filter.title}</p>
                  <div className="filters-types-item-select">
                    <Dropdown
                      value={categoriesSelected}
                      options={filter.options || filterCategories}
                      multiple={filter.isMultiple}
                      onChange={onChange}
                      fluid
                      selection
                    />
                  </div>
                </div>
              );

            default:
              return null;
          }
        })}
      </div>
    </div>
  );
}

Filters.propTypes = {
  experienceLevel: PropTypes.arrayOf(PropTypes.string),
  projectLengthSelected: PropTypes.arrayOf(PropTypes.string),
  budgetSelected: PropTypes.arrayOf(PropTypes.string),
  categoriesSelected: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  handleOnChange: PropTypes.func.isRequired,
};

Filters.defaultProps = {
  experienceLevel: [],
  projectLengthSelected: [],
  budgetSelected: [],
  categoriesSelected: [],
};

export default Filters;
