import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { Input, Radio, Dropdown, Button, Icon } from "semantic-ui-react";

import Attachment from "static/icons/upload-attachment.svg";
import { getFileIcon } from "utils";

import {
  SELECTION_PROCESS,
  SERVICE_PROVIDER_TYPE,
  JOB_DURATION,
} from "../../constants";
import "./Step3.scss";

function Step3({
  assistanceType,
  duration,
  serviceProvider,
  documents,
  additionalQuestion1,
  additionalQuestion2,
  additionalQuestion3,
  onChange,
  isFileUploading,
  isSubmitting,
  handleFileUpload,
  handleSubmitForm,
  handlePreviousStep,
  handleRemoveDocument,
}) {
  const handleOnChange = useCallback(
    (e) => {
      onChange(e.target.name, e.target.value);
    },
    [onChange]
  );

  console.log("documents", documents);

  const isEnabled = assistanceType && duration && serviceProvider;

  return (
    <div className="step3">
      <div className="step3-field radio">
        <div>
          {SELECTION_PROCESS.map((type) => (
            <Radio
              name="assistanceType"
              label={type.label}
              value={type.value}
              checked={type.value === assistanceType}
              onChange={(e, { value }) =>
                handleOnChange({ target: { name: "assistanceType", value } })
              }
            />
          ))}
        </div>
        <label>Selection Process*</label>
      </div>
      <div className="step3-field">
        <Dropdown
          value={duration}
          options={JOB_DURATION}
          onChange={(e, data) =>
            handleOnChange({ target: { name: "duration", value: data.value } })
          }
          fluid
          selection
        />
        <label>Duration*</label>
      </div>
      <div className="step3-field radio">
        <div>
          {SERVICE_PROVIDER_TYPE.map((type) => (
            <Radio
              name="serviceProvider"
              label={type.label}
              value={type.value}
              checked={type.value === serviceProvider}
              onChange={(e, { value }) =>
                handleOnChange({ target: { name: "serviceProvider", value } })
              }
            />
          ))}
        </div>
        <label>Service Provider Type*</label>
      </div>
      <div className="step3-field file">
        <label>
          Any additional attachment
          <div style={{ display: "flex" }}>
            {documents.map((item, index) => {
              const extension = item.title;
              const split = extension.split(".");
              return (
                <div
                  key={index}
                  style={{
                    justifyContent: "center",
                    padding: "5px",
                    display: "flex"
                  }}
                >
                  <img
                    src={getFileIcon(split[1])}
                    alt="document"
                    style={{
                      height: "30px",
                      margin: "auto",
                      width: "auto",
                    }}
                  />
                  <p style={{ fontSize: 10 }}>
                    {split[0].length > 15
                      ? `${split[0].substring(0, 15)}...`
                      : split[0]}
                  </p>
                  <Icon
                    className="myProfile-portfolio-document-remove"
                    name="close"
                    onClick={() => handleRemoveDocument(index)}
                  />
                </div>
              );
            })}
          </div>
          <div className="file-placeholder">
            <img src={Attachment} alt="" />
            {isFileUploading ? "Uploading file..." : "Upload file (max 10 MB)"}
          </div>
          <Input type="file" onChange={handleFileUpload} multiple />
        </label>
      </div>
      <div className="step3-field">
        <Input
          name="additionalQuestion3"
          type="text"
          value={additionalQuestion3}
          placeholder="Question 3"
          onChange={handleOnChange}
        />
        <Input
          name="additionalQuestion2"
          type="text"
          value={additionalQuestion2}
          placeholder="Question 2"
          onChange={handleOnChange}
        />
        <Input
          name="additionalQuestion1"
          type="text"
          value={additionalQuestion1}
          placeholder="Question 1"
          onChange={handleOnChange}
        />
        <label>Any additional questions (max 3)</label>
      </div>
      <div className="step3-buttons">
        <Button className="alternative" onClick={handlePreviousStep}>
          Previous
        </Button>
        <Button
          className="primary"
          onClick={handleSubmitForm}
          loading={isSubmitting}
          disabled={!isEnabled}
        >
          Submit
        </Button>
      </div>
    </div>
  );
}

Step3.propTypes = {
  assistanceType: PropTypes.string,
  duration: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }),
  serviceProvider: PropTypes.string,
  fileAttachment: PropTypes.string,
  isFileUploading: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  handleSubmitForm: PropTypes.func.isRequired,
  handlePreviousStep: PropTypes.func.isRequired,
  handleFileUpload: PropTypes.func.isRequired,
};

Step3.defaultProps = {
  duration: null,
  isFileUploading: false,
  isSubmitting: false,
  assistanceType: "",
  fileAttachment: "",
  serviceProvider: "",
};

export default Step3;
