import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Sidebar } from "semantic-ui-react";

import ROUTES from "constants/Routes";
import JobDetail from "containers/JobDetail";

import ClosePrimary from "static/icons/close-primaryColor.svg";
import NewWindow from "static/icons/new-window.svg";

import "./JobDetailWrapper.scss";

function JobDetailWrapper({ visible, selectedJobId, onOutSideClick }) {
  const activeWrapperClassName = visible ? "active" : "";

  return (
    <div className={`jobDetailWrapper-overlay ${activeWrapperClassName}`}>
      <Sidebar
        animation="overlay"
        direction="right"
        className="jobDetailWrapper-sidebar"
        width="very wide"
        onHide={onOutSideClick}
        visible={visible}
      >
        <div className="jobDetailWrapper-sidebar-header">
          <div className="jobDetailWrapper-wrapper">
            <img
              className="jobDetailWrapper-sidebar-header-close"
              alt="close"
              src={ClosePrimary}
              onClick={onOutSideClick}
            />
            <Link
              className="jobDetailWrapper-sidebar-header-link"
              to={`${ROUTES.JOB_POST_DETAIL.replace(":id", selectedJobId)}`}
              target="_blank"
            >
              <img src={NewWindow} alt="" />
              Open link in a new window
            </Link>
          </div>
        </div>
        {visible && (
          <div className="jobDetailWrapper-wrapper">
            <div className="jobDetailWrapper-details">
              <JobDetail jobId={selectedJobId} />
            </div>
          </div>
        )}
      </Sidebar>
    </div>
  );
}

JobDetailWrapper.propTypes = {
  visible: PropTypes.bool,
  selectedJobId: PropTypes.string,
  onOutSideClick: PropTypes.func.isRequired,
};

JobDetailWrapper.defaultProps = {
  visible: false,
  selectedJobId: "",
};

export default JobDetailWrapper;
