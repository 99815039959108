import React from "react";
import PropTypes from "prop-types";

import { getFileIcon } from "utils";

function JobDetailBody({ job }) {
  return (
    <div className="jobDetail-content">
      <h4>{job.title}</h4>
      <p className="jobDetail-content-description">{job.description}</p>
      <div className="jobDetail-content-skills">
        <p className="jobDetail-content-skills-heading">Skills required:</p>
        <div className="jobDetail-content-skills-items">
          {job.skills.map(({ skill }) => (
            <div className="jobDetail-content-skills-item">{skill}</div>
          ))}
        </div>
      </div>
      <div>
        {job?.documents?.length ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "12px",
            }}
          >
            {job?.documents?.map((document, index) => {
              const extension = document.title;
              const split = extension.split(".");
              return (
                <a
                  href={document.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div
                    key={`fcDetailDocuments-${index}`}
                    style={{
                      padding: "5px",
                      display: "flex",
                      marginTop: "6px",
                    }}
                  >
                    <img
                      src={getFileIcon(split[1])}
                      alt="document"
                      style={{ height: "30px", width: "auto" }}
                    />
                    <p style={{ fontSize: 10, lineHeight: "30px" }}>
                      {split[0]}
                    </p>
                  </div>
                </a>
              );
            })}
          </div>
        ) : (
          <p className="noDataAdded" style={{ marginTop: "24px" }}>
            No documents added
          </p>
        )}
      </div>
    </div>
  );
}

JobDetailBody.propTypes = {
  job: PropTypes.shape({}),
};

JobDetailBody.defaultProps = {
  job: {},
};

export default JobDetailBody;
