import React from "react";
import PropTypes from "prop-types";
import "../../../HomePage/components/Categories/Categories.scss";

function Step1({ subCategoryOption, subCategories, handleOnChange }) {
  return (
    <>
      {subCategories.map((subCategory) => (
        <div
          className="subCategory-option"
          key={subCategory._id}
          onClick={() => handleOnChange(subCategory._id)}
        >
          <input
            name="sub-category"
            type="radio"
            value={subCategory._id}
            className="radioBtn"
            checked={subCategory._id === subCategoryOption}
          />
          <p>{subCategory.title}</p>
        </div>
      ))}
    </>
  );
}

Step1.propTypes = {
  subCategoryOption: PropTypes.string,
  subCategories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
    })
  ),
  handleOnChange: PropTypes.func.isRequired,
};

Step1.defaultProps = {
  subCategoryOption: "",
  subCategories: [],
};

export default Step1;
