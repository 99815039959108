import React, { useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Button, Divider, Loader, Input } from "semantic-ui-react";
// import FacebookLogin from "react-facebook-login";
import { GoogleLogin } from "react-google-login";

import "react-toastify/dist/ReactToastify.css";

import { setToken } from "utils";
import Auth from "components/Auth";
import { isEmailValid } from "components/Auth/utils";
import PasswordInput from "components/PasswordInput";

import { maxEmailLength, maxPasswordLength } from "constants/Auth";
import { FACEBOOK_APP_ID, GOOGLE_CLIENT_ID } from "constants/Keys";
import ROUTES from "constants/Routes";

import { userLogin } from "./actions";
import { INVALID_EMAIL_ID, INVALID_PASSWORD } from "./constants";
import { stateToProps, dispatchToProps } from "./maps";
import { userGoogleLoginAPI, userFacebookLoginAPI } from "./actions";

import "./Login.scss";

function Login() {
  const { isLoggedIn, isProfileSetupDone } = useSelector(stateToProps);
  const { setUser } = dispatchToProps(useDispatch());
  const history = useHistory();
  let location = useLocation();
  const { from } = location.state || { from: { pathname: "/" } };

  if (isLoggedIn) {
    if (!isProfileSetupDone) history.replace(ROUTES.PROFILE_SETUP);
    else history.replace(from);
  }

  const [isLoading, setIsLoading] = React.useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const handleEmailChange = useCallback((e) => {
    if (e.target.value.length <= maxEmailLength) setEmail(e.target.value);
  }, []);

  const handlePasswordChange = useCallback((e) => {
    if (e.target.value.length <= maxPasswordLength) setPassword(e.target.value);
  }, []);

  const resetErrors = useCallback((e) => {
    if (e.target.name === "email") {
      setEmailError(false);
    }

    if (e.target.name === "password") {
      setPasswordError(false);
    }
  }, []);

  const handleLogin = useCallback(() => {
    setSubmitting(true);

    let error = false;

    if (!isEmailValid(email)) {
      error = true;
      setEmailError(true);
    }

    if (!password.length) {
      error = true;
      setPasswordError(true);
    }

    if (error) {
      setSubmitting(false);
      return;
    }

    userLogin({ email, password })
      .then((response) => {
        setSubmitting(false);
        setToken(response.data.token, () => {
          setUser({ ...response.data.meta });
        });
      })
      .catch((error) => {
        setSubmitting(false);
        try {
          toast.error(`Login failed! ${error.response.data.error}`);
        } catch (e) {
          toast.error(`Login failed!`);
        }
      });
  }, [email, password, setUser]);

  const handleGoogleLogin = useCallback(
    (e) => {
      setIsLoading(true);

      userGoogleLoginAPI({ id: e.googleId, accessToken: e.tokenId })
        .then((response) => {
          setIsLoading(false);
          setToken(response.data.token, () => {
            setUser({ ...response.data.meta });
          });
        })
        .catch((error) => {
          setIsLoading(false);
          try {
            toast.error(`Login failed! ${error.response.data.error}`);
          } catch (e) {
            toast.error(`Login failed!`);
          }
        });
    },
    [setUser]
  );

  // const handleFacebookLogin = useCallback(
  //   (e) => {
  //     setIsLoading(true);

  //     userFacebookLoginAPI({
  //       id: e.id,
  //       accessToken: e.accessToken,
  //       name: e.name,
  //       email: e.email,
  //     })
  //       .then((response) => {
  //         setIsLoading(false);
  //         setToken(response.data.token, () => {
  //           setUser({ ...response.data.meta });
  //         });
  //       })
  //       .catch((error) => {
  //         setIsLoading(false);
  //         try {
  //           toast.error(`Login failed! ${error.response.data.error}`);
  //         } catch (e) {
  //           toast.error(`Login failed!`);
  //         }
  //       });
  //   },
  //   [setUser]
  // );

  const emailErrorClassName = emailError ? "error" : "";
  const passwordErrorClassName = passwordError ? "error" : "";

  return (
    <Auth>
      <ToastContainer
        className="toast"
        position="bottom-center"
        autoClose={2500}
        hideProgressBar
      />
      <Loader active={isLoading} />
      <h3>Log in and get to work</h3>
      <div className="login-container">
        <Input
          className={emailErrorClassName}
          name="email"
          type="email"
          placeholder="Email"
          maxLength={maxEmailLength}
          spellCheck={false}
          value={email}
          onFocus={resetErrors}
          onChange={handleEmailChange}
        />
        {emailError && <span className="login-error">{INVALID_EMAIL_ID}</span>}
      </div>
      <div className="login-container">
        <PasswordInput
          className={passwordErrorClassName}
          value={password}
          onFocus={resetErrors}
          onChange={handlePasswordChange}
        />
        {passwordError && (
          <span className="login-error">{INVALID_PASSWORD}</span>
        )}
      </div>
      <Link className="login-forgotpassword" to={ROUTES.FORGOT_PASSWORD}>
        Forgot Password?
      </Link>
      <Button
        className="primary"
        onClick={handleLogin}
        loading={submitting}
        fluid
      >
        Login
      </Button>
      {/* <div className="login-container"> */}
      <Divider horizontal className="login-divider">
        Or
      </Divider>
      <GoogleLogin
        clientId={GOOGLE_CLIENT_ID}
        buttonText="Login with Google"
        onSuccess={handleGoogleLogin}
        className="google-login-button"
        onFailure={(e) => console.log("google response fail", e)}
      />
      {/* <FacebookLogin
        appId={FACEBOOK_APP_ID}
        autoLoad={false}
        fields="name, email"
        callback={handleFacebookLogin}
        cssClass="facebook-login-button"
        icon="fa-facebook"
      /> */}
      {/* </div> */}
    </Auth>
  );
}

export default Login;
