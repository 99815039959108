import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { Loader, Button } from "semantic-ui-react";
import { useSelector } from "react-redux";
import RichTextEditor from "react-rte";

import ROUTES from "constants/Routes";
import FormWrapper from "components/FormWrapper";
import { calculateTotalExperience, getFileIcon, getMonthName } from "utils";

import ProfileImagePlaceholder from "static/images/profile-image-placeholder.png";

import { getFreelancerDetail } from "./actions";
import { stateToProps } from "./maps";

import "./FreelancerDetail.scss";

function FreelancerDetail() {
  const history = useHistory();
  const { id: freelancerId } = useParams();
  const { isLoggedIn, userId } = useSelector(stateToProps);

  const [freelancer, setFreelancer] = React.useState({});
  const [pageHasError, setPageHasError] = React.useState(false);
  const [isFetching, setIsFetching] = React.useState(false);
  const currentPortfolio = React.useRef({});

  const goToChat = React.useCallback(async () => {
    if (!isLoggedIn) {
      history.push({ pathname: ROUTES.LOGIN });
      return;
    }

    history.push({
      pathname: "/chat",
      state: { usersInvolved: [freelancerId, userId], chatInitiator: userId },
    });
  }, [history, freelancerId, isLoggedIn, userId]);

  React.useEffect(() => {
    if (!freelancerId) return;

    setIsFetching(true);

    getFreelancerDetail(freelancerId)
      .then((response) => {
        setIsFetching(false);
        setFreelancer(response.data.freelancer);
      })
      .catch(() => {
        setIsFetching(false);
        setPageHasError(true);
      });
  }, [freelancerId]);

  const { years, months } = calculateTotalExperience(freelancer.experience);

  React.useMemo(() => {
    if (!freelancer.portfolio) return;

    const newFreelancerPortfolio = {};

    freelancer.portfolio.forEach((portfolio, i) =>
      newFreelancerPortfolio.hasOwnProperty(portfolio.category)
        ? (newFreelancerPortfolio[portfolio.category] = [
            ...newFreelancerPortfolio[portfolio.category],
            portfolio,
          ])
        : (newFreelancerPortfolio[portfolio.category] = [portfolio])
    );

    currentPortfolio.current = { ...newFreelancerPortfolio };
  }, [freelancer.portfolio]);

  const freelancerKeys = Object.keys(currentPortfolio.current);

  return (
    <FormWrapper
      renderHeader={
        <div className="fcDetail-header">
          <div className="fcDetail-header-first">
            <div className="fcDetail-header-profile">
              <img
                className="fcDetail-header-profile-img"
                src={freelancer.userId?.imageUrl || ProfileImagePlaceholder}
                alt="Profile"
              />
            </div>
            <div className="fcDetail-header-info">
              <h2 className="formWrapper-heading">{freelancer.userId?.name}</h2>
              <p className="formWrapper-subHeading">{freelancer.title}</p>
              <p className="fcDetail-header-info-experience">{`${years}.${months}+ years of experience`}</p>
            </div>
          </div>
          <div className="fcDetail-header-last">
            <div className="fcDetail-header-last-wrapper">
              <Button className="primary" onClick={goToChat}>
                {isLoggedIn ? "Message" : "Login to message"}
              </Button>
            </div>
          </div>
        </div>
      }
    >
      <p style={{ marginTop: "14px", fontSize: "14px" }}>
        {freelancer.description}
      </p>
      <Loader active={isFetching} />
      <div className="fcDetail-section">
        <h4 className="fcDetail-section-heading">Portfolio</h4>
        <div className="fcDetail-section-content">
          {freelancerKeys.map((category, cI) => {
            return (
              <div>
                <div className="fcDetail-section-content-category">
                  {category}
                </div>
                {currentPortfolio.current[category].map((portfolio, pI) => {
                  return (
                    <div style={{ width: "95%", margin: "0 auto" }}>
                      <h4 className="fcDetail-section-content-title">
                        {portfolio.title}
                      </h4>
                      <div className="item-portfolio-description">
                        <div className="fcDetail-section-content-block-heading">
                          Description
                        </div>
                        <RichTextEditor
                          readOnly
                          value={RichTextEditor.createValueFromString(
                            portfolio.description,
                            "html"
                          )}
                        />
                      </div>
                      <div className="fcDetail-section-content-block">
                        <p className="fcDetail-section-content-block-heading">
                          Tools Used
                        </p>
                        {portfolio.toolsUsed.length ? (
                          <p className="fcDetail-section-content-block-content">
                            {portfolio.toolsUsed.join(", ")}
                          </p>
                        ) : (
                          <p className="noDataAdded">No tools added</p>
                        )}
                      </div>
                      <div className="fcDetail-section-content-block">
                        <p className="fcDetail-section-content-block-heading">
                          Documents
                        </p>
                        {portfolio.documents.length ? (
                          portfolio.documents.map((document, index) => {
                            const extension = document.title;
                            const split = extension.split(".");
                            return (
                              <a
                                href={isLoggedIn ? document.url : ROUTES.SIGNUP}
                                target={isLoggedIn && "_blank"}
                                rel="noopener noreferrer"
                              >
                                <div
                                  key={`fcDetailDocuments-${index}`}
                                  style={{
                                    padding: "5px",
                                    display: "flex",
                                  }}
                                >
                                  <img
                                    src={getFileIcon(split[1])}
                                    alt="document"
                                    style={{ height: "30px", width: "auto" }}
                                  />
                                  <p
                                    style={{ fontSize: 10, lineHeight: "30px" }}
                                  >
                                    {split[0]}
                                  </p>
                                </div>
                              </a>
                            );
                          })
                        ) : (
                          <p className="noDataAdded">No documents added</p>
                        )}
                      </div>
                      {pI !== currentPortfolio.current[category].length - 1 && (
                        <div
                          className="line-separator"
                          style={{ margin: "40px auto", width: "70%" }}
                        ></div>
                      )}
                    </div>
                  );
                })}
                {cI !== freelancerKeys.length - 1 && (
                  <div
                    className="line-separator"
                    style={{ margin: "40px auto" }}
                  ></div>
                )}
              </div>
            );
          })}
        </div>
      </div>
      <div className="fcDetail-section">
        <h4 className="fcDetail-section-heading">Work</h4>
        <div className="fcDetail-section-content">
          {freelancer.experience?.map((f, i) => (
            <div>
              <h4 className="fcDetail-section-content-title">{f.title}</h4>
              <p className="fcDetail-section-content-company">{f.company}</p>
              <p className="fcDetail-section-content-subtitle">{`${getMonthName(
                f.startMonth
              )} ${f.startYear} - ${!f.endMonth ? "Present" : f.Month} ${
                !f.endYear ? "" : f.endYear
              }`}</p>
              {i !== freelancer.experience?.length - 1 && (
                <div className="line-separator"></div>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="fcDetail-section">
        <h4 className="fcDetail-section-heading">Education</h4>
        <div className="fcDetail-section-content">
          {Array.isArray(freelancer.education) &&
          freelancer.education.length ? (
            freelancer.education.map((edu, i) => (
              <div>
                <h4 className="fcDetail-section-content-title">
                  {edu.schoolName}
                </h4>
                <p className="fcDetail-section-content-company">
                  {edu.degreeName}
                </p>
                <p className="fcDetail-section-content-subtitle">{`${
                  edu.startDate
                } - ${edu.isCurrent ? "Present" : edu.endDate}`}</p>
                {i !== freelancer.education.length - 1 && (
                  <div className="line-separator"></div>
                )}
              </div>
            ))
          ) : (
            <div className="fcDetail-section-notAdded">
              No education information added yet
            </div>
          )}
        </div>
      </div>
      <div className="fcDetail-section">
        <h4 className="fcDetail-section-heading">Additional Info</h4>
        <div className="fcDetail-section-content">
          <div className="fcDetail-section-content-additionalInfo">
            <div className="ques">What kind of freelancer you are?</div>
            <div className="answer">
              {
                freelancer?.freelancerQuestions?.find(
                  (q) => q.question === "whatKindOfFreelancerYouAre"
                ).answer
              }
            </div>
            <div className="ques">What's your location preference?</div>
            <div className="answer">
              {
                freelancer?.freelancerQuestions?.find(
                  (q) => q.question === "chooseLocationPreference"
                ).answer
              }
            </div>
            <div className="ques">How are your communication skills?</div>
            <div className="answer">
              {
                freelancer?.freelancerQuestions?.find(
                  (q) => q.question === "rateYourCommunicationSkills"
                ).answer
              }
            </div>
          </div>
        </div>
      </div>
    </FormWrapper>
  );
}

export default FreelancerDetail;
