import axios from "axios";

import {
  GET_SUB_CATEGORIES_MISC,
  GET_SUB_SUB_CATEGORIES_MISC,
  GET_CATEGORY_DETAILS,
} from "api/category";

export const getCategoryDetailsAPI = ({ id }) => {
  return axios.post(GET_CATEGORY_DETAILS, { id });
};

export const getSubCategoriesAPI = ({ categoryId }) => {
  return axios.post(GET_SUB_CATEGORIES_MISC, { categoryId });
};

export const getSubSubCategoriesAPI = ({ subCategoryId }) => {
  return axios.post(GET_SUB_SUB_CATEGORIES_MISC, { subCategoryId });
};
