import { JOB_STATUS } from "constants/Job";
import { PROPOSAL_STATUS } from "constants/Proposal";
import { firstLetterCapitalise } from "utils";

export const getJobStatusColor = (status, active) => {
  if (!active) return "red";

  let color;

  switch (status) {
    case JOB_STATUS.OPEN:
      color = "blue";
      break;
    case JOB_STATUS.CLOSED:
      color = "red";
      break;
    case JOB_STATUS.ASSIGNED:
      color = "green";
      break;
    default:
      color = "white";
      break;
  }

  return color;
};

export const getJobStatus = (status, isApproved, isActive) => {
  if (!isApproved) return "In Review";
  else if (!isActive) return "Deactivated";
  else return firstLetterCapitalise(status);
};

export const getProposalStatusColor = (status) => {
  let color = "white";

  switch (status) {
    case PROPOSAL_STATUS.OPEN:
      color = "blue";
      break;
    case PROPOSAL_STATUS.REJECTED:
      color = "red";
      break;
    case PROPOSAL_STATUS.ACCEPTED:
      color = "green";
      break;
    default:
      break;
  }

  return color;
};

export const getContractStatusColor = (status) => {
  let color = "white";

  switch (status) {
    case PROPOSAL_STATUS.OPEN:
      color = "blue";
      break;
    case PROPOSAL_STATUS.REJECTED:
      color = "red";
      break;
    case PROPOSAL_STATUS.ACCEPTED:
      color = "green";
      break;
    default:
      break;
  }

  return color;
};
