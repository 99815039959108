import React from "react";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { Tab } from "semantic-ui-react";

import FormWrapper from "components/FormWrapper";
import ROUTES from "constants/Routes";

import DashboardHeader from "./components/DashboardHeader";
import FindWork from "./components/FindWork";
import SubmittedProposal from "./components/SubmittedProposal";
import MyJobs from "./components/MyJobs";
import MyContracts from "./components/MyContracts";

import { getProfileData } from "containers/Settings/actions";
import { getDashboardJobPosts, getClientJobPosts } from "./actions";
import { stateToProps } from "./maps";

import "./Dashboard.scss";

const getPanes = ({ isClient, jobPosts, loading, userId, getJobPosts }) => {
  let panes = [
    {
      menuItem: "Find Work",
      render: () => (
        <Tab.Pane
          className="settings-tabs-pane"
          attached={false}
          loading={loading}
        >
          <FindWork jobs={jobPosts} getJobPosts={getJobPosts} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Submitted Proposals",
      render: () => (
        <Tab.Pane
          className="settings-tabs-pane"
          attached={false}
          loading={loading}
        >
          <SubmittedProposal />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "My Jobs",
      render: () => (
        <Tab.Pane
          className="settings-tabs-pane"
          attached={false}
          loading={loading}
        >
          <MyContracts userId={userId} />
        </Tab.Pane>
      ),
    },
  ];

  if (isClient) {
    panes = [
      {
        menuItem: "My Job Posts",
        render: () => (
          <Tab.Pane
            className="settings-tabs-pane"
            attached={false}
            loading={loading}
          >
            <MyJobs jobs={jobPosts} />
          </Tab.Pane>
        ),
      },
      {
        menuItem: "Ongoing Jobs",
        render: () => (
          <Tab.Pane
            className="settings-tabs-pane"
            attached={false}
            loading={loading}
          >
            <MyContracts userId={userId} />
          </Tab.Pane>
        ),
      },
    ];
  }

  return panes;
};

function Dashboard() {
  const { isClient, userId } = useSelector(stateToProps);
  const [jobPosts, setJobPosts] = React.useState([]);
  const [isFetching, setIsFetching] = React.useState(false);
  const [isPortfolioPresent, setIsPortfolioPresent] = React.useState(true);

  const getJobPosts = React.useCallback(async () => {
    setIsFetching(true);

    getDashboardJobPosts({ userId })
      .then((response) => {
        setIsFetching(false);
        setJobPosts(response.data.posts || []);
      })
      .catch((error) => {
        setIsFetching(false);
        try {
          toast.error(
            `Fetching job posts failed! ${error.response.data.error}`
          );
        } catch (e) {
          toast.error(`Fetching job posts failed!`);
        }
      });
  }, [userId]);

  React.useEffect(() => {
    const query = { userId };

    if (!isClient) {
      getJobPosts();
      return;
    }

    setIsFetching(true);

    getClientJobPosts(query)
      .then((response) => {
        setIsFetching(false);
        setJobPosts(response.data.posts || []);
      })
      .catch((error) => {
        setIsFetching(false);
        try {
          toast.error(
            `Fetching job posts failed! ${error.response.data.error}`
          );
        } catch (e) {
          toast.error(`Fetching job posts failed!`);
        }
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (isClient) return;

    getProfileData(userId).then((response) => {
      setIsPortfolioPresent(
        response.data.profile.portfolio.length >= 2
          ? !!response.data.profile.portfolio.filter((p) => !!p.title).length
          : false
      );
    });
  }, [userId, isClient]);

  const allPanes = React.useCallback(
    () =>
      getPanes({
        isClient,
        jobPosts,
        userId,
        getJobPosts,
        loading: isFetching,
      }),
    [isClient, jobPosts, isFetching, userId, getJobPosts]
  );

  return (
    <>
      {!isClient && !isPortfolioPresent && (
        <div className="dashboard-warning">
          Please add portfolio to apply for jobs.{" "}
          <a href={ROUTES.PROFILE}>Click here to add</a>
        </div>
      )}
      <FormWrapper renderHeader={<DashboardHeader isClient={isClient} />}>
        <ToastContainer
          className="toast"
          position="bottom-center"
          autoClose={2500}
          hideProgressBar
        />
        <Tab
          className="ui grid dashboard-tabs"
          menu={{
            secondary: true,
            vertical: true,
            fluid: true,
          }}
          panes={allPanes()}
        />
      </FormWrapper>
    </>
  );
}

export default Dashboard;
