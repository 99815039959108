import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

import ROUTES from "constants/Routes";
import { stateToProps } from "./maps";

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated or your profile setup is not done or user is a freelancer.
function ClientPrivateRoute({ children, ...rest }) {
  const { isLoggedIn, isProfileSetupDone, isClient } = useSelector(
    stateToProps
  );

  const handleRender = React.useCallback(
    ({ location }) => {
      if (!isLoggedIn) {
        return (
          <Redirect
            to={{ pathname: ROUTES.LOGIN, state: { from: location } }}
          />
        );
      }

      if (!isProfileSetupDone) {
        return (
          <Redirect
            to={{ pathname: ROUTES.PROFILE_SETUP, state: { from: location } }}
          />
        );
      }

      if (!isClient) {
        return <Redirect to={{ pathname: ROUTES.HOME }} />;
      }

      return children;
    },
    [isClient, isLoggedIn, isProfileSetupDone, children]
  );

  return <Route {...rest} render={handleRender} />;
}

export default ClientPrivateRoute;
