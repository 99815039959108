import { authorizedGet, authorizedPost } from "api";
import {
  PROFILE_URL,
  RESET_PASSWORD_URL,
  PROFILE_IMAGE_URL,
  USER_UPLOAD,
} from "api/user";
import { GET_ALL_SKILLS } from "api/job";

export const getProfileData = (id) => {
  return authorizedGet(`${PROFILE_URL.replace(":id", id)}`);
};

export const saveProfileData = (id, data) => {
  return authorizedPost(`${PROFILE_URL.replace(":id", id)}`, data);
};

export const resetPasswordData = (data) => {
  return authorizedPost(`${RESET_PASSWORD_URL}`, data);
};

export const imageData = (id, data) => {
  return authorizedPost(`${PROFILE_IMAGE_URL.replace(":id", id)}`, data);
};

export const getAllSkills = () => {
  return authorizedGet(GET_ALL_SKILLS);
};

export const uploadFile = (selectedDocs) => {
  const formData = new FormData();
  for (let i = 0; i < selectedDocs.length; i++) {
    formData.append("files", selectedDocs[i], selectedDocs[i].name);
  }

  return authorizedPost(USER_UPLOAD, formData);
};
