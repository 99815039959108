export const MAX_FILE_SIZE = 10 * 1024 * 1024;

export const ALLOWED_MIMETYPES = [
  "application/pdf",
  "application/msword",
  "image/jpeg",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
];

export const JOB_POST_STEPS = [
  {
    id: "job-post-step-0",
    title: "Primary fields",
    details: "Basic information about Posting",
  },
  {
    id: "job-post-step-1",
    title: "Skills required",
    details: "Skills, budget, etc.",
  },
  {
    id: "job-post-step-2",
    title: "Additional details",
    details: "Any additional details",
  },
];

export const TYPE_OF_LOCATION = [
  {
    id: "type-of-location-1",
    value: true,
    label: "Remote",
  },
  {
    id: "type-of-location-1",
    value: false,
    label: "Specific",
  },
];

export const TYPE_OF_FREELANCERS = [
  {
    id: "1",
    value: "entry",
    label: "Entry",
    heading: "Entry level",
    details: "I am new to this field",
  },
  {
    id: "2",
    value: "intermediate",
    label: "Intermediate",
    heading: "Intermediate",
    details: "I have worked in this field for sometime",
  },
  {
    id: "3",
    value: "expert",
    label: "Expert",
    heading: "Expert level",
    details:
      "I have deep expertise in this field, along with years of experience",
  },
];

export const SELECTION_PROCESS = [
  {
    id: "selection-process-1",
    value: "myself",
    label: "Will hire myself",
  },
  {
    id: "selection-process-2",
    value: "epTeam",
    label: "Assistance from EP team",
  },
];

export const SERVICE_PROVIDER_TYPE = [
  {
    id: "service-provider-1",
    value: "freelancer",
    label: "Freelancer",
  },
  {
    id: "service-provider-2",
    value: "agency",
    label: "Agency",
  },
  {
    id: "service-provider-3",
    value: "anyone",
    label: "Anyone",
  },
];

export const BUDGET_RANGE = [
  { id: "budget-range-1", text: "500-2000", value: "500-2000" },
  { id: "budget-range-2", text: "2000-7000", value: "2000-7000" },
  { id: "budget-range-3", text: "7000-25000", value: "7000-25000" },
  { id: "budget-range-4", text: "25,000-70,000", value: "25000-70000" },
  { id: "budget-range-5", text: "70,000-2,00,000", value: "70000-200000" },
  {
    id: "budget-range-6",
    text: "2,00,000-5,00,000",
    value: "200000-500000",
  },
  {
    id: "budget-range-7",
    text: "5,00,000-10,00,000",
    value: "500000-1000000",
  },
  {
    id: "budget-range-8",
    text: "10,00,000-18,00,000",
    value: "1000000-1800000",
  },
  { id: "budget-range-9", text: "18,00,000+", value: "18,00,000+" },
];

export const JOB_DURATION = [
  { id: "job-duration-1", value: "7d", text: "Less then a week" },
  { id: "job-duration-2", value: "2w-3w", text: "2-3 Weeks" },
  { id: "job-duration-3", value: "1m", text: "1 Month" },
  { id: "job-duration-4", value: "2m-3m", text: "2-3 Months" },
];
