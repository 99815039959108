import React from "react";
import { Link } from "react-router-dom";
import { Button, Label, Icon, Modal, Input } from "semantic-ui-react";

import ROUTES from "constants/Routes";
import NewWindow from "static/icons/new-window.svg";

import { CONTRACT_STATUS } from "constants/Proposal";
import { getContractStatusColor } from "utils/job";
import { firstLetterCapitalise } from "utils";

import "./CommonView.scss";

function CommonView({
  contract,
  isClient,
  goToChat,
  bidValue,
  changeBidValue,
  handleUpdateContract,
  handleWithdrawAccept,
}) {
  return (
    <>
      <div className="jobContract-body">
        <div className="jobContract-freelancer-details">
          <h4>{contract?.jobId?.title}</h4>
          <Link
            className="jobDetailWrapper-sidebar-header-link jobContract-freelancer-link jobContract-job-link"
            to={`${ROUTES.JOB_POST_DETAIL.replace(
              ":id",
              contract?.jobId?._id
            )}`}
            target="_blank"
          >
            <img src={NewWindow} alt="" />
            Open Job in a new window
          </Link>
        </div>
        <p className="jobDetail-content-description">
          {contract?.jobId?.description}
        </p>
        <div className="jobDetail-content-skills">
          <p className="jobDetail-content-skills-heading">Freelance Details:</p>
          <div className="jobContract-freelancer-details">
            <p>{contract?.applicationId?.userId?.name}</p>
            <Link
              className="jobDetailWrapper-sidebar-header-link jobContract-freelancer-link"
              style={{ display: "block" }}
              to={`${ROUTES.VIEW_SPECIFIC_FREELANCER.replace(
                ":id",
                contract?.applicationId?.userId?._id
              )}`}
              target="_blank"
            >
              <img src={NewWindow} alt="" />
              Open freelancer profile in a new window
            </Link>
          </div>
        </div>
        {contract.status === CONTRACT_STATUS.OPEN && (
          <div className="jobContract-actionables">
            <Button icon labelPosition="left" basic primary onClick={goToChat}>
              <Icon name="chat" className="chat-icon" />
              Message
            </Button>
            {isClient && (
              <Modal
                className="jobDetailProposals-modal"
                trigger={<Button className="alternative">Edit</Button>}
                content={
                  <div className="jobDetailProposals-terms">
                    <h3>Terms for the hire</h3>
                    <h4>Bid for the project</h4>
                    <p>
                      This is the price you and{" "}
                      {contract?.applicationId?.userId?.name} have agreed upon
                    </p>
                    <Input
                      name="bid"
                      labelPosition="right"
                      type="number"
                      placeholder="Amount"
                      className="jobProposalBody-terms-answer"
                      value={bidValue}
                      onChange={(e) => changeBidValue(e.target.value)}
                    >
                      <Label basic>₹</Label>
                      <input className="side-padding" />
                      <Label>.00</Label>
                    </Input>
                    <div className="jobDetailProposals-terms-checkbox">
                      By accepting, you understand and agree to the ExpertRight
                      Terms of Service, including the User Agreement and Privacy
                      Policy.
                    </div>
                  </div>
                }
                actions={[
                  "Cancel",
                  {
                    key: "accept",
                    content: `Agree Terms & Hire ${contract?.applicationId?.userId?.name}`,
                    positive: true,
                    onClick: handleUpdateContract,
                  },
                ]}
              />
            )}
          </div>
        )}
      </div>
      <div className="jobContract-sidebar">
        {isClient ? (
          <Button
            className="primary"
            onClick={handleWithdrawAccept}
            disabled={contract.status !== CONTRACT_STATUS.OPEN}
          >
            {"Withdraw Contract"}
          </Button>
        ) : (
          <Button
            className="primary"
            onClick={handleWithdrawAccept}
            disabled={contract.status !== CONTRACT_STATUS.OPEN}
          >
            {"Accept Contract"}
          </Button>
        )}
        <div className="jobDetail-actionables-figures">
          <div className="jobDetail-actionables-figures-item">
            <Label color={getContractStatusColor(contract.status)}>
              {firstLetterCapitalise(contract.status)}
            </Label>
            {contract.status === "open" && isClient && (
              <p className="waiting-on-freelancer">
                Waiting for freelancer
                <br /> to accept the contract
              </p>
            )}
            <span>Status</span>
          </div>
          <div className="jobDetail-actionables-figures-divider"></div>
        </div>
        <div className="jobDetail-actionables-figures-item">
          <p>{contract.bid}</p>
          <span>Bid</span>
        </div>
        <div className="jobDetail-actionables-figures-divider"></div>
        <div className="jobDetail-actionables-figures-item">
          <p>{contract?.jobId?.duration}</p>
          <span>Est. Time</span>
        </div>
      </div>
    </>
  );
}

export default CommonView;
