import React from "react";
import { useHistory } from 'react-router-dom'

import { DISPLAY_FEATURES, DISPLAY_STATS } from "../../constants";
import "./Features.scss";

function Features() {
  const history = useHistory();
  return (
    <div className="features">
      <div className="features-wrapper">
        <h2 className="features-title">Our Features</h2>
        <p className="features-subHeading">
          Meet the professional talent at ExpertRight
        </p>
        <div className="features-explain">
          <div className="features-list">
            {DISPLAY_FEATURES.map((feature) => (
              <div key={feature.id} className="features-list-item">
                <img
                  src={feature.icon}
                  className="features-list-item-image"
                  alt=""
                />
                <div className="features-list-item-content">
                  <h4>{feature.title}</h4>
                  <p>{feature.subtext}</p>
                </div>
              </div>
            ))}
          </div>
          <div className="features-stats">
            {DISPLAY_STATS.map((stat) => (
              <div key={stat.id} className="features-stats-item">
                <div className="features-stats-item-image">
                  <img src={stat.icon} alt="" />
                </div>
                <div className="features-stats-item-content">
                  <h4>{stat.stat}</h4>
                  <p>{stat.text}</p>
                </div>
              </div>
            ))}
            <div className="features-stats-join">
              <p>
                Don't wait up! Join now to get unlimited access to talent pool
              </p>
              <button className="primary" onClick={() => history.push("/signup")}>Sign Up</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Features;
