import React, { useEffect, useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { Grid, Segment, Sidebar, Modal, TextArea } from "semantic-ui-react";

import LogoHorizontal from "static/images/logoHorizontal.png";

import HeaderProfile from "./components/HeaderProfile";
import LoggedOut from "./components/LoggedOut";
import Notifications from "./components/Notifications";

import {
  LOGGED_OUT_LINKS,
  CLIENT_LINKS,
  FREELANCER_LINKS,
} from "./constants/index";
import { raiseQuery } from "./actions";
import { stateToProps, dispatchToProps } from "./maps";
import HamburgerLogo from "../../static/icons/hamburger-menu.svg";
import HamburgerClose from "../../static/icons/hamburger-close.svg";
import "./Header.scss";

function RespHeader() {
  const { isLoggedIn, isProfileSetupDone, isClient, userId } =
    useSelector(stateToProps);
  const [visible, setVisible] = React.useState(false);
  const { logoutUser } = dispatchToProps(useDispatch());
  const [shouldShowShadow, setShouldShowShadow] = useState(false);
  const [query, setQuery] = React.useState("");

  const windowHeight = useCallback(() => {
    if (window.pageYOffset > 0 && !shouldShowShadow) {
      setShouldShowShadow(true);
    } else if (window.pageYOffset === 0 && shouldShowShadow) {
      setShouldShowShadow(false);
    }
  }, [shouldShowShadow]);

  const raiseAQuery = React.useCallback(() => {
    if (!query) {
      toast.error("Tell us about your query");
      return;
    }

    raiseQuery(userId, { query })
      .then(() => {
        setQuery("");
        toast.success(
          "Query successfully raised. Our team will get back to you."
        );
      })
      .catch(() =>
        toast.error(
          "Raising query failed. Please contact us at info@expertright.com"
        )
      );
  }, [query, userId]);

  // componentDidMount
  useEffect(() => {
    window.addEventListener("scroll", windowHeight);

    return () => {
      window.removeEventListener("scroll", windowHeight);
    };
  }, [windowHeight]);

  const shadowClassName = shouldShowShadow ? "header-shadow" : null;

  let headerLinks = LOGGED_OUT_LINKS;

  if (isLoggedIn) {
    if (isProfileSetupDone) {
      headerLinks = isClient ? CLIENT_LINKS : FREELANCER_LINKS;
    } else {
      headerLinks = [];
    }
  }

  return (
    <React.Fragment>
      <ToastContainer
        className="toast"
        position="bottom-center"
        autoClose={2500}
        hideProgressBar
      />
      <header className={shadowClassName}>
        <Link className="header-logo-wrapper" to="/">
          <img
            className="header-logo"
            src={LogoHorizontal}
            alt="Expert Right"
          />
        </Link>
        <div className="header-links" id="headerCollapse">
          {isLoggedIn && (
            <Modal
              trigger={
                <div className="header-links-withMargin header-query">
                  Have a query?
                </div>
              }
              content={
                <div className="raise-a-dispute-modal">
                  <div>Raise a query</div>
                  <TextArea
                    placeholder="Tell us about your query"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                  />
                </div>
              }
              actions={[
                "Cancel",
                {
                  key: "submit",
                  content: `Submit`,
                  positive: true,
                  onClick: raiseAQuery,
                },
              ]}
            />
          )}
          <div>
            {headerLinks.map((link) => (
              <a
                className="header-links-withMargin"
                key={link.id}
                href={link.url}
              >
                {link.text}
              </a>
            ))}
          </div>
          {isLoggedIn ? (
            <>
              <Notifications userId={userId} />
              <HeaderProfile
                isProfileSetupDone={isProfileSetupDone}
                handleLogout={logoutUser}
              />
            </>
          ) : (
            <LoggedOut />
          )}
        </div>
      </header>

      {/* Hamburger menu */}
      <Grid.Column>
        <header1 className="">
          <div className="headerWrapper">
            <Link className="headerLogoWrapper" to="/">
              <img
                className="headerLogo"
                src={LogoHorizontal}
                alt="Expert Right"
              />
            </Link>
            <button
              onClick={(e, data) => setVisible(!visible)}
              className="hamburgerIcon"
            >
              <img src={HamburgerLogo} className="togglerLogo" alt="Menu" />
            </button>
          </div>

          <Sidebar
            as={Segment}
            animation="push"
            direction="right"
            visible={visible}
            className="toggledSidebar"
          >
            <div className="header1-links" id="headerCollapse">
              <button
                onClick={(e, data) => setVisible(!visible)}
                className="hamburgerIcon hamburgerClose"
              >
                <img src={HamburgerClose} className="togglerLogo" alt="Close" />
              </button>
              <div>
                {headerLinks.map((link) => (
                  <div>
                    <Link
                      className="header1-links-withMargin"
                      key={link.id}
                      to={link.url}
                    >
                      {link.text}
                    </Link>
                  </div>
                ))}
              </div>
              <div className="authBtn">
                {isLoggedIn ? (
                  <HeaderProfile
                    isProfileSetupDone={isProfileSetupDone}
                    handleLogout={logoutUser}
                  />
                ) : (
                  <LoggedOut />
                )}
              </div>
            </div>
          </Sidebar>
        </header1>
      </Grid.Column>
    </React.Fragment>
  );
}

export default RespHeader;
