import { SET_USER, LOGOUT_USER, SET_USER_META } from "actions/auth";

const initialState = {
  isLoggedIn: false,
};

export default function userReducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_USER:
      return { ...state, ...action.data, isLoggedIn: true };

    case SET_USER_META:
      return { ...state, ...action.data };

    case LOGOUT_USER:
      return { initialState };

    default:
      return state;
  }
}
