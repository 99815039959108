import React from "react";
import { Button } from "semantic-ui-react";
import { ToastContainer, toast } from "react-toastify";

import { maxEmailLength } from "constants/Auth";
import { unauthorizedPost } from "api";
import { SEND_RESET_PASSWORD_EMAIL } from "api/auth";
import Auth from "components/Auth";

import "./ForgotPassword.scss";

function ForgotPassword() {
  const [email, setEmail] = React.useState("");
  const [emailError, setEmailError] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [submitted, setSubmitted] = React.useState(false);

  const handleSubmit = React.useCallback(async () => {
    if (!email) {
      toast.error("Add email to proceed");
      return;
    }

    setLoading(true);

    unauthorizedPost(SEND_RESET_PASSWORD_EMAIL, { email })
      .then(() => {
        setLoading(false);
        setSubmitted(true);
      })
      .catch((error) => {
        toast.error("Email does not exist");
        console.error(error);
        setLoading(false);
      });
  }, [email]);

  return (
    <Auth>
      <ToastContainer
        className="toast"
        position="bottom-center"
        autoClose={2500}
        hideProgressBar
      />
      <h3>Reset your password</h3>
      {!submitted ? (
        <div className="forgotPassword-container">
          <p className="forgotPassword-content">
            Fill in your Email that you used to signup with Expertright. We will
            send you an email with the instructions to set a new password.
          </p>
          <input
            name="email"
            type="email"
            placeholder="Email"
            maxLength={maxEmailLength}
            spellCheck={false}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {emailError && <span className="login-error">{}</span>}
          <Button
            className="primary forgotPassword-button"
            onClick={handleSubmit}
            loading={loading}
            fluid
          >
            Send reset email
          </Button>
        </div>
      ) : (
        <>
          <div className="forgotPassword-content">
            <b>Password reset successful!</b>
          </div>
          <div> Check your email for further instructions.</div>
        </>
      )}
    </Auth>
  );
}

export default ForgotPassword;
