import React, { useState, useEffect, useCallback } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import FormWrapper from "components/FormWrapper";
import { DISPLAY_CATEGORIES } from "containers/HomePage/constants";
import ROUTES from "constants/Routes";

import { SUB_CATEGORIES_LIST, SUB_SUB_CATEGORIES_LIST } from "./constants";
import Step1 from "./components/Step1";
import Step2 from "./components/Step2";
import { stateToProps, dispatchToProps } from "./maps";
import {
  getSubCategoriesAPI,
  getSubSubCategoriesAPI,
  getCategoryDetailsAPI,
} from "./actions";
import "./SubCategoryForm.scss";

function SubCategoryForm() {
  const history = useHistory();
  const { isLoggedIn, category } = useSelector(stateToProps);
  const {
    getSubCategories,
    setSubCategories,
    getSubSubCategories,
    setSubSubCategories,
  } = dispatchToProps(useDispatch());
  const [subCategoryOption, setSubCategoryOption] = useState("");
  const [subSubCategoryOption, setSubSubCategoryOption] = useState("");
  const [categoryTitle, setCategoryTitle] = useState("");
  const [formStep, setFormStep] = useState(0);
  const { categoryId } = useParams();
  useEffect(() => {
    getCategoryDetailsAPI({ id: categoryId })
      .then((res) => {
        setCategoryTitle(res.data.data.title);
      })
      .catch((error) => {
        console.log("error", error);
      });
    getSubCategoriesAPI({ categoryId: categoryId })
      .then((res) => {
        setSubCategories(res.data.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);
  const subCategories = category.sub_categories || [];
  let subSubCategories;
  subSubCategories = category.sub_sub_categories || [];

  const handleOnChange = useCallback(
    (value) => setSubCategoryOption(value),
    []
  );

  const handleOnSubSubChange = useCallback(
    (value) => setSubSubCategoryOption(value),
    []
  );

  const handleNextStep = useCallback(() => {
    getSubSubCategoriesAPI({ subCategoryId: subCategoryOption })
      .then((res) => {
        setSubSubCategories(res.data.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
    if (formStep === 0 && category.sub_sub_categories.length) {
      if (!subCategoryOption) return;
      setFormStep(1);
    } else if (!isLoggedIn) {
      history.push({
        pathname: ROUTES.SIGNUP,
        data: {
          redirectUrl: `/job-post/new?category=${subCategoryOption}&subSubCategory=${subSubCategoryOption}`,
        },
      });
    } else {
      history.push("/job-post/new");
    }
  }, [
    isLoggedIn,
    history,
    formStep,
    subCategoryOption,
    subSubCategories,
    subSubCategoryOption,
    category?.sub_sub_categories.length,
    setSubSubCategories,
  ]);

  const spacedApartClassName = formStep ? "spacedApart" : "";
  const disabledClassName = !subCategoryOption ? "disabled" : "";

  return (
    <FormWrapper
      heading="Select Sub Categories to proceed"
      subHeading={`Select the type of ${categoryTitle} work you want`}
    >
      <div className="subCategory-wrapper">
        {formStep === 0 ? (
          <Step1
            subCategoryOption={subCategoryOption}
            subCategories={subCategories}
            handleOnChange={handleOnChange}
          />
        ) : (
          <Step2
            subSubCategories={subSubCategories}
            subSubCategoryOption={subSubCategoryOption}
            handleOnChange={handleOnSubSubChange}
          />
        )}
      </div>
      <div className={`subCategory-buttons ${spacedApartClassName}`}>
        {!!formStep && (
          <button className="alternative" onClick={() => setFormStep(0)}>
            Previous
          </button>
        )}
        <button
          className={`alternative ${disabledClassName}`}
          onClick={handleNextStep}
        >
          Next
        </button>
      </div>
    </FormWrapper>
  );
}

export default SubCategoryForm;
