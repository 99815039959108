import React, { useCallback, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Input, Button, Dropdown, TextArea } from "semantic-ui-react";
import { getAllCategory } from "../../actions/index";

import "./Step1.scss";

function Step1({ title, category, description, onChange, handleNextStep }) {
  const [CATEGORY_TYPES, setCATEGORY_TYPES] = useState();

  const handleOnChange = useCallback(
    (e) => {
      onChange(e.target.name, e.target.value);
    },
    [onChange]
  );

  const isEnabled = title && category && description;

  const changeToNextStep = useCallback(() => {
    if (title && category && description) {
      handleNextStep();
    }
  }, [title, category, description, handleNextStep]);

  useEffect(() => {
    getAllCategory().then((response) => {
      const categories = [];
      response.data.data.map(({ title, _id }) =>
        categories.push({ text: title, value: _id })
      );
      setCATEGORY_TYPES(categories);
    });
  }, []);

  return (
    <div className="step1">
      <div className="step1-field">
        <Input
          name="title"
          type="text"
          placeholder="Title"
          value={title}
          spellCheck={false}
          onChange={handleOnChange}
        />
        <label htmlFor="title">Job Title*</label>
      </div>
      <div className="step1-field">
        <Dropdown
          value={category}
          options={CATEGORY_TYPES}
          onChange={(e, data) =>
            handleOnChange({ target: { name: "category", value: data.value } })
          }
          fluid
          search
          selection
        />
        <label htmlFor="category">Category*</label>
      </div>
      <div className="step1-field textarea">
        <TextArea
          name="description"
          placeholder="Add some project description here"
          rows={6}
          value={description}
          spellCheck={false}
          onChange={handleOnChange}
        ></TextArea>
        <label htmlFor="description">Project Description*</label>
      </div>
      <div className="step1-buttons">
        <Button
          className="alternative"
          onClick={changeToNextStep}
          disabled={!isEnabled}
        >
          Next
        </Button>
      </div>
    </div>
  );
}

Step1.propTypes = {
  title: PropTypes.string,
  category: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }),
  description: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  handleNextStep: PropTypes.func.isRequired,
};

Step1.defaultProps = {
  title: "",
  category: null,
  description: "",
};

export default Step1;
