import React from "react";

import ProfileImagePlaceholder from "static/images/profile-image-placeholder.png";

import "./ChatTabs.scss";

function ChatTabs({ chats, activeChatRoom, currentUserId, onChatClick }) {
  return (
    <div className="chatTabs">
      {chats.map((chat) => {
        const otherUser = chat.usersInvolved.filter(
          ({ _id }) => _id !== currentUserId
        )[0];
        return (
          <div
            className={`chatTabs-wrapper ${
              chat._id === activeChatRoom?._id ? "active" : ""
            }`}
            onClick={() => onChatClick(chat)}
          >
            <img
              className="chatTabs-wrapper-image"
              src={otherUser.imageUrl || ProfileImagePlaceholder}
              alt=""
            />
            <div className="chatTabs-wrapper-name">{otherUser.name}</div>
          </div>
        );
      })}
    </div>
  );
}

export default ChatTabs;
