import React from "react";
import { useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Loader } from "semantic-ui-react";

import { CONTRACT_STATUS, MINIMUM_PAYMENT_VALUE } from "constants/Proposal";
import { RAZORPAY_KEY_ID } from "constants/Keys";

import CommonView from "./components/CommonView";
import ClientView from "./components/ClientView";
import FreelancerView from "./components/FreelancerView";

import { stateToProps } from "./maps";
import {
  getContract,
  acceptContract,
  withdrawContract,
  updateContract,
  getPaymentLink,
  verifyPayment,
  submitForReview,
  acceptRejectReview,
  raiseDispute,
} from "./actions";

import "./JobContract.scss";

function JobContract({ location }) {
  const history = useHistory();
  const { id } = useParams();
  const [isFetching, setIsFetching] = React.useState(false);
  const [isVerifyingPayment, setIsVerifyingPayment] = React.useState(false);
  const [bidValue, setBidValue] = React.useState(-1);
  const [contract, setContract] = React.useState({
    jobId: {
      userId: "",
    },
    applicationId: { userId: "" },
  });
  const { isClient, userId, name, email, phone } = useSelector(stateToProps);

  React.useEffect(() => {
    if (!id) return;

    setIsFetching(true);

    getContract(id)
      .then((response) => {
        setContract(response.data.contract);
        setBidValue(response.data.contract.bid);
        setIsFetching(false);
      })
      .catch(() => {
        toast.error(
          "Error occurred while fetching contract. Please try again."
        );
        setIsFetching(false);
      });
  }, [id]);

  const userIdForJob = contract?.jobId?.ownerId;
  const userIdForFreelancer = contract?.applicationId?.userId?._id;

  const goToChat = React.useCallback(async () => {
    const userIdForChat = isClient ? userIdForFreelancer : userIdForJob;
    history.push({
      pathname: "/chat",
      state: { usersInvolved: [userIdForChat, userId], chatInitiator: userId },
    });
  }, [isClient, userIdForJob, userIdForFreelancer, history, userId]);

  const handleWithdrawAccept = React.useCallback(() => {
    if (contract.status !== CONTRACT_STATUS.OPEN) {
      toast.error("This contract is not open.");
      return;
    }

    const contractAction = isClient ? withdrawContract : acceptContract;

    setIsFetching(true);

    contractAction(contract._id, { userId })
      .then(() => {
        setIsFetching(false);
        setContract((contract) => ({
          ...contract,
          status: isClient
            ? CONTRACT_STATUS.REJECTED
            : CONTRACT_STATUS.ACCEPTED,
        }));
      })
      .catch((error) => {
        toast.error("Error occurred! Please try again.");
        setIsFetching(false);
      });
  }, [contract, isClient, userId]);

  const handleUpdateContract = React.useCallback(() => {
    if (contract.status !== CONTRACT_STATUS.OPEN) {
      toast.error("This contract is not open.");
      return;
    }

    if (bidValue === contract.bid) {
      toast.error("Bid value cannot be the same.");
      return;
    }

    updateContract(contract._id, { userId, bid: bidValue })
      .then((response) => {
        setIsFetching(false);
        setContract(response.data.contract);
      })
      .catch((error) => {
        toast.error("Error occurred! Please try again.");
        setIsFetching(false);
      });
  }, [contract, bidValue, userId]);

  const verifyPaymentHandler = React.useCallback(
    ({ milestoneId, order_id, payment_id, signature }, cb) => {
      setIsVerifyingPayment(true);
      verifyPayment({
        milestoneId,
        order_id,
        payment_id,
        signature,
      })
        .then(({ data }) => {
          toast.success("Successfully Paid! Milestone is added to the Job.");
          setIsVerifyingPayment(false);
          setContract((contract) => ({
            ...contract,
            milestones: [...(contract.milestones || []), data],
          }));
          setIsVerifyingPayment(false);
          cb(true);
        })
        .catch((e) => {
          setIsVerifyingPayment(false);
          toast.error(`${e.error}`);
          cb(false);
        });
    },
    []
  );

  const handlePayment = React.useCallback(
    (milestoneValue, milestone, cb) => {
      if (milestoneValue < MINIMUM_PAYMENT_VALUE * contract.bid) {
        toast.error(
          `Milestone value cannot be less Rs${
            MINIMUM_PAYMENT_VALUE * contract.bid
          }`
        );
        cb(false);
        return;
      }

      getPaymentLink({
        amount: milestoneValue,
        contractId: contract._id,
        userId,
        milestone,
      })
        .then(({ data }) => {
          const { amount, notes, id } = data.order;
          const options = {
            key: RAZORPAY_KEY_ID,
            amount: amount,
            currency: "INR",
            name: "ExpertRight",
            description: "Milestone Payment",
            image:
              "https://www.exp`ertright.com/static/media/logoHorizontal.ef5c1d0c.png",
            order_id: id,
            handler: (response) =>
              verifyPaymentHandler(
                {
                  milestoneId: notes.milestoneId,
                  order_id: id,
                  payment_id: response.razorpay_payment_id,
                  signature: response.razorpay_signature,
                },
                cb
              ),
            prefill: {
              name,
              email,
              contact: `+91${phone}`,
            },
            theme: {
              color: "#5c6d88",
            },
            modal: {
              ondismiss: function () {
                cb();
              },
            },
          };
          const razorpay = new window.Razorpay(options);
          razorpay.on("payment.failed", (errorResponse) => {
            toast.error("Payment Failed! Please try again");
            cb(false);
          });
          razorpay.open();
        })
        .catch((e) => {
          toast.error(e);
        });
    },
    [
      contract._id,
      userId,
      contract.bid,
      email,
      name,
      phone,
      verifyPaymentHandler,
    ]
  );

  const changeBidValue = React.useCallback(
    (newValue) => setBidValue(newValue),
    []
  );

  const handleReviewSubmit = React.useCallback((milestoneId, topicId) => {
    submitForReview({ milestoneId, topicId })
      .then(({ data }) => {
        setContract((contract) => ({
          ...contract,
          milestones: contract.milestones.map((milestone) => {
            if (milestone._id === data.milestone._id) return data.milestone;
            return milestone;
          }),
        }));
      })
      .catch((error) => {
        toast.error(
          error?.response?.data?.error || "Error occurred! Please try again."
        );
      });
  }, []);

  const acceptOrRejectReview = React.useCallback(
    (milestoneId, topicId, status, note) => {
      acceptRejectReview({ milestoneId, topicId, status, note })
        .then(({ data }) => {
          setContract((contract) => ({
            ...contract,
            milestones: contract.milestones.map((milestone) => {
              if (milestone._id === data.milestone._id) return data.milestone;
              return milestone;
            }),
          }));
        })
        .catch((error) => {
          toast.error(
            error?.response?.data?.error || "Error occurred! Please try again."
          );
        });
    },
    []
  );

  const raiseADispute = React.useCallback(
    (dispute) => {
      raiseDispute(contract._id, { dispute, isClient })
        .then(() =>
          toast.success(
            "Dispute successfully raised. Our team at Expertright will get back to you soon."
          )
        )
        .catch(() =>
          toast.error(
            "Failed to raise a dispute. Please contact us at info@expertright.com"
          )
        );
    },
    [contract._id, isClient]
  );

  const Component =
    contract.status !== CONTRACT_STATUS.ACCEPTED
      ? CommonView
      : isClient
      ? ClientView
      : FreelancerView;

  const props =
    contract.status !== CONTRACT_STATUS.ACCEPTED
      ? {
          contract,
          isClient,
          goToChat,
          bidValue,
          changeBidValue,
          handleUpdateContract,
          handleWithdrawAccept,
        }
      : isClient
      ? {
          contract,
          getPaymentLink: handlePayment,
          goToChat,
          acceptOrRejectReview,
          raiseADispute,
        }
      : { contract, goToChat, handleReviewSubmit, raiseADispute };

  return (
    <div className="jobContract-wrapper">
      <ToastContainer
        className="toast"
        position="bottom-center"
        autoClose={2500}
        hideProgressBar
      />
      <Loader active={isFetching || isVerifyingPayment} />
      <div className="jobContract">
        <Component {...props} />
      </div>
    </div>
  );
}

export default JobContract;
