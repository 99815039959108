import React from "react";
import PropTypes from "prop-types";
import { Input, Dropdown, Radio, Button } from "semantic-ui-react";

import {getAllSkills} from '../../actions';

import {
  BUDGET_RANGE,
  TYPE_OF_FREELANCERS,
  TYPE_OF_LOCATION,
} from "../../constants";
import "./Step2.scss";

function Step2({
  skills,
  isRemote,
  location,
  budget,
  levelOfFreelancer,
  onChange,
  handleNextStep,
  handlePreviousStep,
}) {
  const [skillsOptions, setSkillsOptions] = React.useState([])
  
  React.useEffect(() => {
    getAllSkills()
      .then((response) => {
        const skills = [];
        response.data.data.map(({ skill, _id }) =>
          skills.push({ text: skill, value: _id })
        );

        setSkillsOptions(skills);
      })
      .catch((err) => console.log("ERROR OCCURED!", err));
  }, []);


  const handleOnChange = React.useCallback(
    (e) => {
      onChange(e.target.name, e.target.value);
    },
    [onChange]
  );

  const isNextEnabled =
    Array.isArray(skills) && skills.length && budget && levelOfFreelancer;

  const changeToNextStep = React.useCallback(() => {
    if (Array.isArray(skills) && skills.length && budget && levelOfFreelancer) {
      handleNextStep();
    }
  }, [skills, budget, levelOfFreelancer, handleNextStep]);

  return (
    <div className="step2">
      <div className="step2-field">
        <Dropdown
          value={skills}
          options={skillsOptions}
          onChange={(e, data) =>
            handleOnChange({ target: { name: "skills", value: data.value } })
          }
          fluid
          search
          selection
          multiple
        />
        <label>Skills*</label>
      </div>
      <div className="step2-field">
        {isRemote !== undefined && !isRemote && (
          <Input
            placeholder="Specify location"
            type="text"
            name="location"
            value={location}
            spellCheck={false}
            onChange={handleOnChange}
          />
        )}
        <div className="wrapper-radio">
          {TYPE_OF_LOCATION.map((type) => (
            <Radio
              name="isRemote"
              label={type.label}
              value={type.value}
              checked={type.value === isRemote}
              onChange={(e, { value }) =>
                handleOnChange({
                  target: { name: "isRemote", value },
                })
              }
            />
          ))}
        </div>
        <label htmlFor="category">Location (Remote or specific)</label>
      </div>
      <div className="step2-field">
        <Dropdown
          value={budget}
          options={BUDGET_RANGE}
          onChange={(e, data) =>
            handleOnChange({ target: { name: "budget", value: data.value } })
          }
          fluid
          selection
        />
        <label>Budget*</label>
      </div>
      <div className="step2-field radio">
        <div>
          {TYPE_OF_FREELANCERS.map((type) => (
            <Radio
              name="levelOfFreelancer"
              label={type.label}
              value={type.value}
              checked={type.value === levelOfFreelancer}
              onChange={(e, { value }) =>
                handleOnChange({ target: { name: "levelOfFreelancer", value } })
              }
            />
          ))}
        </div>
        <label>Level Of Freelancer*</label>
      </div>
      <div className="step2-buttons">
        <Button className="alternative" onClick={handlePreviousStep}>
          Previous
        </Button>
        <Button
          className="alternative"
          onClick={changeToNextStep}
          disabled={!isNextEnabled}
        >
          Next
        </Button>
      </div>
    </div>
  );
}

Step2.propTypes = {
  skills: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  location: PropTypes.string,
  budget: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }),
  levelOfFreelancer: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  handleNextStep: PropTypes.func.isRequired,
  handlePreviousStep: PropTypes.func.isRequired,
};

Step2.defaultProps = {
  skills: null,
  location: "",
  budget: null,
  levelOfFreelancer: "",
};

export default Step2;
