import React from "react";
import { io } from "socket.io-client";

export const socket = (userId) => {
  if (!userId) return {};
  return io(process.env.REACT_APP_API_BASE_URL, {
    transports: ["websocket"],
    query: {
      userId,
    },
  })
}

export const SocketContext = React.createContext();
