import React from "react";

import { DISPLAY_CLIENTS, DISPLAY_TESTIMONIALS } from "../../constants";
import "./Clients.scss";

function Clients() {
  return (
    <div className="clients">
      <div className="clients-wrapper">
        <h2 className="clients-title">Trusted by Businesses</h2>
        <p className="clients-subHeading">
          Hundreds of businesses rely on us for quality projects
        </p>
        <div className="clients-logo">
          {DISPLAY_CLIENTS.map((client) => (
            <div key={client.id} className="clients-logo-item">
              <img src={client.icon} alt={client.text} />
            </div>
          ))}
        </div>
        <div className="clients-testimonials">
          <div className="clients-testimonials-heading">
            <h3>Testimonials</h3>
            <p>
              Hear what our clients
              <br /> have to say:
            </p>
          </div>
          <div className="clients-testimonials-wrapper">
            {DISPLAY_TESTIMONIALS.map((testimonial) => (
              <div key={testimonial.id} className="clients-testimonials-item">
                <p className="clients-testimonials-item-review">
                  {testimonial.review}
                </p>
                <span className="clients-testimonials-item-name">
                  {testimonial.name}
                </span>
                <span className="clients-testimonials-item-position">
                  {testimonial.position}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Clients;
