import React from "react";
import { Redirect, Switch } from "react-router-dom";

import ROUTES from "constants/Routes";

import UserPrivateRoute from "components/UserPrivateRoute";
import ClientPrivateRoute from "components/ClientPrivateRoute";
import FreelancerPrivateRoute from "components/FreelancerPrivateRoute";

import JobPost from "containers/JobPost";
import JobList from "containers/JobSearch";
import JobDetail from "containers/JobDetail";
import JobProposalView from "containers/JobProposalView";
import JobProposal from "containers/JobProposal";
import JobContract from "containers/JobContract";

function JobRoutes() {
  return (
    <Switch>
      <ClientPrivateRoute path={ROUTES.JOB_POST_NEW} exact>
        <JobPost />
      </ClientPrivateRoute>
      <UserPrivateRoute path={ROUTES.JOB_POST_SEARCH} exact>
        <JobList />
      </UserPrivateRoute>
      <UserPrivateRoute path={ROUTES.JOB_POST_DETAIL} exact>
        <JobDetail />
      </UserPrivateRoute>
      <UserPrivateRoute path={ROUTES.JOB_PROPOSAL} exact>
        <JobProposalView />
      </UserPrivateRoute>
      <FreelancerPrivateRoute path={ROUTES.JOB_POST_APPLY} exact>
        <JobProposal />
      </FreelancerPrivateRoute>
      <UserPrivateRoute path={ROUTES.JOB_POST_HIRE} exact>
        <JobContract />
      </UserPrivateRoute>
      <Redirect to={ROUTES.JOB_POST_SEARCH} exact />
    </Switch>
  );
}

export default JobRoutes;
