import React from "react";
import { Link } from "react-router-dom";

import ROUTES from "constants/Routes";

function LoggedOut() {
  return (
    <>
      <Link className="header-links-withMargin" to={ROUTES.LOGIN}>
        Log in
      </Link>
      <Link to={ROUTES.SIGNUP}>
        <button className="primary">Join Now</button>
      </Link>
    </>
  );
}

export default LoggedOut;
