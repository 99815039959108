import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

import ROUTES from "constants/Routes";
import { stateToProps } from "./maps";

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated or your profile setup is not done.
function UserPrivateRoute({ children, ...rest }) {
  const { isLoggedIn, isProfileSetupDone } = useSelector(stateToProps);
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoggedIn && isProfileSetupDone ? (
          React.cloneElement(children, { location })
        ) : (
          <Redirect
            to={{
              pathname: ROUTES.PROFILE_SETUP,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export default UserPrivateRoute;
