import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Input, TextArea, Dropdown, Button, Form } from "semantic-ui-react";

import {
  FREELANCER_TYPE,
  CHOOSE_LOCATION_OPTION,
  COMMUNICATION_SKILLS,
} from "../../constants";
import { getAllSkills } from "../../actions/index";

import "./Step2.scss";

function Step2({
  title,
  description,
  skills,
  whatKindOfFreelancerYouAre,
  chooseLocationPreference,
  rateYourCommunicationSkills,
  onChange,
  handlePreviousStep,
  isSubmitting,
  handleNextStep,
  formErrors,
  resetError,
}) {
  const [skillsOption, setskillsOption] = useState();

  const handleOnChange = React.useCallback(
    (e, data) => {
      onChange(e.target.name, e.target.value);
    },
    [onChange]
  );

  useEffect(() => {
    getAllSkills()
      .then((response) => {
        const skills = [];
        response.data.data.map(({ skill, _id }) =>
          skills.push({ text: skill, value: _id })
        );

        setskillsOption(skills);
      })
      .catch((err) => console.log("ERROR OCCURED!", err));
  }, []);

  return (
    <div className="profileStep1-wrapper">
      <div className="profileStep1-fields">
        <Input
          name="title"
          placeholder="Enter your current title"
          value={title}
          spellCheck={false}
          onChange={handleOnChange}
          error={formErrors.title}
          onFocus={(e) => resetError(e.target.name)}
          fluid
        />
        <label htmlFor="name">Profile Title</label>
      </div>
      <div className="profileStep1-fields">
        <Form className="profileStep1-textarea">
          <TextArea
            name="description"
            placeholder="Tell us about yourself"
            className={formErrors.description ? "error": undefined}
            onFocus={(e) => resetError(e.target.name)}
            rows={4}
            value={description}
            spellCheck={false}
            onChange={handleOnChange}
          />
        </Form>
        <label htmlFor="phone">Summary</label>
      </div>
      <div className="profileStep1-fields">
        <Dropdown
          name="skills"
          options={skillsOption}
          value={skills}
          placeholder="Select all skills applicable"
          onChange={(e, data) => onChange("skills", data.value)}
          onFocus={(e) => resetError("skills")}
          error={formErrors.skills}
          fluid
          search
          selection
          multiple
        />
        <label>Skills</label>
      </div>
      <div className="profileStep1-fields">
        <Dropdown
          name="whatKindOfFreelancerYouAre"
          placeholder="Select freelancer type"
          onChange={(e, data) => onChange(data.name, data.value)}
          onFocus={(e) => resetError("whatKindOfFreelancerYouAre")}
          error={formErrors.whatKindOfFreelancerYouAre}
          options={FREELANCER_TYPE}
          value={whatKindOfFreelancerYouAre}
          fluid
          selection
        />
        <label htmlFor="name">What kind of freelancer you are</label>
      </div>
      <div className="profileStep1-fields">
        <Dropdown
          name="chooseLocationPreference"
          placeholder="Select one"
          onChange={(e, data) => onChange(data.name, data.value)}
          error={formErrors.chooseLocationPreference}
          onFocus={(e) => resetError("chooseLocationPreference")}
          fluid
          selection
          options={CHOOSE_LOCATION_OPTION}
          value={chooseLocationPreference}
        />
        <label htmlFor="name">Choose location preference</label>
      </div>
      <div className="profileStep1-fields">
        <Dropdown
          name="rateYourCommunicationSkills"
          placeholder="Select your rating"
          onChange={(e, data) => onChange(data.name, data.value)}
          error={formErrors.rateYourCommunicationSkills}
          onFocus={(e) => resetError('rateYourCommunicationSkills')}
          fluid
          selection
          value={rateYourCommunicationSkills}
          options={COMMUNICATION_SKILLS}
        />
        <label htmlFor="name"> Rate your communication skills</label>
      </div>
      <div className="step3-buttons">
        <Button className="alternative" onClick={handlePreviousStep}>
          Previous
        </Button>
        <Button
          className="primary"
          onClick={handleNextStep}
          loading={isSubmitting}
        >
          Next
        </Button>
      </div>
    </div>
  );
}

Step2.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  skills: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
  handlePreviousStep: PropTypes.func.isRequired,
  handleNextStep: PropTypes.func.isRequired,
};

Step2.defaultProps = {
  title: "",
  description: "",
  skills: [],
};

export default Step2;
