import React, { useCallback } from "react";
import PropTypes from "prop-types";

import Success from "static/icons/success.svg";

import "./StepSidebar.scss";

function StepSidebar({
  steps,
  currentStep,
  stepsCompleted,
  disabledSteps,
  onClick,
  enableAnimation,
}) {
  const renderCurrentStepSelection = useCallback(
    (stepNumber) => (
      <div className="stepSidebar-step-status-circle current">
        <span>{stepNumber + 1}</span>
      </div>
    ),
    []
  );

  const renderOtherStepSelection = useCallback(
    (stepNumber) => {
      // if (stepsCompleted.includes(stepNumber)) {
      //   return (
      //     <div className="stepSidebar-step-status-circle completed">
      //       <img src={Success} alt="Completed" />
      //     </div>
      //   );
      // }

      return (
        <div className="stepSidebar-step-status-circle">
          <span>{stepNumber + 1}</span>
        </div>
      );
    },
    [stepsCompleted]
  );

  const handleOnClick = React.useCallback(
    (stepNumber) => {
      if (disabledSteps.includes(stepNumber)) return;
      onClick(stepNumber);
    },
    [disabledSteps, onClick]
  );

  return (
    <div className="stepSidebar">
      {steps.map((step, i) => (
        <div
          key={step.id}
          className={`stepSidebar-step ${!stepsCompleted.includes(i) && 'hide-step'}`}
          onClick={() => handleOnClick(i)}
        >
          <div className="stepSidebar-step-contents">
            <p>{step.title}</p>
            <span>{step.details}</span>
          </div>
          <div className="stepSidebar-step-status">
            {i === currentStep
              ? renderCurrentStepSelection(i)
              : renderOtherStepSelection(i)}
          </div>
        </div>
      ))}
    </div>
  );
}

StepSidebar.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      details: PropTypes.string,
    })
  ),
  currentStep: PropTypes.number,
  stepsCompleted: PropTypes.arrayOf(PropTypes.number),
  disabledSteps: PropTypes.arrayOf(PropTypes.number),
  onClick: PropTypes.func.isRequired,
};

StepSidebar.defaultProps = {
  steps: [],
  currentStep: 0,
  stepsCompleted: [],
  disabledSteps: [],
};

export default StepSidebar;
