import React from "react";
import PropTypes from "prop-types";

import StepSidebar from "./components/StepSidebar";
import Success from "./components/Success";

import "./FormWrapper.scss";

function FormWrapper({
  children,
  heading,
  subHeading,
  renderHeader,
  customClassName = "",
}) {
  const getChildrenWithProps = React.useCallback((children) => {
    if (!Array.isArray(children)) return children;

    return children.map((child) => {
      if (
        child.type.name === StepSidebar.name ||
        child.type.name === Success.name
      ) {
        return React.cloneElement(child, {
          ...child.props,
        });
      }

      return child;
    });
  }, []);

  const modifiedChildren = getChildrenWithProps(children);
  return (
    <div className="formWrapper">
      <div className="formWrapper-wrapper">
        {!renderHeader ? (
          <>
            {heading && (
              <h2 className={`formWrapper-heading ${customClassName}`}>
                {heading}
              </h2>
            )}
            {subHeading && (
              <p className={`formWrapper-subHeading  ${customClassName}`}>{subHeading}</p>
            )}
          </>
        ) : (
          renderHeader
        )}

        {modifiedChildren}
      </div>
    </div>
  );
}

FormWrapper.propTypes = {
  children: PropTypes.node,
  heading: PropTypes.string,
  subHeading: PropTypes.string,
  renderHeader: PropTypes.node,
};

FormWrapper.defaultProps = {
  children: null,
  heading: "",
  subHeading: "",
  renderHeader: null,
};

FormWrapper.StepSidebar = StepSidebar;
FormWrapper.Success = Success;

export default FormWrapper;
