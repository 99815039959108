import { calculateExperience } from "utils";

export const checkStepOne = ({ name, phone, profileType }) => {
  const errors = {};

  if (!name) errors["name"] = true;
  if (!phone || phone.length !== 10) errors["phone"] = true;
  if (!profileType) errors["profileType"] = true;

  return { hasError: !!Object.keys(errors).length, errors };
};

export const checkStepTwo = ({
  title,
  description,
  skills,
  whatKindOfFreelancerYouAre,
  chooseLocationPreference,
  rateYourCommunicationSkills,
}) => {
  const errors = {};

  if (!title) errors["title"] = true;
  if (!description) errors["description"] = true;
  if (!whatKindOfFreelancerYouAre) errors["whatKindOfFreelancerYouAre"] = true;
  if (!chooseLocationPreference) errors["chooseLocationPreference"] = true;
  if (!rateYourCommunicationSkills)
    errors["rateYourCommunicationSkills"] = true;
  if (!skills.length) errors["skills"] = true;

  return { hasError: !!Object.keys(errors).length, errors };
};

export const checkStepThree = ({ workDetails }) => {
  const errors = {};

  workDetails.map((w) => {
    if (
      w.startMonth &&
      w.startYear &&
      (w.isCurrent || (w.endMonth && w.endYear))
    ) {
      const { diff } = calculateExperience(w);
      console.log('diff', diff)
      if (diff <= 0) {
        errors["workDetails"] = true;
      }
    }
    return w;
  });

  return { hasError: !!Object.keys(errors).length, errors };
};
