import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Tab } from "semantic-ui-react";

import ContactInfo from "./components/ContactInfo";
import CompanyInfo from "./components/CompanyInfo";
import MyProfile from "./components/MyProfile";
import ProfileSettings from "./components/ProfileSettings";
import PasswordSettings from "./components/PasswordSettings";

import { getProfileData, saveProfileData } from "./actions";

import { stateToProps, dispatchToProps } from "./maps";

import "./Settings.scss";

const getPanes = (
  isClient,
  props,
  setUser,
  setProfileData,
  isFetching,
  provider
) => {
  let panes = [
    {
      menuItem: "Contact Info",
      render: () => (
        <Tab.Pane
          className="settings-tabs-pane"
          loading={isFetching}
          attached={false}
        >
          <ContactInfo
            {...props}
            setUser={setUser}
            setProfileData={setProfileData}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Freelance Profile",
      render: () => (
        <Tab.Pane
          className="settings-tabs-pane"
          loading={isFetching}
          attached={false}
        >
          <MyProfile {...props} setProfileData={setProfileData} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Additional Details",
      render: () => (
        <Tab.Pane className="settings-tabs-pane" attached={false}>
          <ProfileSettings
            userProfile={props}
            setProfileData={setProfileData}
          />
        </Tab.Pane>
      ),
    },
  ];

  if (isClient) {
    panes = [
      {
        menuItem: "Contact Info",
        render: () => (
          <Tab.Pane
            loading={isFetching}
            className="settings-tabs-pane"
            attached={false}
          >
            <ContactInfo
              {...props}
              setUser={setUser}
              setProfileData={setProfileData}
            />
          </Tab.Pane>
        ),
      },
      {
        menuItem: "Company Information",
        render: () => (
          <Tab.Pane
            loading={isFetching}
            className="settings-tabs-pane"
            attached={false}
          >
            <CompanyInfo
              {...props}
              setUser={setUser}
              setProfileData={setProfileData}
            />
          </Tab.Pane>
        ),
      },
    ];
  }

  if (provider === null) {
    panes = [
      ...panes,
      {
        menuItem: "Password & Security",
        render: () => (
          <Tab.Pane className="settings-tabs-pane" attached={false}>
            <PasswordSettings />
          </Tab.Pane>
        ),
      },
    ];
  }

  return panes;
};
function Settings() {
  const { isClient, userId, userProfile, phone, email, name, provider } =
    useSelector(stateToProps);
  const { setProfileData, setUser } = dispatchToProps(useDispatch());
  const [isFetching, setIsFetching] = React.useState(false);

  const handleOnUpdate = React.useCallback(
    (data, action, cb) => {
      saveProfileData(userId, { ...data, action })
        .then(() => {
          cb(true, "Saved successfully!");
        })
        .catch((error) => {
          console.error(error);
          try {
            cb(false, error.response.data.error);
          } catch (e) {
            cb(false, "Failed to save! Try again");
          }
        });
    },
    [userId]
  );

  const allPanes = React.useCallback(
    () =>
      getPanes(
        isClient,
        {
          ...userProfile,
          name,
          phone,
          email,
          handleOnUpdate,
        },
        setUser,
        setProfileData,
        isFetching,
        provider
      ),
    [
      isClient,
      email,
      phone,
      name,
      userProfile,
      handleOnUpdate,
      setUser,
      setProfileData,
      isFetching,
      provider,
    ]
  );

  React.useEffect(() => {
    if (!userId) return;

    setIsFetching(true);
    getProfileData(userId).then((response) => {
      setIsFetching(false);
      setProfileData(response.data.profile);
      setUser({ ...response.data.meta });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="settings">
      <div className="settings-wrapper">
        <h2 className="settings-heading">Settings</h2>
        <p className="settings-subHeading">
          Choose between different tabs to edit information
        </p>
        <Tab
          className="settings-tabs"
          menu={{
            secondary: true,
            vertical: true,
            fluid: true,
          }}
          panes={allPanes()}
        />
      </div>
    </div>
  );
}

export default Settings;
