import React from "react";
import PropTypes from "prop-types";
import { Button, Label } from "semantic-ui-react";
import {
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  TwitterIcon,
  LinkedinIcon,
  WhatsappIcon,
} from "react-share";

import ROUTES from "constants/Routes";
import { getJobStatusColor, getJobStatus } from "utils/job";

import "./JobDetailSidebar.scss";

function JobDetailSidebar({ job, isClient, isOwner, onSubmit }) {
  const JOB_POST_URL = `${
    window.origin
  }${ROUTES.JOB_POST_HIRE.replace(":id", job._id)}`
  return (
    <div className="jobDetail-actionables">
      {isClient ? (
        <Button
          className="primary"
          onClick={onSubmit}
          disabled={!isOwner || !job.active}
        >
          {"Deactivate Job"}
        </Button>
      ) : (
        <Button className="primary" onClick={onSubmit} disabled={!job.active}>
          {"Submit a Proposal"}
        </Button>
      )}
      <div className="jobDetail-actionables-figures">
        {isClient && (
          <>
            <div className="jobDetail-actionables-figures-item">
              <Label color={getJobStatusColor(job.status, job.active)}>
                {getJobStatus(job.status, job.isApproved, job.active)}
              </Label>
              <span>Status</span>
            </div>
            <div className="jobDetail-actionables-figures-divider"></div>
          </>
        )}
        <div className="jobDetail-actionables-figures-item">
          <p>{job.budget}</p>
          <span>Budget</span>
        </div>
        <div className="jobDetail-actionables-figures-divider"></div>
        <div className="jobDetail-actionables-figures-item">
          <p>{job.duration}</p>
          <span>Est. Time</span>
        </div>
        <div className="jobDetail-actionables-figures-divider"></div>
        <div className="jobDetail-actionables-figures-item">
          <p>{job.isRemote ? "Remote" : job.location}</p>
          <span>Location</span>
        </div>
        <div className="jobDetail-actionables-figures-divider"></div>
        <div className="jobDetail-actionables-figures-item">
          <p>{job.category.title}</p>
          <span>Category</span>
        </div>
        <div className="jobDetail-actionables-figures-divider"></div>
        <div className="jobDetail-actionables-figures-item">
          <div className="jobDetail-sidebar-social">
            <TwitterShareButton url={`I'm Hiring! ${job.title} - ${JOB_POST_URL}`}>
              <TwitterIcon round size={24} />
            </TwitterShareButton>
            <LinkedinShareButton url={JOB_POST_URL}>
              <LinkedinIcon round size={24} />
            </LinkedinShareButton>
            <WhatsappShareButton title={`I'm Hiring! ${job.title}`} url={JOB_POST_URL}>
              <WhatsappIcon round size={24} />
            </WhatsappShareButton>
          </div>
          <span>Share job</span>
        </div>
      </div>
    </div>
  );
}

JobDetailSidebar.propTypes = {
  job: PropTypes.shape({}),
  isClient: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

JobDetailSidebar.defaultProps = {
  job: {},
};

export default JobDetailSidebar;
