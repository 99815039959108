import React from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Label, Loader, Tab } from "semantic-ui-react";

import ROUTES from "constants/Routes";
import SimpleCard from "components/SimpleCard";
import { firstLetterCapitalise } from "utils";
import { getContractStatusColor } from "utils/job";

import { getContracts } from "../../actions";

import "./MyContract.scss";

function MyContracts({ userId }) {
  const [contracts, setContracts] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    if (!userId) return;

    setIsLoading(true);

    getContracts({ userId })
      .then((response) => {
        setContracts(response.data.contracts);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        try {
          toast.error(
            `Fetching job posts failed! ${error.response.data.error}`
          );
        } catch (e) {
          toast.error(`Fetching job posts failed!`);
        }
      });
  }, [userId]);

  const haveContracts = Array.isArray(contracts) && contracts.length;

  const panes = [
    {
      menuItem: "Ongoing Jobs",
      render: () => (
        <Tab.Pane attached={false}>
          {haveContracts &&
            contracts
              .filter((contract) => contract.status === "accepted")
              .map((contract) => {
                return (
                  <Link
                    to={`${ROUTES.JOB_POST_HIRE.replace(":id", contract._id)}`}
                  >
                    <div className="myJobs-job">
                      <p className="submittedProposal-bid">
                        Milestones <br /> {contract.milestones.length}
                      </p>
                      <h4 className="submittedProposal-title">
                        {contract.jobId.title}
                      </h4>
                      <p className="submittedProposal-status">
                        <Label color={getContractStatusColor(contract.status)}>
                          {firstLetterCapitalise(contract.status)}
                        </Label>
                      </p>
                    </div>
                  </Link>
                );
              })}
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Pending Contracts ",
      render: () => (
        <Tab.Pane attached={false}>
          {haveContracts &&
            contracts
              .filter((contract) => contract.status === "open")
              .map((contract) => {
                const createdDate = new Date(contract.createdAt);
                return (
                  <Link
                    to={`${ROUTES.JOB_POST_HIRE.replace(":id", contract._id)}`}
                  >
                    <div className="myJobs-job">
                      <p className="submittedProposal-date">
                        Created on
                        <br />
                        {`${createdDate.toDateString()} ${createdDate.getHours()}:${createdDate.getMinutes()}:${createdDate.getSeconds()}`}
                      </p>
                      <p className="submittedProposal-bid">
                        Bid <br /> {contract.bid}
                      </p>
                      <h4 className="submittedProposal-title">
                        {contract.jobId.title}
                      </h4>
                      <p className="submittedProposal-status">
                        <Label color={getContractStatusColor(contract.status)}>
                          {firstLetterCapitalise(contract.status)}
                        </Label>
                      </p>
                    </div>
                  </Link>
                );
              })}
        </Tab.Pane>
      ),
    },
    // {
    //   menuItem: "Rejected Bids ",
    //   render: () => (
    //     <Tab.Pane attached={false}>
    //       {haveContracts &&
    //         contracts
    //           .filter((contract) => contract.status === "rejected")
    //           .map((contract) => {
    //             const createdDate = new Date(contract.createdAt);
    //             return (
    //               <Link
    //                 to={`${ROUTES.JOB_POST_HIRE.replace(":id", contract._id)}`}
    //               >
    //                 <div className="myJobs-job">
    //                   <p className="submittedProposal-date">
    //                     Created on
    //                     <br />
    //                     {`${createdDate.toDateString()} ${createdDate.getHours()}:${createdDate.getMinutes()}:${createdDate.getSeconds()}`}
    //                   </p>
    //                   <p className="submittedProposal-bid">
    //                     Bid <br /> {contract.bid}
    //                   </p>
    //                   <h4 className="submittedProposal-title">
    //                     {contract.jobId.title}
    //                   </h4>
    //                   <p className="submittedProposal-status">
    //                     <Label color={getContractStatusColor(contract.status)}>
    //                       {firstLetterCapitalise(contract.status)}
    //                     </Label>
    //                   </p>
    //                 </div>
    //               </Link>
    //             );
    //           })}
    //     </Tab.Pane>
    //   ),
    // },
  ];

  return (
    <SimpleCard heading="Your Jobs">
      <ToastContainer
        className="toast"
        position="bottom-center"
        autoClose={2500}
        hideProgressBar
      />
      <Loader active={isLoading} />
      <SimpleCard.Body>
        <div className="findWork-jobs">
          {haveContracts ? (
            <Tab
              className="myContract-panes settings-tabs-pane"
              menu={{ secondary: true }}
              panes={panes}
            />
          ) : (
            <div className="emptyState">
              Looks like you're yet to finalise a contract!
            </div>
          )}
        </div>
      </SimpleCard.Body>
    </SimpleCard>
  );
}

export default MyContracts;
