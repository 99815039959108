export const PROPOSAL_STATUS = {
  OPEN: "open",
  ACCEPTED: "accepted",
  REJECTED: "rejected",
  IN_PROGRESS: "in progress",
};

export const CONTRACT_STATUS = {
  OPEN: "open",
  ACCEPTED: "accepted",
  REJECTED: "rejected",
};

// 15% of the bid value should be the minimum for milestone payment
export const MINIMUM_PAYMENT_VALUE = 0.15;
