import React from "react";

import "./Loader.scss";

function Loader() {
  return (
    <div className="loadingio-spinner-rolling-y3g38pdn2bq">
      <div className="ldio-cucnsxpct36">
        <div></div>
      </div>
    </div>
  );
}

export default Loader;
