import React from "react";
import { Button } from "semantic-ui-react";
import { useFormik } from "formik";
import Dropzone from "react-dropzone-uploader";

import "react-dropzone-uploader/dist/styles.css";

import "./ContactUs.scss";

function ContactUs() {
  const formik = useFormik({
    initialValues: {
      name: "",
      contact: "",
      email: "",
      subject: "",
      message: "",
    },
    onSubmit: (values) => {
      // alert(JSON.stringify(values, null, 2));
    },
    validate: (values) => {
      const error = {};
      if (!values.name) {
        error.name = "Please enter name";
      }
      if (!values.email) {
        error.email = "Please enter email";
      }
      if (!values.contact) {
        error.contact = "Please enter contact";
      }
      if (!values.subject) {
        error.subject = "Please enter subject";
      }
      if (!values.message) {
        error.message = "Please enter message";
      }
      return error;
    },
  });
  const handleChangeStatus = ({ meta }, status) => {
    console.log(status, meta);
  };

  const handleSubmit = (files, allFiles) => {
    console.log(files.map((f) => f.meta));
    allFiles.forEach((f) => f.remove());
  };
  return (
    <div className="container">
      <div className="form">
        <h2 className="" style={{ textAlign: "center" }}>
          Contact US
        </h2>
        <form onSubmit={formik.handleSubmit}>
          <div className="login-container">
            <input
              name="name"
              type="text"
              placeholder="Name"
              spellCheck={false}
              value={formik.values.name}
              onChange={(e) => formik.setFieldValue("name", e.target.value)}
            />
            {formik.errors.name && formik.touched.name && (
              <span className="login-error">{formik.errors.name}</span>
            )}
          </div>
          <div className="login-container">
            <input
              name="email"
              type="email"
              placeholder="Email"
              spellCheck={false}
              value={formik.values.email}
              onChange={(e) => formik.setFieldValue("email", e.target.value)}
            />
            {formik.errors.email && formik.touched.email && (
              <span className="login-error">{formik.errors.email}</span>
            )}
            {/* {emailError && <span className="login-error">{INVALID_EMAIL_ID}</span>} */}
          </div>
          <div className="login-container">
            <input
              name="contact"
              type="number"
              placeholder="Contact"
              spellCheck={false}
              value={formik.values.contact}
              onChange={(e) => formik.setFieldValue("contact", e.target.value)}
            />
            {formik.errors.contact && formik.touched.contact && (
              <span className="login-error">{formik.errors.contact}</span>
            )}
            {/* {emailError && <span className="login-error">{INVALID_EMAIL_ID}</span>} */}
          </div>
          <div className="login-container">
            <input
              name="subject"
              type="text"
              placeholder="Subject"
              spellCheck={false}
              value={formik.values.subject}
              onChange={(e) => formik.setFieldValue("subject", e.target.value)}
            />
            {formik.errors.subject && formik.touched.subject && (
              <span className="login-error">{formik.errors.subject}</span>
            )}
            {/* {emailError && <span className="login-error">{INVALID_EMAIL_ID}</span>} */}
          </div>
          <div className="login-container">
            <textarea
              name="Message"
              placeholder="Enter your message"
              rows="3"
              value={formik.values.message}
              onChange={(e) => formik.setFieldValue("message", e.target.value)}
            />
            {formik.errors.message && formik.touched.message && (
              <span className="login-error">{formik.errors.message}</span>
            )}
            {/* {emailError && <span className="login-error">{INVALID_EMAIL_ID}</span>} */}
          </div>
          <div className="login-container">
            <Dropzone
              // getUploadParams={getUploadParams}
              accept=".doc, .docx, .pdf"
              onChangeStatus={handleChangeStatus}
              // onSubmit={handleSubmit}
              styles={{ dropzone: { minHeight: 200, maxHeight: 250 } }}
            />
          </div>
          <Button className="primary" fluid>
            Contact Us
          </Button>
        </form>
      </div>
    </div>
  );
}

export default ContactUs;
