import axios from "axios";

import { LOGIN_URL, LOGIN_GOOGLE_URL, LOGIN_FACEBOOK_URL } from "api/auth";

export const userLogin = ({ email, password }) => {
  return axios.post(LOGIN_URL, { email, password });
};

export const userGoogleLoginAPI = ({ id, accessToken, name, email }) => {
  return axios.post(LOGIN_GOOGLE_URL, { id, accessToken, name, email });
};
export const userFacebookLoginAPI = ({ id, accessToken, name, email }) => {
  return axios.post(LOGIN_FACEBOOK_URL, { id, accessToken, name, email });
};
