export const PROFILE_SETUP_STEPS_FREELANCER = [
  {
    id: "profile-step-1",
    title: "Basic Information",
    details: "Getting to know you",
  },
  {
    id: "profile-step-2",
    title: "Knowledge base",
    details: "Skills, Location requirements",
  },
  {
    id: "profile-step-3",
    title: "Work",
    details: "Work details",
  },
  {
    id: "profile-step-4",
    title: "Portfolio",
    details: "Work related details",
  },
];
export const PROFILE_SETUP_STEP_CLIENT = [
  {
    id: "profile-step-1",
    title: "Basic Information",
    details: "Getting to know you",
  },
  {
    id: "profile-step-2",
    title: "Company Information",
    details: "Information about your company",
  },
];

export const PROFILE_TYPE_CHOICE = [
  { id: "profile-type-1", label: "Work as a freelancer", value: "freelancer" },
  { id: "profile-type-2", label: "Hire for a project", value: "client" },
];

export const FREELANCER_TYPE = [
  {
    key: "Full time freelancer",
    text: "Full time freelancer",
    value: "Full time freelancer",
  },
  {
    key: "Part time freelancer, I'm working somewhere else as well",
    text: "Part time freelancer, I'm working somewhere else as well",
    value: "Part time freelancer, I'm working somewhere else as well",
  },
  {
    key: "I freelance on weekends only",
    text: "I freelance on weekends only",
    value: "I freelance on weekends only",
  },
  {
    key: "We are a IT Company",
    text: "We are an IT Company",
    value: "We are a IT Company",
  },
];

export const CHOOSE_LOCATION_OPTION = [
  {
    key: "I can work onsite if required, anywhere in my city",
    text: "I can work onsite if required, anywhere in my city",
    value: "I can work onsite if required, anywhere in my city",
  },
  {
    key: "I can travel anywhere & work onsite anywhere",
    text: "I can travel anywhere & work onsite anywhere",
    value: "I can travel anywhere & work onsite anywhere",
  },
  {
    key: "I work remotely only",
    text: "I work remotely only",
    value: "I work remotely only",
  },
  {
    key: "We are a agency and we can deploy our developers wherever required",
    text: "We are a agency and we can deploy our developers wherever required",
    value: "We are a agency and we can deploy our developers wherever required",
  },
];

export const COMMUNICATION_SKILLS = [
  {
    key: "Fluent in spoken & written English",
    text: "Fluent in spoken & written English",
    value: "Fluent in spoken & written English",
  },
  {
    key: "Fluent in written english but average in spoken english",
    text: "Fluent in written english but average in spoken english",
    value: "Fluent in written english but average in spoken english",
  },
  {
    key: "Average in both written & spoken english",
    text: "Average in both written & spoken english",
    value: "Average in both written & spoken english",
  },
  {
    key: "Comfortable in Hindi only",
    text: "Comfortable in Hindi only",
    value: "Comfortable in Hindi only",
  },
  {
    key: "Comfortable in my native language only",
    text: "Comfortable in my native language only",
    value: "Comfortable in my native language only",
  },
];

export const EMPLOYEE_STRENGTH = [
  { text: "1-10", value: "1-10" },
  { text: "10-25", value: "10-25" },
  { text: "25-300", value: "25-300" },
  { text: "300-1000", value: "300-1000" },
  { text: "1000+", value: "1000+" },
];
