import { bindActionCreators } from "redux";

import { setUser } from "actions/auth";
import { getIsLoggedIn, getIsProfileSetupDone } from "selectors/userSelector";

export const stateToProps = (state) => {
  return {
    isLoggedIn: getIsLoggedIn(state),
    isProfileSetupDone: getIsProfileSetupDone(state),
  };
};

export const dispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setUser,
    },
    dispatch
  );
};
