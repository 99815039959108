import React from "react";
import PropTypes from "prop-types";

import "./SimpleCardBody.scss";

function SimpleCardBody({ children }) {
  return <div className="simpleCardBody">{children}</div>;
}

SimpleCardBody.propTypes = {
  children: PropTypes.node,
};

SimpleCardBody.defaultProps = {
  children: null,
};

export default SimpleCardBody;
