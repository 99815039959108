import React from "react";
import { Link } from "react-router-dom";
import { Button } from "semantic-ui-react";

import ROUTES from "constants/Routes";

import SimpleCard from "components/SimpleCard";
import JobView from "containers/JobSearch/components/JobView";

import "./FindWork.scss";

function FindWork({ jobs, getJobPosts }) {
  const handleOnClick = React.useCallback((job) => {
    window.open(`${ROUTES.JOB_POST_DETAIL.replace(":id", job._id)}`, "_blank");
  }, []);
  return (
    <SimpleCard heading="Find job post that suits you!">
      <SimpleCard.Body>
        <div className="findWork-jobs">
          {jobs.length ? (
            jobs.map((job) => <JobView job={job} onClick={handleOnClick} />)
          ) : (
            <div className="findWork-jobs-none">
              <p className="findWork-jobs-none-content">Looks like our system was unable to find a job right now.</p>
              <Button className="primary" onClick={getJobPosts}>Refresh jobs</Button>
            </div>
          )}
          {jobs.length > 10 && (
            <Link to={ROUTES.JOB_POST_SEARCH}>
              <div className="findWork-jobs-more">Show more job posts</div>
            </Link>
          )}
        </div>
      </SimpleCard.Body>
    </SimpleCard>
  );
}

export default FindWork;
