import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Button } from "semantic-ui-react";

import SuccessImage from "static/icons/success.svg";

import "./Success.scss";

function Success({
  successMessage,
  primaryButtonMessage,
  secondaryButtonMessage,
  primaryLink,
  secondaryLink,
}) {
  return (
    <div className="formWrapperSuccess-wrapper">
      <img
        className="green-tick"
        src={SuccessImage}
        alt="success"
        height="300"
        width="300"
        style={{ margin: "auto" }}
      />
      <div className="formWrapperSuccess-success">{successMessage}</div>
      <div className="formWrapperSuccess-ctas">
        <Link to={primaryLink}>
          <Button className="primary">{primaryButtonMessage}</Button>
        </Link>
        <Link to={secondaryLink}>
          <Button className="alternative formWrapperSuccess-ctas-last">
            {secondaryButtonMessage}
          </Button>
        </Link>
      </div>
    </div>
  );
}

Success.propTypes = {
  successMessage: PropTypes.string,
  primaryButtonMessage: PropTypes.string,
  secondaryButtonMessage: PropTypes.string,
  primaryLink: PropTypes.string,
  secondaryLink: PropTypes.string,
};

Success.defaultProps = {
  successMessage: "",
  primaryButtonMessage: "",
  secondaryButtonMessage: "",
  primaryLink: "",
  secondaryLink: "",
};

export default Success;
