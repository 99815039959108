import React from "react";
import PropTypes from "prop-types";

function JobView({ onClick, job }) {
  return (
    <div className="jobList-list-item" onClick={() => onClick(job)}>
      <div className="jobList-list-content">
        <h4 className="jobList-list-content-title">{job.title}</h4>
        <p className="jobList-list-content-desc">{`${job.description.substring(
          0,
          200
        )}...`}</p>
        <div className="jobList-list-content-skills">
          {job.skills.map(({skill}) => (
            <div>{skill}</div>
          ))}
        </div>
      </div>
      <div className="jobList-list-details">
        <p>
          Budget - <b>{job.budget}</b>
        </p>
        <p>
          Est. Time - <b>{job.duration}</b>
        </p>
      </div>
    </div>
  );
}

JobView.propTypes = {
  onClick: PropTypes.func.isRequired,
  job: PropTypes.shape({}).isRequired,
};

export default JobView;
