import ROUTES from "constants/Routes";

//social media icon
import Facebook from "static/icons/socialMedia/facebook.svg";
import Twitter from "static/icons/socialMedia/twitter.svg";
import LinkedIn from "static/icons/socialMedia/linkedin.svg";
import Instagram from "static/icons/socialMedia/instagram.svg";

export const FOOTER_LINKS = {
  company: [
    {
      id: "footer-company-3",
      title: "Terms & Conditions",
      url: ROUTES.TERMS_AND_CONDITIONS,
    },
    {
      id: "footer-company-6",
      title: "Refund Policy",
      url: ROUTES.REFUND_POLICY,
    },
    {
      id: "footer-company-4",
      title: "Privacy Statement",
      url: ROUTES.PRIVACY_POLICY,
    },
    {
      id: "footer-company-5",
      title: "About us",
      url: ROUTES.ABOUT_US,
    },
  ],
  exploreMore: [
    { id: "footer-explore-1", title: "Blog", url: ROUTES.BLOG },
    {
      id: "footer-explore-2",
      title: "How it Works",
      url: ROUTES.HOW_IT_WORKS,
    },
    {
      id: "footer-explore-3",
      title: "Categories",
      url: ROUTES.CATEGORIES,
    },
    {
      id: "footer-explore-4",
      title: "Contact Us",
      url: ROUTES.CONTACT_US,
    },
  ],
};

export const FOOTER_SOCIAL_LINKS = [
  {
    id: "footer-social-1",
    url: ROUTES.SOCIAL_FACEBOOK_LINK,
    text: "Facebook",
    icon: Facebook,
  },
  {
    id: "footer-social-2",
    url: ROUTES.SOCIAL_TWITTER_LINK,
    text: "Twitter",
    icon: Twitter,
  },
  {
    id: "footer-social-3",
    url: ROUTES.SOCIAL_LINKEDIN_LINK,
    text: "Linkedin",
    icon: LinkedIn,
  },
  {
    id: "footer-social-4",
    url: ROUTES.SOCIAL_INSTAGRAM_LINK,
    text: "Instagram",
    icon: Instagram,
  },
];
