import React from "react";
import FormWrapper from "components/FormWrapper";

import AboutUsImage from "static/icons/about_us.svg";

import "./AboutUs.scss";

function AboutUs() {
  return (
    <FormWrapper
      heading="About Us"
      subHeading="Our journey to becoming a freelancer marketplace"
    >
      <div className="aboutUs">
        <div className="aboutUs-text">
          ExpertRight don’t do this and that- we do only one thing & we do it
          with passion…& fun!
          <br />
          <br />
          We are a managed freelance marketplace. Started from a college’s dorm
          room, in 2016 ExpertRight realised that freelancing space requires a
          lot of structuring and third party layers to ensure smooth flow of
          projects and deliveries along with a structured payment methodology
          and thus we came up with a model called managed freelance marketplace
          where we not just help companies & startups to onboard freelance
          developers, designers, content writers etc we also help them manage
          their projects & freelancers.
          <br />
          <br />
          All of us at ExpertRight are passionate about doing something which
          will definitely put a dent in freelancing, watch us take this
          rocketship to the moon! 🚀
        </div>
        <img  className="aboutUs-image" src={AboutUsImage} alt="ExpertRight team" />
      </div>
    </FormWrapper>
  );
}

export default AboutUs;
