import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { Button } from "semantic-ui-react";
import { ToastContainer, toast } from "react-toastify";

import PasswordInput from "components/PasswordInput";
import ROUTES from "constants/Routes";
import ConfirmedImage from "static/icons/account-confirmed.svg";
import { unauthorizedPost } from "api";
import { SET_RESET_PASSWORD } from "api/auth";
import Auth from "components/Auth";

function ResetPassword() {
  const history = useHistory();
  const [password, setPassword] = React.useState("");
  const [verifyPassword, setVerifyPassword] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [submitted, setSubmitted] = React.useState(false);

  const { token } = useParams();

  const handleSubmit = React.useCallback(() => {
    if (!token) {
      toast.error("Invalid link. Reset password again.");
      return;
    }

    if (password.length < 6) {
      toast.error("Password should atleast be 6 characters long");
      return;
    }

    if (password !== verifyPassword) {
      toast.error("Password's do not match with each other. Try again.");
      return;
    }

    setLoading(true);

    unauthorizedPost(SET_RESET_PASSWORD, {
      token,
      password,
      verifyPassword,
    })
      .then(() => {
        setLoading(false);
        setSubmitted(true);
      })
      .catch((error) => {
        setLoading(false);
        toast.error(
          error?.error || "Error occurred while resetting the password"
        );
      });
  }, [token, password, verifyPassword]);

  return (
    <Auth>
      <ToastContainer
        className="toast"
        position="bottom-center"
        autoClose={2500}
        hideProgressBar
      />
      <h3>Reset your password</h3>
      {!submitted ? (
        <div className="forgotPassword-container">
          <p className="forgotPassword-content">Fill in the new password</p>
          <PasswordInput
            className="signupForm-container-email"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <PasswordInput
            name="verifyPassword"
            placeholder="Verify Password"
            value={verifyPassword}
            onChange={(e) => setVerifyPassword(e.target.value)}
          />
          <Button
            className="primary forgotPassword-button"
            onClick={handleSubmit}
            loading={loading}
            fluid
          >
            Set Password
          </Button>
        </div>
      ) : (
        <>
          <img
            className="confirmed-image"
            style={{ margin: "0 auto" }}
            src={ConfirmedImage}
            alt=""
          />
          <div
            className="forgotPassword-content"
            style={{ textAlign: "center" }}
          >
            <b>Password changed successfully!</b>
            <Button
              className="primary"
              style={{ marginTop: "12px" }}
              onClick={() => history.push(ROUTES.LOGIN)}
            >
              Proceed to Login
            </Button>
          </div>
          <div></div>
        </>
      )}
    </Auth>
  );
}

export default ResetPassword;
