import React from "react";
import { useHistory } from "react-router-dom";

import "./Consultation.scss";

function Consultation() {
  const history = useHistory();
  return (
    <div className="consultation">
      <div className="consultation-wrapper">
        <div className="consultation-content">
          <h2 className="consultation-content-title">Need help?</h2>
          <p className="consultation-content-subHeading">
            Book a 10 minutes quick consultation with one of our expert
          </p>
          <span className="consultation-content-tips">
            (No Follow-ups, No Strings Attached)
          </span>
        </div>
        <div className="consultation-cta">
          <button className="primary" onClick={() => history.push("/contact-us")}>Contact Us</button>
          <button className="alternative">Check FAQ</button>
        </div>
      </div>
    </div>
  );
}

export default Consultation;
