import React from "react";
import PropTypes from "prop-types";

import Edit from "static/icons/edit.svg";

import "./SectionHeader.scss";

function SectionHeader({ heading, isEditOn, handleOnEdit }) {
  return (
    <div className="sectionHeader">
      <h4>{heading}</h4>
      {!isEditOn ? (
        <div className="sectionHeader-edit" onClick={handleOnEdit}>
          <img className="sectionHeader-edit-icon" src={Edit} alt="" />
          <p className="sectionHeader-edit-text">Edit</p>
        </div>
      ) : null}
    </div>
  );
}

SectionHeader.propTypes = {
  heading: PropTypes.string,
  isEditOn: PropTypes.bool,
  handleOnEdit: PropTypes.func,
};

SectionHeader.defaultProps = {
  heading: "",
  isEditOn: false,
  handleOnEdit: () => {},
};

export default SectionHeader;
