import React from "react";
import PropTypes from "prop-types";
import { Button } from "semantic-ui-react";

import "./Editable.scss";

function Editable({
  children,
  isUpdating,
  onCancel,
  onUpdate,
  handleOnCancel,
  alwaysEditable,
}) {
  const onCancelClick = React.useCallback(() => {
    onCancel();
    handleOnCancel();
  }, [handleOnCancel, onCancel]);

  const handleOnUpdate = React.useCallback(() => {
    onUpdate(handleOnCancel);
  }, [onUpdate, handleOnCancel]);
  return (
    <div className="editable-body">
      {children}
      {!alwaysEditable && (
        <div className="editable-actionable">
          <Button
            className="primary editable-actionable-primary"
            //onClick={handleOnUpdate}
            onClick={handleOnUpdate}
            loading={isUpdating}
          >
            Update
          </Button>
          <Button className="alternative" onClick={onCancelClick}>
            Cancel
          </Button>
        </div>
      )}
    </div>
  );
}

Editable.propTypes = {
  children: PropTypes.element,
  isUpdating: PropTypes.bool,
  onCancel: PropTypes.func,
  onUpdate: PropTypes.func,
  handleOnCancel: PropTypes.func,
  alwaysEditable: PropTypes.bool,
};

Editable.defaultProps = {
  isUpdating: false,
  onCancel: () => {},
  onUpdate: () => {},
  handleOnCancel: () => {},
  alwaysEditable: false,
};

export default Editable;
