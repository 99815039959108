export const DUMMY_COUNTRY_LIST = [
  { key: "india", value: "india", text: "India" },
  { key: "canada", value: "canada", text: "Canada" },
  { key: "us", value: "us", text: "U.S.A." },
  { key: "uk", value: "uk", text: "U.K." },
];

export const YEAR_OPTIONS = [
  { text: "2000", value: "2000" },
  { text: "2001", value: "2001" },
  { text: "2002", value: "2002" },
  { text: "2003", value: "2003" },
  { text: "2004", value: "2004" },
  { text: "2005", value: "2005" },
  { text: "2006", value: "2006" },
  { text: "2007", value: "2007" },
  { text: "2008", value: "2008" },
  { text: "2009", value: "2009" },
  { text: "2010", value: "2010" },
  { text: "2011", value: "2011" },
  { text: "2012", value: "2012" },
  { text: "2013", value: "2013" },
  { text: "2014", value: "2014" },
  { text: "2015", value: "2015" },
  { text: "2016", value: "2016" },
  { text: "2017", value: "2017" },
  { text: "2018", value: "2018" },
  { text: "2019", value: "2019" },
  { text: "2020", value: "2020" },
  { text: "2021", value: "2021" },
  { text: "2022", value: "2022" },
];

export const MONTH_OPTIONS = [
  { text: "January", value: "0" },
  { text: "February", value: "1" },
  { text: "March", value: "2" },
  { text: "April", value: "3" },
  { text: "May", value: "4" },
  { text: "June", value: "5" },
  { text: "July", value: "6" },
  { text: "August", value: "7" },
  { text: "September", value: "8" },
  { text: "October", value: "9" },
  { text: "November", value: "10" },
  { text: "December", value: "11" },
];
