import { GET_CATEGORIES,SET_ALL_CATEGORIES, SET_SUB_CATEGORIES, SET_SUB_SUB_CATEGORIES } from "actions/category";

const initialState = {
  categories: [],
  sub_categories:[],
  sub_sub_categories:[]
};

export default function categoryReducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_CATEGORIES:
      return { ...state };
    case SET_ALL_CATEGORIES:{
        return { ...state, categories:action.data}
    }
    case SET_SUB_CATEGORIES:{
        return { ...state, sub_categories:action.data}
    }
    case SET_SUB_SUB_CATEGORIES:{
        return { ...state, sub_sub_categories:action.data}
    }
    default:
      return state;
  }
}
