import { authorizedGet } from "api";
import {
  GET_DASHBOARD_JOB_POSTS,
  GET_CLIENT_JOB_POSTS,
  GET_SUBMITTED_PROPOSAL,
  GET_ALL_CONTRACTS,
} from "api/job";

export const getDashboardJobPosts = (query) =>
  authorizedGet(GET_DASHBOARD_JOB_POSTS, query);

export const getClientJobPosts = (query) =>
  authorizedGet(GET_CLIENT_JOB_POSTS, query);

export const getSubmittedProposals = (query) =>
  authorizedGet(GET_SUBMITTED_PROPOSAL, query);

export const getContracts = (query) => authorizedGet(GET_ALL_CONTRACTS, query);
