import React from "react";

import Banner from "./components/Banner";
import Categories from "./components/Categories";
import Clients from "./components/Clients";
import HowItWorks from "./components/HowItWorks";
import Features from "./components/Features";
import Post from "./components/Post";
import Consultation from "./components/Consultation";

import "./HomePage.scss";

function HomePage() {
  return (
    <main className="homepage">
      <Banner />
      <Categories />
      <Clients />
      <HowItWorks />
      <Features />
      <Post />
      <Consultation />
    </main>
  );
}

export default HomePage;
