import React from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Icon,
  Progress,
  Accordion,
  Modal,
  Form,
  TextArea,
} from "semantic-ui-react";

import ROUTES from "constants/Routes";
import NewWindow from "static/icons/new-window.svg";

import MilestoneModal from "../MilestoneModal";
import { calculateProgress } from "../../utils";

import "./ClientView.scss";

function ClientView({
  contract,
  goToChat,
  getPaymentLink,
  acceptOrRejectReview,
  raiseADispute,
}) {
  const [modalActive, setModalActive] = React.useState(false);
  const [activeIndex, setActiveIndex] = React.useState(-1);
  const [note, setNote] = React.useState("");
  const [dispute, setDispute] = React.useState("");

  const modalOnClose = React.useCallback(() => setModalActive(false), []);
  const handleAccordionClick = React.useCallback((event, data) => {
    if (!data.active) {
      setActiveIndex(data.index);
    } else {
      setActiveIndex(-1);
    }
  }, []);

  const totalMilestones = contract.milestones.length;
  const progress = calculateProgress(contract);

  return (
    <>
      <div className="jobContract-body">
        <h4>{contract.jobId.title}</h4>
        <Link
          className="jobDetailWrapper-sidebar-header-link jobContract-job-link"
          to={`${ROUTES.JOB_POST_DETAIL.replace(":id", contract?.jobId?._id)}`}
          target="_blank"
        >
          <img src={NewWindow} alt="" />
          Open Job details
        </Link>
        <Progress
          className="jobContract-progress"
          value={progress}
          total={totalMilestones}
          progress="ratio"
        >
          {totalMilestones === 0
            ? "No milestone added yet"
            : `${totalMilestones} total Milestones`}
        </Progress>
        <div>
          <Accordion className="jobDetailProposals">
            <div className="clientView-accordionTitle">
              <div className="clientView-H1">Milestone Title</div>
              <div className="clientView-H2">Milestone Amount</div>
              <div className="clientView-H2">Payment Status</div>
              <div></div>
            </div>
            {contract.milestones.map((milestone, index) => {
              return (
                <>
                  <Accordion.Title
                    active={activeIndex === index}
                    index={index}
                    className="clientView-accordionTitle"
                    onClick={(event, data) => handleAccordionClick(event, data)}
                  >
                    <div>{milestone.title}</div>
                    <div className="amount">&#8377; {milestone.amount}</div>
                    <div className="status">In Escrow</div>
                    <div className="arrow">
                      <Icon name="dropdown" />
                    </div>
                  </Accordion.Title>
                  <Accordion.Content active={activeIndex === index}>
                    <div className="clientView-topic">
                      <div className="clientView-topic-heading">
                        Deliverables to deliver:
                      </div>
                      <div></div>
                      <div></div>
                      {milestone.topics.map((topic) => {
                        const reviewsExist = Array.isArray(topic.reviews);
                        const hasAcceptedReview = reviewsExist
                          ? topic.reviews.find(
                              (review) => review.status === "accepted"
                            ) && topic.completed
                          : null;
                        const hasInProgressReview = reviewsExist
                          ? topic.reviews.find(
                              (review) => review.status === "in_progress"
                            )
                          : null;
                        const hasChangesReview = reviewsExist
                          ? topic.reviews.find(
                              (review) => review.status === "changes_requested"
                            )
                          : null;

                        let reviewStatus;

                        if (hasAcceptedReview) {
                          reviewStatus = (
                            <div className="review-completed">Completed</div>
                          );
                        } else if (hasInProgressReview) {
                          reviewStatus = (
                            <div className="review-in-progress">
                              Review Requested
                              <div className="review-in-progress-ctas">
                                <div
                                  className="accept"
                                  onClick={() =>
                                    acceptOrRejectReview(
                                      milestone._id,
                                      topic.topicId,
                                      true
                                    )
                                  }
                                >
                                  Accept
                                </div>
                                <Modal
                                  trigger={<div className="reject">Reject</div>}
                                  content={
                                    <div className="review-reject-modal">
                                      <Form>
                                        <div className="reject-ques">
                                          Tell us more why this review is
                                          rejected
                                        </div>
                                        <TextArea
                                          value={note}
                                          onChange={(e) =>
                                            setNote(e.target.value)
                                          }
                                        />
                                      </Form>
                                    </div>
                                  }
                                  actions={[
                                    "Cancel",
                                    {
                                      key: "reject",
                                      content: `Reject`,
                                      negative: true,
                                      onClick: () =>
                                        acceptOrRejectReview(
                                          milestone._id,
                                          topic.topicId,
                                          false,
                                          note
                                        ),
                                    },
                                  ]}
                                />
                              </div>
                            </div>
                          );
                        } else if (hasChangesReview) {
                          const changesReviewIndex =
                            topic.reviews.findLastIndex(
                              (r) => r.status === "changes_requested"
                            );
                          reviewStatus = (
                            <div className="review-changes">
                              Changes requested{" "}
                              <Modal
                                trigger={<div className="note">View Note</div>}
                                content={
                                  <div className="review-reject-modal">
                                    <div className="heading">
                                      Additional note for changes:
                                    </div>
                                    <div>
                                      {topic.reviews[changesReviewIndex].note}
                                    </div>
                                  </div>
                                }
                                actions={["Okay"]}
                              />
                            </div>
                          );
                        }

                        return (
                          <>
                            <div className="clientView-topic-title">
                              {topic.title}
                            </div>
                            {topic.docId ? (
                              <a
                                href={`https://docs.google.com/document/d/${topic.docId}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="clientView-topic-document"
                              >
                                Document Link
                              </a>
                            ) : (
                              <a></a>
                            )}
                            <div>{reviewStatus}</div>
                          </>
                        );
                      })}
                    </div>
                  </Accordion.Content>
                </>
              );
            })}
          </Accordion>
        </div>
      </div>
      <div className="jobContract-sidebar">
        <div>
          <Button className="primary" onClick={() => setModalActive(true)}>
            Add Milestone
          </Button>
        </div>
        <div>
          <Button icon labelPosition="left" basic primary onClick={goToChat}>
            <Icon name="chat" className="chat-icon" />
            Message
          </Button>
        </div>
        <div className="jobDetail-actionables-figures">
          <div className="jobDetail-actionables-figures-divider"></div>
          <div className="jobDetail-actionables-figures-item">
            <p>&#8377; {contract.bid}</p>
            <span>Final Bid</span>
          </div>
          <div className="jobDetail-actionables-figures-divider"></div>
          <div className="jobDetail-actionables-figures-item clientView-sidebar-total">
            <p>
              &#8377;{" "}
              {contract.milestones.reduce((arr, m) => arr + m.amount, 0)}
            </p>
            <span>Total Payment made</span>
          </div>
          <div className="jobDetail-actionables-figures-divider"></div>
          <div className="jobDetail-actionables-figures-item">
            <p>{contract.jobId.duration}</p>
            <span>Est. Time</span>
          </div>
          <div className="jobDetail-actionables-figures-divider"></div>
          <div className="jobDetail-actionables-figures-item">
            <p>{contract.applicationId.userId.name}</p>
            <span>Freelancer</span>
            <Link
              className="jobDetailWrapper-sidebar-header-link jobContract-job-link"
              to={`${ROUTES.VIEW_SPECIFIC_FREELANCER.replace(
                ":id",
                contract?.applicationId?.userId?._id
              )}`}
              target="_blank"
            >
              <img src={NewWindow} alt="" />
              Open Freelancer profile
            </Link>
          </div>
          <div className="jobDetail-actionables-figures-divider"></div>
          <div>
            <Modal
              trigger={<div className="raise-a-dispute">Raise a dispute</div>}
              content={
                <div className="raise-a-dispute-modal">
                  <div>Raise a dispute</div>
                  <TextArea
                    placeholder="Tell us about your dispute"
                    value={dispute}
                    onChange={(e) => setDispute(e.target.value)}
                  />
                </div>
              }
              actions={[
                "Cancel",
                {
                  key: "submit",
                  content: `Submit`,
                  positive: true,
                  onClick: () => raiseADispute(dispute),
                },
              ]}
            />
          </div>
        </div>
      </div>
      <MilestoneModal
        active={modalActive}
        onClose={modalOnClose}
        getPaymentLink={getPaymentLink}
      />
    </>
  );
}

export default ClientView;
