import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Loader, Button } from "semantic-ui-react";

import ROUTES from "constants/Routes";
import FormWrapper from "components/FormWrapper";

import JobProposalBody from "./components/JobProposalBody";
import { getJobPost, submitJobProposal } from "./actions";
import { stateToProps } from "./maps";
import "./JobProposal.scss";

function JobProposal() {
  const { userId } = useSelector(stateToProps);
  const { id } = useParams();
  const [job, setJob] = React.useState();
  const [application, setApplication] = React.useState({
    bid: undefined,
    // naming it different, cause "job" also has a duration field
    applicationDuration: "",
    cover: "",
    additionalAnswer1: "",
    additionalAnswer2: "",
    additionalAnswer3: "",
  });
  const [isFetching, setIsFetching] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [successfullySubmitted, setSuccessfullySubmitted] =
    React.useState(false);
  const [submittedProposalId, setSubmittedProposalId] = React.useState("");

  React.useEffect(() => {
    setIsFetching(true);

    getJobPost(id)
      .then((response) => {
        setIsFetching(false);
        setJob({ ...response.data.post });
      })
      .catch((error) => {
        setIsFetching(false);
        try {
          toast.error(`Error occurred! ${error.response.data.error}`);
        } catch (e) {
          toast.error(`Error occurred!`);
        }
      });
  }, [id]);

  const handleOnChange = React.useCallback(
    (key, value) => {
      if (isSubmitting) return;
      setApplication({ ...application, [key]: value });
    },
    [application, isSubmitting]
  );

  const handleOnSubmit = React.useCallback(() => {
    const {
      bid,
      applicationDuration,
      cover,
      additionalAnswer1,
      additionalAnswer2,
      additionalAnswer3,
    } = application;
    const { additionalQuestion1, additionalQuestion2, additionalQuestion3 } =
      job;

    if (!Number.isInteger(Number(bid)) || Number(bid) <= 0) {
      toast.error("Bid Amount should be an integer and greater than 0.");
      return;
    }

    const checkIfEmpty = (field) => {
      if (!field) {
        toast.error(`${field} cannot be empty`);
      }

      return !field;
    };

    if (!applicationDuration) {
      toast.error("Duration is mandatory");
      return;
    }

    if (
      checkIfEmpty(cover) ||
      (additionalQuestion1 && checkIfEmpty(additionalAnswer1)) ||
      (additionalQuestion2 && checkIfEmpty(additionalAnswer2)) ||
      (additionalQuestion3 && checkIfEmpty(additionalAnswer3))
    ) {
      return;
    }

    setIsSubmitting(true);

    const proposal = {
      userId,
      jobId: id,
      bid,
      duration: applicationDuration,
      cover,
      additionalAnswer1,
      additionalAnswer2,
      additionalAnswer3,
    };

    submitJobProposal(proposal)
      .then((response) => {
        setIsSubmitting(false);
        setSuccessfullySubmitted(true);
        setSubmittedProposalId(response.data.proposalId);
      })
      .catch((error) => {
        setIsSubmitting(false);
        try {
          if (Array.isArray(error.response.data.error)) {
            toast.error(
              `Submitting proposal failed! ${error.response.data.error[0].msg}`
            );
          } else {
            toast.error(
              `Submitting proposal failed! ${error.response.data.error}`
            );
          }
        } catch (e) {
          toast.error(`Submitting proposal failed!`);
        }
      });
  }, [application, job, id, userId]);

  const isSubmitEnabled =
    application.bid &&
    application.applicationDuration &&
    application.cover &&
    (job.additionalQuestion1 ? application.additionalAnswer1 : true) &&
    (job.additionalQuestion2 ? application.additionalAnswer2 : true) &&
    (job.additionalQuestion3 ? application.additionalAnswer3 : true);

  return (
    <FormWrapper
      heading="Job Proposal"
      subHeading="Submit a proposal by providing details"
    >
      <ToastContainer
        className="toast"
        position="bottom-center"
        autoClose={2500}
        hideProgressBar
      />
      <Loader active={isFetching} />
      {successfullySubmitted ? (
        <FormWrapper.Success
          successMessage="Proposal succesfully submitted!"
          primaryButtonMessage="Go to dashboard"
          secondaryButtonMessage="View proposal"
          primaryLink={ROUTES.DASHBOARD}
          secondaryLink={ROUTES.JOB_PROPOSAL.replace(
            ":id",
            submittedProposalId
          )}
        />
      ) : (
        <div className="jobProposal-body">
          {job && (
            <JobProposalBody
              {...application}
              {...job}
              onChange={handleOnChange}
            />
          )}
          <div className="jobProposalBody-cta">
            <Button
              className="primary"
              loading={isSubmitting}
              onClick={handleOnSubmit}
              disabled={!isSubmitEnabled}
            >
              Submit
            </Button>
          </div>
        </div>
      )}
    </FormWrapper>
  );
}

export default JobProposal;
