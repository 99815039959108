import ROUTES from "constants/Routes";

export const LOGGED_OUT_LINKS = [
  { id: "blog", text: "Blog", url: ROUTES.BLOG },
  // { id: "how-it-works", text: "How It Works", url: ROUTES.HOW_IT_WORKS },
  { id: "contact-us", text: "Contact Us", url: ROUTES.CONTACT_US },
  // {
  //   id: "browse-projects",
  //   text: "Browse Projects",
  //   url: ROUTES.BROWSE_PROJECT,
  // },
  {
    id: "view-freelancers",
    text: "View Freelancers",
    url: ROUTES.VIEW_ALL_FREELANCERS,
  },
];

export const FREELANCER_LINKS = [
  {
    id: "header-link-freelancer-1",
    text: "View jobs",
    url: ROUTES.JOB_POST_SEARCH,
  },
];

export const CLIENT_LINKS = [
  {
    id: "view-freelancers",
    text: "View Freelancers",
    url: ROUTES.VIEW_ALL_FREELANCERS,
  },
  {
    id: "payment-history",
    text: "Payment History",
    url: ROUTES.PAYMENT_HISTORY,
  },
];

export const PROFILE_LINKS = [
  { id: "profile-link-1", text: "Settings", url: ROUTES.PROFILE },
  { id: "profile-link-1", text: "Chat", url: ROUTES.CHAT },
];
