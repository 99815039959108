import React from "react";
import { useParams, useHistory, Link } from "react-router-dom";
import { Loader, Label, Button } from "semantic-ui-react";
import { ToastContainer, toast } from "react-toastify";

import ROUTES from "constants/Routes";
import { firstLetterCapitalise } from "utils";
import { getProposalStatusColor } from "utils/job";
import NewWindow from "static/icons/new-window.svg";

import { getSpecificProposal } from "./actions";
import "./JobProposalView.scss";

function JobProposalView() {
  const { id } = useParams();
  const history = useHistory();

  const [proposal, setProposal] = React.useState({});
  const [contractId, setContractId] = React.useState("");
  const [isFetching, setIsFetching] = React.useState(false);

  React.useEffect(() => {
    setIsFetching(true);

    getSpecificProposal(id)
      .then((response) => {
        if (response.data.redirect) {
          const { path, _id } = response.data.additionalInfo;

          if (path === "contract") {
            history.push(ROUTES.JOB_POST_HIRE.replace(":id", _id));
          }
        }

        setIsFetching(false);
        setProposal(response.data.proposal);
        setContractId(response.data.contractId || "");
      })
      .catch((error) => {
        setIsFetching(false);
        try {
          toast.error(`Fetching proposal failed! ${error.response.data.error}`);
        } catch (e) {
          toast.error(`Fetching proposal failed!`);
        }
      });
  }, [history, id]);

  return (
    <div className="jobContract-wrapper">
      <ToastContainer
        className="toast"
        position="bottom-center"
        autoClose={2500}
        hideProgressBar
      />
      <Loader active={isFetching} />
      <div className="jobContract">
        <div className="jobContract-body">
          <h4>{proposal?.jobId?.title}</h4>
          <Link
            className="jobDetailWrapper-sidebar-header-link"
            to={`${ROUTES.JOB_POST_DETAIL.replace(
              ":id",
              proposal?.jobId?._id
            )}`}
            target="_blank"
          >
            <img src={NewWindow} alt="" />
            Open job in a new window
          </Link>
          <p className="jobDetail-content-description">{proposal.cover}</p>
          <div className="jobProposalView">
            {proposal?.jobId?.additionalQuestion1 && (
              <div className="jobProposalView-additional">
                <p className="jobProposalView-additional-question">
                  {proposal?.jobId?.additionalQuestion1}
                </p>
                <p className="jobProposalView-additional-answer">
                  {proposal?.additionalAnswer1}
                </p>
              </div>
            )}
            {proposal?.jobId?.additionalQuestion2 && (
              <div className="jobProposalView-additional">
                <p className="jobProposalView-additional-question">
                  {proposal?.jobId?.additionalQuestion2}
                </p>
                <p className="jobProposalView-additional-answer">
                  {proposal?.additionalAnswer2}
                </p>
              </div>
            )}
            {proposal?.jobId?.additionalQuestion3 && (
              <div className="jobProposalView-additional">
                <p className="jobProposalView-additional-question">
                  {proposal?.jobId?.additionalQuestion3}
                </p>
                <p className="jobProposalView-additional-answer">
                  {proposal?.additionalAnswer3}
                </p>
              </div>
            )}
          </div>
        </div>
        <div className="jobContract-sidebar">
          {proposal?.status === "accepted" && (
            <div>
              <Button
                className="primary"
                onClick={() =>
                  history.push(ROUTES.JOB_POST_HIRE.replace(":id", contractId))
                }
              >
                Go to Contract
              </Button>
            </div>
          )}
          <div className="jobDetail-actionables-figures">
            <div className="jobDetail-actionables-figures-item">
              <Label color={getProposalStatusColor(proposal.status)}>
                {firstLetterCapitalise(proposal.status)}
              </Label>
              <span>Status</span>
            </div>
            <div className="jobDetail-actionables-figures-divider"></div>
          </div>
          <div className="jobDetail-actionables-figures">
            <div className="jobDetail-actionables-figures-item">
              <p>{proposal?.bid}</p>
              <span>Bid</span>
            </div>
            <div className="jobDetail-actionables-figures-divider"></div>
          </div>
          <div className="jobDetail-actionables-figures">
            <div className="jobDetail-actionables-figures-item">
              <p>{proposal?.duration}</p>
              <span>Duration</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default JobProposalView;
