import { authorizedPost, authorizedGet } from "api";

import { PROFILE_SETUP_URL } from "api/user";
import { GET_ALL_SKILLS } from "api/job";

export const profileSetupAPI = (profileData) => {
  return authorizedPost(PROFILE_SETUP_URL, profileData);
};

export const getAllSkills = () => {
  return authorizedGet(GET_ALL_SKILLS);
};
