import { createStore, applyMiddleware, combineReducers, compose } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";

import UserReducer from "reducers/UserReducer";
import ProfileReducer from "reducers/ProfileReducer";
import CategoryReducer from "reducers/CategoryReducer";
import MessagesReducer from "reducers/messagesReducer";
import PortfolioReducer from "reducers/portfolioReducer";

const persistConfig = {
  key: "persistedStore",
  storage,
};

const reducers = combineReducers({
  persistedStore: persistReducer({ ...persistConfig, storage }, UserReducer),
  profile: ProfileReducer,
  category: CategoryReducer,
  messages: MessagesReducer,
  portfolio: PortfolioReducer,
});

const store = createStore(
  reducers,
  compose(
    applyMiddleware(thunk)
    // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  )
);

const persistor = persistStore(store);

export { store, persistor };
