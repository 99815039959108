export const SET_USER = "SET_USER";
export const SET_USER_META = "SET_USER_META";
export const LOGOUT_USER = "LOGOUT_USER";

export function setUser(user = {}) {
  return (dispatch) => {
    dispatch({
      type: SET_USER,
      data: user,
    });
  };
}

export function logoutUser() {
  return (dispatch) => {
    dispatch({
      type: LOGOUT_USER,
    });
  };
}

export function setUserMeta(meta = {}) {
  return (dispatch) => {
    dispatch({
      type: SET_USER_META,
      data: meta,
    });
  };
}
