import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Dimmer, Loader, Input, Dropdown } from "semantic-ui-react";
import { ToastContainer, toast } from "react-toastify";

import Section from "components/Section";
import Card from "components/Card";
import { TYPE_OF_FREELANCERS } from "containers/JobPost/constants";
import {
  FREELANCER_TYPE,
  CHOOSE_LOCATION_OPTION,
  COMMUNICATION_SKILLS,
} from "containers/ProfileSetup/constants";

import { stateToProps } from "../../maps";
import { saveProfileData } from "../../actions";

import "./ProfileSettings.scss";

function ProfileSettings({
  userProfile: { experienceLevel, socialLinks, freelancerQuestions },
  setProfileData,
}) {
  const [isExperienceDimmerActive, setIsExperienceDimmerActive] =
    React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [editedSocialLinks, setEditedSocialLinks] = React.useState({});
  const [editedAdditionalDetails, setEditedAdditionalDetails] = React.useState(
    []
  );
  const [editedLevel, setEditedLevel] = React.useState();

  const { userId } = useSelector(stateToProps);

  const handleOnChange = React.useCallback(
    (newLevel) => {
      if (newLevel === experienceLevel) return;

      setIsExperienceDimmerActive(true);

      saveProfileData(userId, {
        experienceLevel: parseInt(newLevel),
        action: "updateExperienceLevelInfo",
      })
        .then(() => {
          setProfileData({ experienceLevel: parseInt(newLevel) });
          setIsExperienceDimmerActive(false);
          setEditedLevel(newLevel);
          toast.success("Successfully saved!");
        })
        .catch((error) => {
          setIsExperienceDimmerActive(false);
          toast.error("Update failed! Please try again.");
          console.error(error);
        });
    },
    [experienceLevel, setProfileData, userId]
  );

  const saveAdditionalDetails = React.useCallback(
    (cb) => {
      if (!editedAdditionalDetails.length) {
        toast.success("Nothing to save!");
        cb();
        return;
      }

      setIsSubmitting(true);

      const payload = freelancerQuestions.map(
        (freelanceQuestion) =>
          editedAdditionalDetails.find(
            (detail) => detail.question === freelanceQuestion.question
          ) || freelanceQuestion
      );

      saveProfileData(userId, {
        freelancerQuestions: payload,
        action: "updateFreelancerQuestions",
      })
        .then(() => {
          setIsSubmitting(false);
          setProfileData({ freelancerQuestions });
          toast.success("Successfully saved!");

          cb();
        })
        .catch((error) => {
          setIsSubmitting(false);
          toast.error("Update failed! Please try again.");
          console.error(error);
        });
    },
    [editedAdditionalDetails, userId, freelancerQuestions, setProfileData]
  );

  const handleAdditionalDetailChange = React.useCallback(
    (name, answer) => {
      const editedDetails = editedAdditionalDetails.find(
        ({ question }) => question === name
      );
      const originalDetails = freelancerQuestions.find(
        ({ question }) => question === name
      );

      if (editedDetails) {
        setEditedAdditionalDetails((additionalDetails) =>
          additionalDetails.map((detail) =>
            detail.question === name ? { ...detail, answer } : detail
          )
        );
      } else {
        setEditedAdditionalDetails((additionalDetails) => [
          ...additionalDetails,
          { question: name, answer },
        ]);
      }
    },
    [editedAdditionalDetails, freelancerQuestions]
  );

  const updateSocialLinks = React.useCallback(
    (cb) => {
      if (!Object.keys(editedSocialLinks).length) {
        toast.error("Nothing to save!");
        // cb();
        return;
      }

      setIsSubmitting(true);

      saveProfileData(userId, {
        socialLinks: editedSocialLinks,
        action: "updateSocialLinksInfo",
      })
        .then(() => {
          setIsSubmitting(false);
          setProfileData((profile) => ({
            ...profile,
            socialLinks: editedSocialLinks,
          }));

          cb();
          toast.success("Successfully saved!");
        })
        .catch((error) => {
          setIsSubmitting(false);
          toast.error("Update failed! Please try again.");
          console.error(error);
        });
    },
    [editedSocialLinks, setProfileData, userId]
  );

  const handleOnChangeform = React.useCallback(
    (inputData) => {
      const { name, value } = inputData;
      const newSocialLinks = { ...editedSocialLinks, [name]: value };
      setEditedSocialLinks(newSocialLinks);
    },
    [editedSocialLinks]
  );

  const resetSocialData = React.useCallback(() => setEditedSocialLinks({}), []);
  const resetAdditionalDetails = React.useCallback(
    () => setEditedAdditionalDetails([]),
    []
  );

  const whatKind =
    editedAdditionalDetails.find(
      ({ question }) => question === "whatKindOfFreelancerYouAre"
    ) ||
    freelancerQuestions.find(
      ({ question }) => question === "whatKindOfFreelancerYouAre"
    );
  const chooseLocation =
    editedAdditionalDetails.find(
      ({ question }) => question === "chooseLocationPreference"
    ) ||
    freelancerQuestions.find(
      ({ question }) => question === "chooseLocationPreference"
    );
  const rateYourCommunicationSkills =
    editedAdditionalDetails.find(
      ({ question }) => question === "rateYourCommunicationSkills"
    ) ||
    freelancerQuestions.find(
      ({ question }) => question === "rateYourCommunicationSkills"
    );

  return (
    <>
      <ToastContainer
        className="toast"
        position="bottom-center"
        autoClose={2500}
        hideProgressBar
      />
      <Dimmer.Dimmable as="">
        <Dimmer active={isExperienceDimmerActive} inverted>
          <Loader />
        </Dimmer>
        <Section alwaysEditable>
          <Section.Header heading="Experience Level" />
          <Section.Editable>
            <div className="field small row">
              {TYPE_OF_FREELANCERS.map((type) => (
                <Card
                  {...type}
                  selected={
                    `${type.id}` === `${editedLevel || experienceLevel}`
                  }
                  customClassName="profileSettings-experience-card"
                  onClick={handleOnChange}
                />
              ))}
            </div>
          </Section.Editable>
        </Section>
      </Dimmer.Dimmable>
      <Section>
        <Section.Header heading="Additional Questions" />
        <Section.ReadOnly>
          <div className="heading">What kind of freelancer you are?</div>
          <div className="para">{whatKind?.answer}</div>
          <div className="heading profileSettings-space-up">
            What's your location preference?
          </div>
          <div className="para">{chooseLocation?.answer}</div>
          <div className="heading profileSettings-space-up">
            How are your communication skills?
          </div>
          <div className="para">{rateYourCommunicationSkills?.answer}</div>
        </Section.ReadOnly>
        <Section.Editable
          isUpdating={isSubmitting}
          onCancel={resetAdditionalDetails}
          onUpdate={saveAdditionalDetails}
        >
          <div className="profileStep1-fields">
            <Dropdown
              name="whatKindOfFreelancerYouAre"
              placeholder="Select freelancer type"
              onChange={(e, data) =>
                handleAdditionalDetailChange(data.name, data.value)
              }
              options={FREELANCER_TYPE}
              value={whatKind?.answer}
              fluid
              selection
            />
            <label htmlFor="name">What kind of freelancer you are</label>
          </div>
          <div className="profileStep1-fields">
            <Dropdown
              name="chooseLocationPreference"
              placeholder="Select one"
              onChange={(e, data) =>
                handleAdditionalDetailChange(data.name, data.value)
              }
              fluid
              selection
              options={CHOOSE_LOCATION_OPTION}
              value={chooseLocation?.answer}
            />
            <label htmlFor="name">Choose location preference</label>
          </div>
          <div className="profileStep1-fields">
            <Dropdown
              name="rateYourCommunicationSkills"
              placeholder="Select your rating"
              onChange={(e, data) =>
                handleAdditionalDetailChange(data.name, data.value)
              }
              fluid
              selection
              value={rateYourCommunicationSkills?.answer}
              options={COMMUNICATION_SKILLS}
            />
            <label htmlFor="name"> Rate your communication skills</label>
          </div>
        </Section.Editable>
      </Section>
      <Dimmer.Dimmable as="">
        <Section>
          <Section.Header heading="Social links" />
          <Section.ReadOnly>
            <div className="item">
              <div className="heading">github</div>
              <div className="para">
                {(editedSocialLinks.github !== undefined
                  ? editedSocialLinks.github
                  : socialLinks?.github) || (
                  <span className="noDataAdded">No link added yet</span>
                )}
              </div>
              <div className="heading profileSettings-space-up">behance</div>
              <div className="para">
                {(editedSocialLinks.behance !== undefined
                  ? editedSocialLinks.behance
                  : socialLinks?.behance) || (
                  <span className="noDataAdded">No link added yet</span>
                )}
              </div>
              <div className="heading profileSettings-space-up">dribbble</div>
              <div className="para">
                {(editedSocialLinks.dribbble !== undefined
                  ? editedSocialLinks.dribbble
                  : socialLinks?.dribbble) || (
                  <span className="noDataAdded">No link added yet</span>
                )}
              </div>
              <div className="heading profileSettings-space-up">linkedIn</div>
              <div className="para">
                {(editedSocialLinks.linkedin !== undefined
                  ? editedSocialLinks.linkedin
                  : socialLinks?.linkedin) || (
                  <span className="noDataAdded">No link added yet</span>
                )}
              </div>
            </div>
          </Section.ReadOnly>
          <Section.Editable
            isUpdating={isSubmitting}
            onCancel={resetSocialData}
            onUpdate={updateSocialLinks}
          >
            <div className="field">
              <label>Github</label>
              <Input
                value={
                  editedSocialLinks.github !== undefined
                    ? editedSocialLinks.github
                    : socialLinks?.github
                }
                placeholder="Add github profile link"
                name="github"
                onChange={(_, data) => handleOnChangeform(data)}
              />
            </div>
            <div className="field">
              <label>Behance</label>
              <Input
                value={
                  editedSocialLinks.behance !== undefined
                    ? editedSocialLinks.behance
                    : socialLinks?.behance
                }
                placeholder="Add behance profile link"
                name="behance"
                onChange={(_, data) => handleOnChangeform(data)}
              />
            </div>
            <div className="field">
              <label>Dribbble</label>
              <Input
                value={
                  editedSocialLinks.dribbble !== undefined
                    ? editedSocialLinks.dribbble
                    : socialLinks?.dribbble
                }
                placeholder="Add dribbble profile link"
                name="dribbble"
                onChange={(_, data) => handleOnChangeform(data)}
              />
            </div>
            <div className="field">
              <label>LinkedIn</label>
              <Input
                value={
                  editedSocialLinks.linkedin !== undefined
                    ? editedSocialLinks.linkedin
                    : socialLinks?.linkedin
                }
                placeholder="Add linkedin profile link"
                name="linkedin"
                onChange={(_, data) => handleOnChangeform(data)}
              />
            </div>
          </Section.Editable>
        </Section>
      </Dimmer.Dimmable>
    </>
  );
}

ProfileSettings.propTypes = {
  experienceLevel: PropTypes.string,
};

ProfileSettings.defaultProps = {
  experienceLevel: 1,
};
export default ProfileSettings;
