import axios from "axios";
import { getToken } from "../utils";

/**
 * Makes an authorized post call with token
 * @param {string} url url for the post request
 * @param {string} data data to send in the request
 * @param {Object} headers any additional headers to send in the request
 */
export const authorizedPost = async (url, data, headers = {}) => {
  const token = await getToken();
  return axios.post(url, data, {
    headers: { Authorization: `Bearer ${token}`, ...headers },
  });
};

/**
 * Makes an authorized get call with token
 * @param {string} url url of the request
 * @param {Object} query additional parameters to send in get request
 */
export const authorizedGet = async (url, query = {}) => {
  const token = await getToken();
  return axios.get(url, {
    headers: { Authorization: `Bearer ${token}` },
    params: { ...query },
  });
};

export const unauthorizedGet = async (url, query = {}) =>
  axios.get(url, { params: { ...query } });

export const unauthorizedPost = async (url, data) => axios.post(url, data);
