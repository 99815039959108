import React from "react";
import PropTypes from "prop-types";
import "../../../HomePage/components/Categories/Categories.scss";

function Step2({ subSubCategoryOption, subSubCategories, handleOnChange }) {
  return (
    <>
      {subSubCategories.map((subCategory) => (
        <div
          className="subCategory-option"
          key={subCategory.id}
          onClick={() => handleOnChange(subCategory.id)}
        >
          <input
            name="sub-category"
            type="radio"
            value={subCategory.id}
            checked={subCategory.id === subSubCategoryOption}
          />
          <p>{subCategory.title}</p>
        </div>
      ))}
    </>
  );
}

Step2.propTypes = {
  subSubCategoryOption: PropTypes.string,
  subSubCategories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
    })
  ),
  handleOnChange: PropTypes.func.isRequired,
};

Step2.defaultProps = {
  subCategoryOption: "",
  subCategories: [],
};

export default Step2;
