import { authorizedGet, authorizedPost } from "api";
import {
  GET_JOB_POST,
  GET_JOB_PROPOSALS,
  ACCEPT_REJECT_PROPOSALS,
  SUBMIT_NEW_CONTRACT,
  DEACTIVATE_JOB,
} from "api/job";

export const getJobPost = (id) =>
  authorizedGet(`${GET_JOB_POST.replace(":id", id)}`);

export const getJobProposal = (id, query) =>
  authorizedGet(`${GET_JOB_PROPOSALS.replace(":jobId", id)}`, query);

export const acceptRejectProposal = (id, data) =>
  authorizedPost(`${ACCEPT_REJECT_PROPOSALS.replace(":proposalId", id)}`, data);

export const createContract = (data) =>
  authorizedPost(SUBMIT_NEW_CONTRACT, data);

export const deactivateJob = (userId, jobId) =>
  authorizedPost(DEACTIVATE_JOB, { userId, jobId });
