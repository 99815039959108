import React from "react";
import { Input } from "semantic-ui-react";

import { maxPasswordLength } from "constants/Auth";

function PasswordInput({
  name = "password",
  placeholder = "Password",
  className,
  value,
  onChange,
  onFocus,
}) {
  const [isPasswordVisible, setIsPasswordVisible] = React.useState(false);

  const handleVisibilityChange = React.useCallback(() => {
    setIsPasswordVisible((visibility) => !visibility);
  }, []);

  return (
    <Input
      name={name}
      type={isPasswordVisible ? "text" : "password"}
      placeholder={placeholder}
      className={className}
      spellCheck={false}
      value={value}
      icon={{
        name: isPasswordVisible ? "eye slash" : "eye",
        link: true,
        onClick: handleVisibilityChange,
      }}
      maxLength={maxPasswordLength}
      onChange={onChange}
      onFocus={onFocus}
    />
  );
}

export default PasswordInput;
