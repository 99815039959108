import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Button } from "semantic-ui-react";

import ROUTES from "constants/Routes";

import "./DashboardHeader.scss";

function DashboardHeader({ isClient }) {
  return (
    <div className="dashboardHeader">
      <div className="dashboardHeader-content">
        <h2 className="formWrapper-heading">Dashboard</h2>
        <p className="formWrapper-subHeading">Your recent activities</p>
      </div>
      {isClient && (
        <Link to={ROUTES.JOB_POST_NEW}>
          <Button className="primary dashboardHeader-cta">
            Post a new job
          </Button>
        </Link>
      )}
    </div>
  );
}

DashboardHeader.propTypes = {
  isClient: PropTypes.bool.isRequired,
};

export default DashboardHeader;
