import React from "react";
import PropTypes from "prop-types";

import SimpleCardBody from "./components/SimpleCardBody";
import "./SimpleCard.scss";

function SimpleCard({ heading, renderHeaderItem, children }) {
  const getChildrenWithProps = React.useCallback((children) => {
    if (!Array.isArray(children)) return children;
    return children.map((child) => {
      if (child.type.name === SimpleCardBody.name) {
        return React.cloneElement(child, {
          ...child.props,
        });
      }

      return child;
    });
  }, []);

  const modifiedChildren = getChildrenWithProps(children);

  return (
    <div className="simpleCard">
      <div className="simpleCard-header">
        <h3>{heading}</h3>
        {renderHeaderItem && renderHeaderItem()}
      </div>
      {modifiedChildren}
    </div>
  );
}

SimpleCard.propTypes = {
  heading: PropTypes.string,
  renderHeaderItem: PropTypes.func,
};

SimpleCard.defaultProps = {
  heading: "",
  renderHeaderItem: null,
};

SimpleCard.Body = SimpleCardBody;

export default SimpleCard;
