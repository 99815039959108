import React from "react";
import { useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { Loader, Button } from "semantic-ui-react";

import ROUTES from "constants/Routes";
import { SocketContext } from "context/socket";
import FormWrapper from "components/FormWrapper";
import ChatTabs from "./components/ChatTabs";
import ChatPane from "./components/ChatPane";

import { getAllChats, initiateChat } from "./actions";
import { stateToProps } from "./maps";

import "./NewChat.scss";

function NewChat() {
  const location = useLocation();
  const history = useHistory();
  const { userId } = useSelector(stateToProps);
  const [chats, setChats] = React.useState([]);
  const [activeChatRoom, setActiveChatRoom] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const socket = React.useContext(SocketContext);

  React.useEffect(() => {
    socket.on("new message", ({ message, room }) => {
      setChats((chats) => {
        const existingChatRoomIndex = chats.findIndex(
          (c) => c._id === room._id
        );
        const allTheChats = [...chats];

        if (existingChatRoomIndex !== -1) {
          allTheChats[existingChatRoomIndex].messages.push(message);
        } else {
          allTheChats.push({ ...room, messages: [message] });
        }

        return allTheChats;
      });
    });
  }, [socket]);

  React.useEffect(() => {
    setIsLoading(true);

    getAllChats(userId)
      .then(({ data }) => {
        setIsLoading(false);
        setChats(data.chats);
      })
      .catch((e) => console.log("error"));
  }, [userId]);

  React.useEffect(() => {
    if (location.state) {
      initiateChat(location.state)
        .then(({ data }) => setActiveChatRoom(data.chatRoom))
        .catch((e) => console.log("error"));
    }
  }, [location, socket]);

  const handleSubmitMessage = React.useCallback(
    (message, cb) => {
      if (!activeChatRoom) {
        console.log("active chat room not found", activeChatRoom);
        return;
      }

      const { messages, ...restOfTheRoom } = activeChatRoom;

      socket.emit("send new message", {
        room: {
          ...restOfTheRoom,
          usersInvolved: restOfTheRoom.usersInvolved.map((ui) => ui?._id || ui),
        },
        userId,
        message,
      });

      cb();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeChatRoom, userId]
  );

  const handleChatSelection = React.useCallback((room) => {
    setActiveChatRoom(room);
  }, []);

  return (
    <div>
      <Loader active={isLoading} />
      {!isLoading && (
        <FormWrapper
          heading="Chats"
          subHeading="Message other members and get your projects running!"
        >
          <div className="newChat-wrapper">
            {!chats.length && !activeChatRoom ? (
              <div className="newChat-empty">
                <div>Looks like you haven't started any chat yet!</div>
                <Button
                  className="primary"
                  onClick={() => history.push(ROUTES.DASHBOARD)}
                >
                  Back to Dashboard
                </Button>
              </div>
            ) : (
              <>
                <ChatTabs
                  activeChatRoom={activeChatRoom}
                  currentUserId={userId}
                  chats={chats}
                  onChatClick={handleChatSelection}
                />
                <ChatPane
                  currentUserId={userId}
                  activeChat={chats.find(
                    (cr) => cr._id === activeChatRoom?._id
                  )}
                  onMessageSubmit={handleSubmitMessage}
                />
              </>
            )}
          </div>
        </FormWrapper>
      )}
    </div>
  );
}

export default NewChat;
