import React, { useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Button, Divider, Input } from "semantic-ui-react";
// import FacebookLogin from "react-facebook-login";
import { GoogleLogin } from "react-google-login";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Loader from "components/Loader";
import PasswordInput from "components/PasswordInput";
import { isEmailValid } from "components/Auth/utils";
import {
  maxEmailLength,
  maxPasswordLength,
  minPasswordLength,
} from "constants/Auth";
import { FACEBOOK_APP_ID, GOOGLE_CLIENT_ID } from "constants/Keys";
import { setToken } from "utils";

import {
  userGoogleSignUpAPI,
  userFacebookSignUpAPI,
  userSignup,
} from "../../actions";
import { PASSWORD_LESS_CHARACTER, INVALID_EMAIL_ID } from "../../constants";
import { dispatchToProps } from "../../maps";

import "./SignupForm.scss";

function SignupForm({ onSubmit }) {
  const location = useLocation();
  const { setUser } = dispatchToProps(useDispatch());

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);

  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);

  const [loading, isLoading] = useState(false);

  const handleEmailChange = useCallback((e) => {
    if (e.target.value.length <= maxEmailLength) setEmail(e.target.value);
  }, []);

  const handlePasswordChange = useCallback((e) => {
    if (e.target.value.length <= maxPasswordLength) setPassword(e.target.value);
  }, []);

  const resetErrors = useCallback((e) => {
    if (e.target.name === "email") {
      setEmailError(false);
    }

    if (e.target.name === "password") {
      setPasswordError(false);
    }
  }, []);

  const submitCreateAccount = useCallback(() => {
    let hasError = false;

    if (!isEmailValid(email)) {
      hasError = true;
      setEmailError(true);
    }

    if (
      password.length < minPasswordLength ||
      password.length > maxPasswordLength
    ) {
      hasError = true;
      setPasswordError(true);
    }

    if (hasError) return;

    isLoading(true);

    userSignup({
      email,
      password,
      redirectUrl: location.data ? location.data.redirectUrl : "",
    })
      .then((success) => {
        try {
          onSubmit({
            success: true,
            message: `${success.data.message}`,
          });
        } catch (e) {
          onSubmit({
            success: true,
            message: `Please check your email to confirm your account.`,
          });
        }
      })
      .catch((error) => {
        isLoading(false);
        try {
          toast.error(`Signup failed! ${error.response.data.error}.`);
        } catch (e) {
          toast.error(`Signup failed! Try again.`);
        }
      });
  }, [email, password, onSubmit, location.data]);

  const handleGoogleSignup = useCallback(
    (e) => {
      isLoading(true);
      userGoogleSignUpAPI({
        id: e.googleId,
        accessToken: e.tokenId,
      })
        .then((response) => {
          isLoading(false);
          setToken(response.data.token, () => {
            setUser({ ...response.data.meta });
          });
        })
        .catch((error) => {
          isLoading(false);
          try {
            toast.error(`Login failed! ${error.response.data.error}`);
          } catch (e) {
            toast.error(`Login failed!`);
          }
        });
    },
    [setUser]
  );

  // const handleFacebookSignup = useCallback((e) => {
  //   isLoading(true);
  //   userFacebookSignUpAPI({
  //     id: e.id,
  //     accessToken: e.accessToken,
  //     name: e.name,
  //     email: e.email,
  //   })
  //     .then((response) => {
  //       isLoading(false);
  //       setToken(response.data.token, () => {
  //         setUser({ ...response.data.meta });
  //       });
  //     })
  //     .catch((error) => {
  //       isLoading(false);
  //       try {
  //         toast.error(`Login failed! ${error.response.data.error}`);
  //       } catch (e) {
  //         toast.error(`Login failed!`);
  //       }
  //     });
  // }, []);

  const emailErrorClassName = emailError ? "error" : "";
  const passwordErrorClassName = passwordError ? "error" : "";

  return (
    <>
      {loading ? (
        <div className="signupForm-loader">
          <Loader />
        </div>
      ) : (
        <>
          <ToastContainer
            className="toast"
            position="bottom-center"
            autoClose={2500}
            hideProgressBar
          />
          <h3>Signup and grow with Expert Right</h3>
          <div className="signupForm-container">
            <Input
              className={`signupForm-container-email ${emailErrorClassName}`}
              name="email"
              type="email"
              placeholder="Email"
              maxLength={maxEmailLength}
              spellCheck={false}
              value={email}
              onFocus={resetErrors}
              onChange={handleEmailChange}
            />
            {emailError && (
              <span className="signupForm-error">{INVALID_EMAIL_ID}</span>
            )}
          </div>
          <div className="signupForm-container">
            <PasswordInput
              className={passwordErrorClassName}
              value={password}
              onFocus={resetErrors}
              onChange={handlePasswordChange}
            />
            {passwordError && (
              <span className="signupForm-error">
                {PASSWORD_LESS_CHARACTER}
              </span>
            )}
          </div>
          <Button
            className="primary signupForm-button"
            onClick={submitCreateAccount}
            fluid
          >
            Create Account
          </Button>
          <Divider horizontal className="login-divider">
            Or
          </Divider>
          <GoogleLogin
            clientId={GOOGLE_CLIENT_ID}
            buttonText="Signup with Google"
            className="google-login-button"
            onSuccess={handleGoogleSignup}
            onFailure={(e) => console.log("google response fail", e)}
          />
          {/* <FacebookLogin
            appId={FACEBOOK_APP_ID}
            fields="name, email"
            textButton="Signup with Facebook"
            autoLoad={false}
            cssClass="facebook-login-button"
            icon="fa-facebook"
            callback={handleFacebookSignup}
          /> */}
        </>
      )}
    </>
  );
}

SignupForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default SignupForm;
