export function calculateProgress(contract) {
  let progress = 0;

  if (Array.isArray(contract.milestones) && contract.milestones.length) {
    const completedMilestone = contract.milestones.filter((milestone) => {
      const completedTopics = milestone.topics.filter(
        (topic) =>
          Array.isArray(topic.reviews) &&
          topic.reviews.findIndex((r) => r.status === "accepted") !== -1
      ).length;

      const totalTopics = milestone.topics.length;
      return completedTopics === totalTopics;
    });
    progress = completedMilestone.length;
  }

  return progress;
}
