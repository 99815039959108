import React from "react";
import PropTypes from "prop-types";

import Success from "static/icons/success.svg";

import "./SignupSubmit.scss";

function SignupSubmit({ successMessage }) {
  return (
    <>
      <img className="signupSubmit-tick" src={Success} alt="" />
      <h3 className="signupSubmit-heading">Signup successful</h3>
      <p className="signupSubmit-message">{successMessage}</p>
    </>
  );
}

SignupSubmit.propTypes = {
  successMessage: PropTypes.string,
};

SignupSubmit.defaultProps = {
  successMessage: "Signup successful! Check your email to confirm the account.",
};

export default SignupSubmit;
