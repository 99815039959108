import React from "react";
import { useSelector } from "react-redux";

import { socket, SocketContext } from "context/socket";
import NewChat from "containers/NewChat";

import { stateToProps } from "./maps";

function NewChatWrapper() {
  const { userId } = useSelector(stateToProps);

  const socketConnection = React.useMemo(() => {
    return socket(userId)
  }, [userId]);

  React.useEffect(() => {
    return () => socketConnection.disconnect();
  }, [socketConnection]);

  if (!userId) return;

  return (
    <SocketContext.Provider value={socketConnection}>
      <NewChat />
    </SocketContext.Provider>
  );
}

export default NewChatWrapper;
