import React from "react";
import PropTypes from "prop-types";
import { Input, Checkbox, Button } from "semantic-ui-react";

import ProfileImagePlaceholder from "static/images/profile-image-placeholder.png";

import { PROFILE_TYPE_CHOICE } from "../../constants";
import "./Step1.scss";

function Step1({
  name,
  phone,
  profileType,
  profileImage,
  onChange,
  handleNextStep,
  handleImageUpload,
  formErrors,
  resetError,
}) {
  const handleOnChange = React.useCallback(
    (e) => onChange(e.target.name, e.target.value),
    [onChange]
  );
  return (
    <div className="profileStep1-wrapper">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ width: "66%" }}>
          <div className="profileStep1-fields">
            <Input
              name="name"
              placeholder="Enter your full name"
              value={name}
              spellCheck={false}
              onChange={handleOnChange}
              className={formErrors.name && "error"}
              onFocus={(e) => resetError(e.target.name)}
              error={formErrors.name}
              fluid
            />
            <label htmlFor="name">Name</label>
          </div>
          <div className="profileStep1-fields">
            <Input
              name="phone"
              className="phone"
              label="+91"
              placeholder="Enter phone number"
              type="number"
              value={phone}
              onFocus={(e) => resetError(e.target.name)}
              onChange={handleOnChange}
              error={formErrors.phone}
            />
            <label htmlFor="phone">Phone</label>
          </div>
        </div>
        <div className="field minor myProfile-file">
          <img
            src={profileImage || ProfileImagePlaceholder}
            style={{ borderRadius: "50%", overflow: "hidden" }}
            alt=""
          />
          <label className="myProfile-file-label">
            Change picture
            <Input
              className="myProfile-file-input"
              type="file"
              onChange={handleImageUpload}
            />
          </label>
          <div className="myProfile-file-limits">
            <div>File size limit: 10mb</div>
            <div>Only PNG, JPG allowed</div>
          </div>
        </div>
      </div>

      <div className="profileStep1-fields">
        {formErrors.profileType && (
          <div className="profileSetup-error">
            Please select one of the above choice
          </div>
        )}
        {PROFILE_TYPE_CHOICE.map((choice) => (
          <Checkbox
            key={choice.id}
            id={choice.id}
            name="profileType"
            label={choice.label}
            value={choice.value}
            checked={choice.value === profileType}
            onChange={handleOnChange}
            onFocus={(e) => resetError(e.target.name)}
          />
        ))}
        <label>I want to:</label>
      </div>
      <div className="step1-buttons">
        <Button className="primary" onClick={() => handleNextStep()}>
          Next
        </Button>
      </div>
    </div>
  );
}

Step1.propTypes = {
  name: PropTypes.string,
  phone: PropTypes.string,
  profileType: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  handleNextStep: PropTypes.func.isRequired,
  handleSubmitForm: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
};

Step1.defaultProps = {
  name: "",
  phone: "",
  profileType: "",
  isSubmitting: false,
};

export default Step1;
