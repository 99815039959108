import React from "react";
import { useSelector } from "react-redux";
import { Dimmer, Loader } from "semantic-ui-react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ROUTES from "constants/Routes";

import FormWrapper from "components/FormWrapper";
import { uploadFile } from "containers/Settings/actions";

import { JOB_POST_STEPS } from "./constants";

import Step1 from "./components/Step1";
import Step2 from "./components/Step2";
import Step3 from "./components/Step3";

import { submitJobPostApi } from "./actions";

import { MAX_FILE_SIZE, ALLOWED_MIMETYPES } from "./constants";
import { stateToProps } from "./maps";

import "./JobPost.scss";

function JobPost() {
  const { userId } = useSelector(stateToProps);
  const [currentStep, setCurrentStep] = React.useState(0);
  const [stepsCompleted, setStepsCompleted] = React.useState([0]);
  const [isFileUploading, setIsFileUploading] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [isDimmerActive, setIsDimmerActive] = React.useState(false);
  const [successfullySubmitted, setSuccessfullySubmitted] =
    React.useState(false);
  const [formData, setFormData] = React.useState({
    title: "",
    description: "",
    category: null,
    skills: null,
    isRemote: undefined,
    location: "",
    budget: null,
    levelOfFreelancer: "",
    assistanceType: "",
    duration: null,
    serviceProvider: "",
    documents: [],
    additionalQuestion1: "",
    additionalQuestion2: "",
    additionalQuestion3: "",
  });
  const [postSubmitId, setPostSubmitId] = React.useState("");

  const updateFormData = React.useCallback(
    (key, value) => {
      if (isSubmitting) return;
      setFormData({ ...formData, [key]: value });
    },
    [formData, isSubmitting]
  );
  const changeCurrentStep = React.useCallback(
    (stepNumber) => {
      const {
        title,
        category,
        description,
        skills,
        budget,
        levelOfFreelancer,
      } = formData;

      let totalStepsCompleted = [...stepsCompleted];

      if (stepNumber < currentStep) {
        totalStepsCompleted = totalStepsCompleted.filter(
          (step) => step !== currentStep
        );
      }

      if (stepNumber === 1) {
        if (title && category && description) {
          setStepsCompleted([...totalStepsCompleted, 1]);
        }
      } else if (stepNumber === 2) {
        if (
          Array.isArray(skills) &&
          skills.length &&
          budget &&
          levelOfFreelancer
        ) {
          setStepsCompleted([...totalStepsCompleted, 2]);
        }
      }

      window.scrollTo(0, 0);
      setCurrentStep(stepNumber);
    },
    [formData, stepsCompleted, currentStep]
  );

  const handleSubmitForm = React.useCallback(() => {
    if (isFileUploading) {
      toast.warn("Files are being uploaded. Please wait for them to finish");
      return;
    }

    const {
      title,
      description,
      category,
      skills,
      isRemote,
      budget,
      levelOfFreelancer,
      assistanceType,
      duration,
      serviceProvider,
    } = formData;

    if (
      !title ||
      !description ||
      !category ||
      !Array.isArray(skills) ||
      !skills.length ||
      isRemote === undefined ||
      !budget ||
      !levelOfFreelancer ||
      !assistanceType ||
      !duration ||
      !serviceProvider
    ) {
      toast.error("Please fill mandatory fields");
      return;
    }

    setIsSubmitting(true);

    submitJobPostApi({ ...formData, userId })
      .then((response) => {
        setPostSubmitId(response.data.id || "");
        setSuccessfullySubmitted(true);
        window.scrollTo(0, 0);
      })
      .catch((error) => {
        setIsSubmitting(false);
        try {
          if (Array.isArray(error.response.data.error)) {
            toast.error(
              `Submitting job post failed! ${error.response.data.error[0].msg}`
            );
          } else {
            toast.error(
              `Submitting job post failed! ${error.response.data.error}`
            );
          }
        } catch (e) {
          toast.error(`Submitting job post failed!`);
        }
      });
  }, [isFileUploading, formData, userId]);

  //upload Document
  const handleFileUpload = React.useCallback(
    async (e) => {
      if (!e.target.files.length) return;

      const filesToBeUploaded = e.target.files;
      if (
        !!Object.keys(filesToBeUploaded).filter(
          (docKey) =>
            !ALLOWED_MIMETYPES.includes(filesToBeUploaded[docKey].type)
        ).length
      ) {
        toast.error(`Only PDF, DOC and JPEG file types are allowed`);
        return;
      }

      if (
        !!Object.keys(filesToBeUploaded).filter(
          (docKey) => filesToBeUploaded[docKey].size > MAX_FILE_SIZE
        ).length
      ) {
        toast.error(`File size cannot be greater than 10mb`);
        return;
      }

      if (isSubmitting) return;

      setIsFileUploading(true);

      uploadFile(filesToBeUploaded)
        .then((response) => {
          setIsFileUploading(false);
          toast.success("Upload completed!");
          setFormData((formData) => ({
            ...formData,
            documents: [
              ...formData.documents,
              ...response.data.files.map(({ originalname, location }) => ({
                title: originalname,
                url: location,
              })),
            ],
          }));
        })
        .catch((error) => {
          setIsFileUploading(false);
          toast.error("Uploading failed!");
        });
    },
    [isSubmitting]
  );

  const handleRemoveDocument = React.useCallback((documentIndex) => {
    setFormData((formData) => ({
      ...formData,
      documents: formData.documents.filter((_, i) => i !== documentIndex),
    }));
  }, []);

  let step;
  switch (currentStep) {
    case 1:
      step = (
        <Step2
          skills={formData.skills}
          isRemote={formData.isRemote}
          location={formData.location}
          budget={formData.budget}
          levelOfFreelancer={formData.levelOfFreelancer}
          onChange={updateFormData}
          handleNextStep={() => changeCurrentStep(currentStep + 1)}
          handlePreviousStep={() => changeCurrentStep(currentStep - 1)}
        />
      );
      break;

    case 2:
      step = (
        <Step3
          {...formData}
          isFileUploading={isFileUploading}
          isSubmitting={isSubmitting}
          onChange={updateFormData}
          handleFileUpload={handleFileUpload}
          handleSubmitForm={handleSubmitForm}
          handlePreviousStep={() => changeCurrentStep(currentStep - 1)}
          handleRemoveDocument={handleRemoveDocument}
        />
      );

      break;

    default:
      step = (
        <Step1
          title={formData.title}
          category={formData.category}
          description={formData.description}
          onChange={updateFormData}
          handleNextStep={() => changeCurrentStep(currentStep + 1)}
        />
      );
  }

  return (
    <FormWrapper
      heading="Post a Job Form"
      subHeading="Fill out your details to submit a posting"
    >
      <ToastContainer
        className="toast"
        position="bottom-center"
        autoClose={2500}
        hideProgressBar
      />
      {successfullySubmitted ? (
        <FormWrapper.Success
          successMessage="Job succesfully posted!"
          primaryButtonMessage="Go to dashboard"
          secondaryButtonMessage="View job post"
          primaryLink={ROUTES.DASHBOARD}
          secondaryLink={ROUTES.JOB_POST_DETAIL.replace(":id", postSubmitId)}
        />
      ) : (
        <div className="jobPost-form">
          <FormWrapper.StepSidebar
            steps={JOB_POST_STEPS}
            currentStep={currentStep}
            stepsCompleted={stepsCompleted}
            onClick={changeCurrentStep}
          />
          <Dimmer.Dimmable>
            <Dimmer active={isDimmerActive} inverted>
              <Loader />
            </Dimmer>
            <div className="jobPost-form-fields">{step}</div>
          </Dimmer.Dimmable>
        </div>
      )}
    </FormWrapper>
  );
}

export default JobPost;
