export const FILE_UPLOAD = `${process.env.REACT_APP_API_BASE_URL}api/jobs/upload-file`;
export const GET_ALL_JOB_POSTS = `${process.env.REACT_APP_API_BASE_URL}api/jobs/posts`;
export const GET_DASHBOARD_JOB_POSTS = `${process.env.REACT_APP_API_BASE_URL}api/jobs/dashboard/posts`;
export const GET_CLIENT_JOB_POSTS = `${process.env.REACT_APP_API_BASE_URL}api/jobs/client/posts`;
export const GET_JOB_POST = `${process.env.REACT_APP_API_BASE_URL}api/jobs/posts/:id`;
export const SUBMIT_NEW_JOB = `${process.env.REACT_APP_API_BASE_URL}api/jobs/new`;
export const DEACTIVATE_JOB = `${process.env.REACT_APP_API_BASE_URL}api/jobs/deactivate`;

export const SUBMIT_JOB_PROPOSAL = `${process.env.REACT_APP_API_BASE_URL}api/proposal/new`;
export const GET_SUBMITTED_PROPOSAL = `${process.env.REACT_APP_API_BASE_URL}api/proposal/all`;
export const GET_JOB_PROPOSALS = `${process.env.REACT_APP_API_BASE_URL}api/proposal/:jobId/proposals`;
export const ACCEPT_REJECT_PROPOSALS = `${process.env.REACT_APP_API_BASE_URL}api/proposal/:proposalId/`;
export const GET_SPECIFIC_PROPOSAL = `${process.env.REACT_APP_API_BASE_URL}api/proposal/getById/:id`;

export const SUBMIT_NEW_CONTRACT = `${process.env.REACT_APP_API_BASE_URL}api/contract/new`;
export const GET_CONTRACT = `${process.env.REACT_APP_API_BASE_URL}api/contract/posts/:id`;
export const UPDATE_CONTRACT = `${process.env.REACT_APP_API_BASE_URL}api/contract/posts/:id`;
export const ACCEPT_CONTRACT = `${process.env.REACT_APP_API_BASE_URL}api/contract/posts/:id/accept`;
export const WITHDRAW_CONTRACT = `${process.env.REACT_APP_API_BASE_URL}api/contract/posts/:id/withdraw`;
export const GET_ALL_CONTRACTS = `${process.env.REACT_APP_API_BASE_URL}api/contract/posts`;
export const RAISE_DISPUTE = `${process.env.REACT_APP_API_BASE_URL}api/contract/:id/raise-dispute`;

export const GET_ALL_CATEGORIES = `${process.env.REACT_APP_API_BASE_URL}api/misc/category/get/all`;
export const GET_ALL_SKILLS = `${process.env.REACT_APP_API_BASE_URL}api/misc/skills/get/all`;
