const initialState = {
  messages: [],
  pane: []
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case "GET_ALL_MESSAGES":
      return { ...state, messages: action.data };
    
    case "GET_ALL_DIALOGS":
      return { ...state, pane: action.data };

    default:
      return state;
  }
}
