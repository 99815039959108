import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Loader, Pagination } from "semantic-ui-react";
import { ToastContainer, toast } from "react-toastify";

import ROUTES from "constants/Routes";
import docIcon from "static/icons/docicon.png";

import FormWrapper from "components/FormWrapper";
import { calculateTotalExperience } from "utils";
import ProfileImagePlaceholder from "static/images/profile-image-placeholder.png";

import { stateToProps } from "./maps";
import { PAGE_SIZE } from "./constants";
import { getTopFreelancers, getAllFreelancers } from "./actions";

import "./ViewFreelancer.scss";

function ViewFreelancer() {
  const history = useHistory();
  const { isLoggedIn } = useSelector(stateToProps);

  const [freelancers, setFreelancers] = React.useState([]);
  const [isFetching, setIsFetching] = React.useState(false);
  const [activePage, setActivePage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1);
  const [pageHasError, setPageHasError] = React.useState(false);

  const getFreelancers = React.useCallback(() => {
    const query = {
      currentPage: activePage,
      pageSize: PAGE_SIZE,
    };

    setIsFetching(true);

    getAllFreelancers(query)
      .then((response) => {
        setIsFetching(false);
        setFreelancers(response.data.freelancers);
        setTotalPages(response.data.totalPages);
      })
      .catch((error) => {
        try {
          toast.error(
            `Fetching freelancers failed! ${error.response.data.error}`
          );
        } catch (e) {
          toast.error(`Fetching freelancers failed! Please try again`);
        }

        setIsFetching(false);
      });
  }, [activePage]);

  React.useEffect(() => {
    if (isLoggedIn) {
      getFreelancers();
      return;
    }
    
    setIsFetching(true);
    getTopFreelancers()
      .then((response) => {
        setIsFetching(false);
        setFreelancers(response.data.freelancers);
      })
      .catch(() => {
        setIsFetching(false);
        setPageHasError(true);
      });
  }, [isLoggedIn, getFreelancers]);

  const handlePageChange = React.useCallback((_, data) => {
    const { activePage } = data;
    setActivePage(activePage);
  }, []);

  return (
    <FormWrapper
      heading="Freelancers"
      subHeading="Hire best in class freelancers"
      customClassName="viewFreelancers-paddingLeft"
    >
      <Loader active={isFetching} />
      <ToastContainer
        className="toast"
        position="bottom-center"
        autoClose={2500}
        hideProgressBar
      />
      <div className="viewFreelancers">
        {freelancers.map((freelancer) => {
          const { years, months } = calculateTotalExperience(
            freelancer.experience
          );

          return (
            <div
              className="viewFreelancers-card"
              onClick={() =>
                history.push(
                  ROUTES.VIEW_SPECIFIC_FREELANCER.replace(
                    ":id",
                    freelancer.userId._id
                  )
                )
              }
            >
              <div className="viewFreelancers-card-image">
                <img
                  src={freelancer.userId?.imageUrl || ProfileImagePlaceholder}
                  alt="Profile"
                />
              </div>
              <div className="viewFreelancers-card-personal">
                <h4 className="viewFreelancers-card-personal-name">
                  {freelancer.userId.name}
                </h4>
                <p className="viewFreelancers-card-personal-title">
                  {freelancer.title}
                </p>
                <p className="viewFreelancers-card-personal-experience">
                  {`${years}.${months}+ years of experience`}
                </p>
                <div className="viewFreelancers-card-personal-skills">
                  {freelancer.skills.slice(0, 3).map(({ skill }) => (
                    <span className="viewFreelancers-card-personal-skills-item">
                      {skill}
                    </span>
                  ))}
                </div>
              </div>
              <div className="viewFreelancers-card-portfolio">
                <p className="viewFreelancers-card-portfolio-heading">
                  Portfolio
                </p>
                <div className="viewFreelancers-card-portfolio-wrapper">
                  {freelancer.portfolio.map(
                    ({ description, title, toolsUsed, documents }) => (
                      <div className="viewFreelancers-card-portfolio-item">
                        <p className="viewFreelancers-card-portfolio-item-title">
                          {title}
                        </p>
                        {!!toolsUsed.length && (
                          <>
                            <p className="viewFreelancers-card-portfolio-item-tools-heading">
                              Tools used:
                            </p>
                            <div className="viewFreelancers-card-portfolio-item-tools">
                              <p className="viewFreelancers-card-portfolio-item-tools-name">
                                {toolsUsed.slice(0, 3).join(", ")}
                              </p>
                            </div>
                          </>
                        )}
                        {!!documents.length && (
                          <div className="viewFreelancers-card-portfolio-item-files">
                            <img
                              className="viewFreelancers-card-portfolio-item-files-img"
                              src={docIcon}
                              alt="File"
                            />
                            <div className="viewFreelancers-card-portfolio-item-files-text">
                              Files inside
                            </div>
                          </div>
                        )}
                        <div></div>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {isLoggedIn && (
          <Pagination
            className="jobList-pagination"
            siblingRange={1}
            firstItem={null}
            lastItem={null}
            activePage={activePage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        )}
      </div>
    </FormWrapper>
  );
}

export default ViewFreelancer;
