import React from "react";
import PropTypes from "prop-types";
import { ToastContainer, toast } from "react-toastify";
import {
  Modal,
  Segment,
  TextArea,
  Input,
  Button,
  Dropdown,
  Header,
  Icon,
} from "semantic-ui-react";
import RichTextEditor from "react-rte";

import { getFileIcon } from "utils";
import { MAX_FILE_SIZE } from "constants/Job";
import { uploadFile } from "containers/Settings/actions";
import { TOOLS, TOOLBAR_CONFIG } from "containers/Settings/constants";

import "./Step4.scss";
function Step4({
  portfolio,
  onChange,
  handlePreviousStep,
  handleSubmitForm,
  isSubmitting,
}) {
  const [portfolioProfile, setPortfolioProfile] = React.useState(portfolio);
  const [isUploading, setIsUploading] = React.useState(false);
  const [showModal, SetShowModal] = React.useState(false);
  const [modalIndex, setModalIndex] = React.useState(-1);
  const [selectedDoc, setSelectedDoc] = React.useState([]);
  const [docDescription, setDocDiscription] = React.useState("");
  const fileInputRef = React.createRef();

  const handleOnChange = React.useCallback(
    (data) => {
      const profiles = [...portfolioProfile];

      profiles[data.index] = {
        ...profiles[data.index],
        ...data.changes,
      };

      setPortfolioProfile(profiles);
      onChange("portfolioDetails", profiles);
    },
    [portfolioProfile, onChange]
  );

  //upload Document
  const uploadDocument = React.useCallback(
    async (e) => {
      if (!selectedDoc.length) {
        toast.error("Please select a valid file.");
        return;
      }

      if (
        !!Object.keys(selectedDoc).filter(
          (docKey) => selectedDoc[docKey].size > MAX_FILE_SIZE
        ).length
      ) {
        toast.error(`File size cannot be greater than 10mb`);
        return;
      }

      if (modalIndex === -1) {
        toast.error("Internal error occurred! Please try again.");
        return;
      }

      setIsUploading(true);
      uploadFile(selectedDoc)
        .then((response) => {
          setIsUploading(false);
          toast.success("Upload completed!");

          const existingDocuments = Array.isArray(
            portfolioProfile[modalIndex]?.documents
          )
            ? portfolioProfile[modalIndex].documents
            : [];
          const newDocuments = [
            ...existingDocuments,
            ...response.data.files.map((file) => ({
              title: file.originalname,
              url: file.location,
            })),
          ];

          handleOnChange({
            index: modalIndex,
            changes: {
              documents: newDocuments,
            },
          });
        })
        .catch((error) => {
          setIsUploading(false);
          console.error(error);
          toast.error("Uploading failed!");
        });
    },
    [portfolioProfile, selectedDoc, handleOnChange, modalIndex]
  );

  const handleAddMore = React.useCallback(() => {
    const newProfiles = [...portfolioProfile, {}];

    onChange("portfolioDetails", newProfiles);
    setPortfolioProfile(newProfiles);
  }, [portfolioProfile, onChange]);

  const handleRemoveDocument = React.useCallback(
    (itemIndex, documentIndex) => {
      const portfolioArr = [...portfolioProfile];
      const newProfiles = portfolioArr.map((item, index) =>
        itemIndex === index
          ? {
              ...item,
              documents: item.documents.filter(
                (_, dIndex) => documentIndex !== dIndex
              ),
            }
          : item
      );

      setPortfolioProfile(newProfiles);
      onChange("portfolioDetails", newProfiles);
    },
    [portfolioProfile, onChange]
  );

  const handleDelete = React.useCallback((index) => {
    setPortfolioProfile((profiles) =>
      profiles.filter((value, i) => index !== i)
    );
  }, []);

  return (
    <div className="profileStep1-wrapper">
      <ToastContainer
        className="toast"
        position="bottom-center"
        autoClose={2500}
        hideProgressBar
      />
      <Modal
        open={showModal}
        onClose={() => {
          setSelectedDoc([]);
          SetShowModal(!showModal);
          setModalIndex(-1);
        }}
        closeIcon
      >
        <Modal.Header>Upload Media</Modal.Header>
        <Modal.Content>
          <label>Select Document (max filesize 10 MB)</label>
          <Segment placeholder>
            <Header icon>
              {Object.keys(selectedDoc).length ? (
                Object.keys(selectedDoc).map((docKey) => (
                  <p>{selectedDoc[docKey].name}</p>
                ))
              ) : (
                <p>No media selected.</p>
              )}
            </Header>
            <Button
              primary
              content="Choose Media"
              labelPosition="left"
              icon="file"
              onClick={() => fileInputRef.current.click()}
            />
            <input
              ref={fileInputRef}
              type="file"
              hidden
              multiple
              onChange={(e) => setSelectedDoc(e.target.files)}
            />
          </Segment>

          <div className="item">
            <label>Summary</label>
            <TextArea
              className="textArea"
              rows="2"
              placeholder="Description for document"
              value={docDescription}
              onChange={(e) => setDocDiscription(e.target.value)}
            ></TextArea>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={() => uploadDocument()}
            disabled={isUploading}
            primary
          >
            {isUploading ? "Uploading..." : "Upload"}
          </Button>
        </Modal.Actions>
      </Modal>
      <div className="Step4-box">
        <div className="myProfile-education-item-heading">
          Portfolio details
        </div>
        <div className="step4-subtitle">
          Atleast 2 portfolio items are required to apply for a job
        </div>
        {portfolioProfile.map((portfolioData, i) => {
          let editorValue = portfolioData.description;

          if (typeof portfolioData.description === "string") {
            if (!portfolioData.description) {
              editorValue = RichTextEditor.createEmptyValue();
            } else {
              editorValue = RichTextEditor.createValueFromString(
                portfolioData.description,
                "html"
              );
            }
          } else if (editorValue === undefined) {
            editorValue = RichTextEditor.createEmptyValue();
          } else {
            editorValue = portfolioData.description;
          }

          return (
            <div key={`protoflio-${i}`} className="myProfile-education-item">
              <div className="myProfile-education-item-heading"># {i + 1}</div>
              <div className="field">
                <div className="field">
                  <label>Title</label>
                  <Input
                    name="title"
                    placeholder="Enter a title"
                    style={{ width: "100%" }}
                    value={portfolioData.title}
                    onChange={(e) =>
                      handleOnChange({
                        index: i,
                        changes: { title: e.target.value },
                      })
                    }
                  />
                </div>
                <div className="field">
                  <label>Documents</label>
                  <div className="item">
                    {portfolioData.documents &&
                      portfolioData.documents.length !== 0 && (
                        <div
                          style={{ flexDirection: "column", display: "flex" }}
                        >
                          {portfolioData.documents.map((item, index) => {
                            const extension = item.title;
                            const split = extension.split(".");
                            return (
                              <div
                                key={index}
                                style={{
                                  padding: "5px",
                                  display: "flex",
                                }}
                              >
                                <img
                                  src={getFileIcon(split[1])}
                                  alt="document"
                                  style={{ height: "30px", width: "auto" }}
                                />
                                <p style={{ fontSize: 10, lineHeight: "30px" }}>
                                  {split[0].length > 50
                                    ? `${split[0].substring(0, 50)}...`
                                    : split[0]}
                                </p>
                                <Icon
                                  className="myProfile-portfolio-document-remove"
                                  name="close"
                                  onClick={() => handleRemoveDocument(i, index)}
                                />
                              </div>
                            );
                          })}
                        </div>
                      )}
                  </div>
                  <Button
                    className="myProfile-portfolio-addMedia"
                    onClick={() => {
                      SetShowModal(!showModal);
                      setModalIndex(i);
                    }}
                    primary
                  >
                    Add Media
                  </Button>
                </div>
                <div className="field row">
                  <div
                    className="field"
                    style={{ flex: "1", marginRight: "28px" }}
                  >
                    <label>Category</label>
                    <Dropdown
                      options={[
                        { text: "Dummy 1", value: "dummy-1" },
                        { text: "Dummy 2", value: "dummy-2" },
                      ]}
                      name="category"
                      value={portfolioData.category || ""}
                      onChange={(e, data) =>
                        handleOnChange({
                          index: i,
                          changes: { category: data.value },
                        })
                      }
                      selection
                    />
                  </div>
                  <div className="field" style={{ flex: "1" }}>
                    <label>Tools Used</label>
                    <Dropdown
                      options={TOOLS}
                      name="toolsUsed"
                      value={portfolioData.toolsUsed || []}
                      onChange={(e, data) =>
                        handleOnChange({
                          index: i,
                          changes: { toolsUsed: data.value },
                        })
                      }
                      search
                      selection
                      multiple
                    />
                  </div>
                </div>
                <div className="summary-field">
                  <label>Summary</label>
                  <RichTextEditor
                    name="description"
                    placeholder="Portfolio description"
                    toolbarConfig={TOOLBAR_CONFIG}
                    value={editorValue}
                    onChange={(value) =>
                      handleOnChange({
                        index: i,
                        changes: { description: value },
                      })
                    }
                  />
                </div>
                {i !== 0 && (
                  <div className="field no-margin at-bottom">
                    <Button
                      className="alternative warning"
                      onClick={() => handleDelete(i)}
                    >
                      Delete
                    </Button>
                  </div>
                )}
              </div>
              {i !== portfolioProfile.length - 1 && (
                <div className="line-separator"></div>
              )}
            </div>
          );
        })}
        <div
          className="myProfile-addmore step4-margin-top-0"
          onClick={handleAddMore}
        >
          <h4>+ Add more</h4>
        </div>
      </div>
      <div className="step3-buttons">
        <Button className="alternative" onClick={handlePreviousStep}>
          Previous
        </Button>
        <Button
          className="primary"
          onClick={handleSubmitForm}
          loading={isSubmitting}
        >
          Submit
        </Button>
      </div>
    </div>
  );
}

Step4.propTypes = {
  onChange: PropTypes.func.isRequired,
  handlePreviousStep: PropTypes.func.isRequired,
  handleSubmitForm: PropTypes.func.isRequired,
};

export default Step4;
