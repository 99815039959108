export const SET_PROFILE = "profile.SET_PROFILE";
export const SET_PROFILE_TITLE = "profile.SET_PROFILE_TITLE";
export const SET_PROFILE_DESCRIPTION = "profile.SET_PROFILE_DESCRIPTION";
export const SET_PROFILE_SKILLS = "profile.SET_PROFILE_SKILLS";

export function setProfileData(profile = {}) {
  return (dispatch) => {
    dispatch({
      type: SET_PROFILE,
      data: profile,
    });
  };
}
