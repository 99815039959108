import React from "react";
import { useHistory } from 'react-router-dom'

import "./Banner.scss";

function Banner() {
  const history = useHistory();
  return (
    <div className="banner">
      <div className="banner-wrapper">
        <div className="banner-wrapper-content">
          <h1>
            Onboard <span>background checked</span> freelancers or agencies
          </h1>
          <p>
            Quickly. Calculatively. Intelligently.
          </p>
          <div className="banner-wrapper-content-actions">
            <button className="primary" onClick={() => history.push("/signup")}>Get Started</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Banner;
