import React from "react";
import PropTypes from "prop-types";

import "./Auth.scss";

function Auth({ children }) {
  return (
    <div className="auth">
      <div className="auth-wrapper">
        <div className="auth-fields">{children}</div>
      </div>
    </div>
  );
}

Auth.propTypes = {
  children: PropTypes.node,
};

Auth.defaultProps = {
  children: null,
};

export default Auth;
