import { bindActionCreators } from "redux";
import { getIsLoggedIn } from "selectors/userSelector";
import { getCategory } from "selectors/categorySelector";
import {
  getSubCategories,
  setSubCategories,
  getSubSubCategories,
  setSubSubCategories,
} from "actions/category";

export const stateToProps = (state) => {
  return {
    isLoggedIn: getIsLoggedIn(state),
    category: getCategory(state),
  };
};

export const dispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getSubCategories,
      getSubSubCategories,
      setSubCategories,
      setSubSubCategories,
    },
    dispatch
  );
};
