import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Label } from "semantic-ui-react";

import ROUTES from "constants/Routes";
import { getJobStatusColor, getJobStatus } from "utils/job";

import SimpleCard from "components/SimpleCard";

import "./MyJobs.scss";

function MyJobs({ jobs }) {
  const haveJobs = Array.isArray(jobs) && jobs.length;
  return (
    <SimpleCard heading="Your Job posts">
      <SimpleCard.Body>
        <div className="findWork-jobs">
          {haveJobs ? (
            jobs.map((job) => {
              const createdDate = new Date(job.createdAt);

              return (
                <Link to={`${ROUTES.JOB_POST_DETAIL.replace(":id", job._id)}`}>
                  <div className="myJobs-job">
                    <p className="myJobs-job-created">
                      Created on
                      <br />
                      {`${createdDate.toDateString()} ${createdDate.getHours()}:${createdDate.getMinutes()}:${createdDate.getSeconds()}`}
                    </p>
                    <h4 className="myJobs-job-title">{job.title}</h4>
                    <p className="myJobs-job-status">
                      <Label color={getJobStatusColor(job.status, job.active)}>
                        {getJobStatus(job.status, job.isApproved, job.active)}
                      </Label>
                    </p>
                  </div>
                </Link>
              );
            })
          ) : (
            <div>Looks like you're yet to create a job post!</div>
          )}
        </div>
      </SimpleCard.Body>
    </SimpleCard>
  );
}

MyJobs.propTypes = {
  jobs: PropTypes.arrayOf(PropTypes.shape({})),
};

export default MyJobs;
