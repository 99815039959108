import { bindActionCreators } from "redux";

import { setUserMeta } from "actions/auth";
import {
  getUserId,
  getIsClient,
  getIsProfileSetupDone,
} from "selectors/userSelector";

export const stateToProps = (state) => {
  return {
    userId: getUserId(state),
    isClient: getIsClient(state),
    isProfileSetupDone: getIsProfileSetupDone(state),
  };
};

export const dispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setUserMeta,
    },
    dispatch
  );
};
