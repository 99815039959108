import {
  JOB_DURATION,
  BUDGET_RANGE,
} from "containers/JobPost/constants";

export const FILTER_TYPES = {
  CHECKBOX: "checkbox",
  SELECT: "select",
};

export const FILTER_BY_FILTERS = [
  {
    id: "filter-by-1",
    title: "Experience Level",
    key: "experienceLevel",
    type: FILTER_TYPES.CHECKBOX,
    options: [
      { id: "experience-level-1", text: "Entry", value: "entry" },
      {
        id: "experience-level-2",
        text: "Intermediate",
        value: "intermediate",
      },
      { id: "experience-level-3", text: "Expert", value: "expert" },
    ],
  },
  {
    id: "filter-by-2",
    title: "Project Length",
    key: "projectLengthSelected",
    type: FILTER_TYPES.CHECKBOX,
    options: JOB_DURATION,
  },
  {
    id: "filter-by-3",
    title: "Budget",
    key: "budgetSelected",
    type: FILTER_TYPES.CHECKBOX,
    options: BUDGET_RANGE,
  },
  {
    id: "filter-by-4",
    title: "Cateogory",
    key: "categoriesSelected",
    type: FILTER_TYPES.SELECT,
    isMultiple: true,
  },
];

export const PAGE_SIZE = 10;
