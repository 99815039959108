import React from "react";
import { Link } from "react-router-dom";

import { FOOTER_LINKS, FOOTER_SOCIAL_LINKS } from "./constants";
import "./Footer.scss";

function Footer() {
  return (
    <div className="footer">
      <div className="footer-wrapper">
        <div className="footer-column">
          <h4 className="footer-column-title">Company</h4>
          <div className="footer-column-link">
            {FOOTER_LINKS.company.map((link) => (
              <Link
                className="footer-column-link-item"
                key={link.id}
                to={link.url}
              >
                {link.title}
              </Link>
            ))}
          </div>
        </div>
        <div className="footer-column">
          <h4 className="footer-column-title">Explore More</h4>
          <div className="footer-column-link">
            {FOOTER_LINKS.exploreMore.map((link) => (
              <Link
                className="footer-column-link-item"
                key={link.id}
                to={link.url}
              >
                {link.title}
              </Link>
            ))}
          </div>
        </div>
        <div className="footer-column-extended">
          <h4 className="footer-column-title">ExpertRight</h4>
          <div className="footer-column-address">
            <div className="footer-column-address-item">
              <div className="footer-column-address-heading">Address:</div>
              <div className="footer-column-address-answer">
                G-2, 589, Surya Nagar,Jaipur - 302019, Rajasthan
              </div>
            </div>
            <div className="footer-column-address-item">
              <div className="footer-column-address-heading">Phone:</div>
              <div className="footer-column-address-answer">
                <a href="tel:+919460068385">+91-9460068385</a>
                <a href="tel:+919358359378">+91-9358359378</a>
              </div>
            </div>
            <div className="footer-column-address-item">
              <div className="footer-column-address-heading">Email:</div>
              <div className="footer-column-address-answer">
                <a href="mailto:support@expertright.com">
                  support@expertright.com
                </a>
              </div>
            </div>
            <div className="footer-column-address-item">
              <div className="footer-column-address-heading">Follow us:</div>
              <div className="footer-column-address-answer">
                {FOOTER_SOCIAL_LINKS.map((link) => (
                  <a
                    key={link.id}
                    href={link.url}
                    className="social"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={link.icon} alt={link.text} />
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
