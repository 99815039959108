import React from "react";
import { useHistory } from 'react-router-dom'

import PostJob from "static/images/postJob.png";
import "./Post.scss";

function Post() {
  const history = useHistory();
  return (
    <div className="postJob">
      <div className="postJob-wrapper">
        <div className="postJob-image">
          <img src={PostJob} alt="" />
        </div>
        <div className="postJob-content">
          <h2 className="postJob-content-title">Get Anything Done</h2>
          <p className="postJob-content-subHeading">
            No matter what you want to be done, simply post your requirement and
            hire expert freelancers or agencies right away. Manage your projects
            with ease using our inbuild project management tool.
          </p>
          <button className="primary postJob-content-button" onClick={() => history.push("/signup")}>Post a Job</button>
        </div>
      </div>
    </div>
  );
}

export default Post;
