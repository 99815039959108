import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
  Input,
  TextArea,
  Button,
  Select,
  Checkbox,
  Dropdown,
  Icon,
  Modal,
  Segment,
  Header,
  Dimmer,
  Loader,
} from "semantic-ui-react";
import RichTextEditor from "react-rte";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { MAX_FILE_SIZE } from "constants/Job";
import {
  YEAR_OPTIONS,
  MONTH_OPTIONS,
  DUMMY_COUNTRY_LIST,
} from "constants/Options";
import { TOOLS } from "containers/Settings/constants";
import { uploadFile } from "containers/Settings/actions";
import Section from "components/Section";
import { getFileIcon, calculateExperience } from "utils";
import ProfileImagePlaceholder from "static/images/profile-image-placeholder.png";

import { saveProfileData, imageData } from "../../actions/index";
import { stateToProps } from "../../maps";
import { getAllSkills } from "../../actions/index";
import { TOOLBAR_CONFIG } from "../../constants";
import "./MyProfile.scss";

const EMPTY_EXPERIENCE_OBJECT = {
  title: "",
  company: "",
  startMonth: "",
  startYear: "",
  isCurrent: false,
  summary: "",
};

const EMPTY_EDUCATION_OBJECT = {
  country: "",
  degreeName: "",
  schoolName: "",
  startDate: "",
  endDate: "",
  isCurrent: false,
};

const EMPTY_PORTFOLIO_OBJECT = {
  title: "",
  description: "",
  documents: [],
  toolsUsed: [],
};

function MyProfile({
  title,
  description,
  skills,
  imageUrl,
  education,
  experience,
  portfolio,
  setProfileData,
}) {
  const { userId } = useSelector(stateToProps);

  const [skillsOption, setskillsOption] = React.useState([]);
  const [editedEducationData, setEditedEducationData] = React.useState([]);
  const [editedExperienceData, setEditedExperienceData] = React.useState([]);
  const [editedPortfolioData, setEditedPortfolioData] = React.useState([]);
  const [editedSkillData, setEditedSkillData] = React.useState([]);
  const [isSkillDataEdited, setIsSkillDataEdited] = React.useState(false);
  const [editedContactData, setEditedContactData] = React.useState({});
  const [isContactInfoSubmitting, setIsContactInfoSubmitting] =
    React.useState(false);
  const [isUploading, setIsUploading] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [selectedDoc, setSelectedDoc] = React.useState([]);
  const [modalIndex, setModalIndex] = React.useState(-1);
  const [docDescription, setDocDiscription] = React.useState("");
  const [isDimmerActive, setIsDimmerActive] = React.useState(false);
  const fileInputRef = React.createRef();

  const resetContactData = React.useCallback(
    () => setEditedContactData({}),
    [setEditedContactData]
  );
  const resetSkillData = React.useCallback(() => {
    setIsSkillDataEdited(false);
    setEditedSkillData([]);
  }, []);
  const resetExperienceData = React.useCallback(
    () => setEditedExperienceData([]),
    []
  );
  const resetEducationData = React.useCallback(
    () => setEditedEducationData([]),
    []
  );
  const resetPortfolioData = React.useCallback(
    () => setEditedPortfolioData([]),
    []
  );

  const experienceValue = editedExperienceData.length
    ? editedExperienceData
    : experience;

  const educationValue = editedEducationData.length
    ? editedEducationData
    : Array.isArray(education) && education.length
    ? education
    : [{ ...EMPTY_EDUCATION_OBJECT }];

  const portfolioValue = editedPortfolioData.length
    ? editedPortfolioData
    : Array.isArray(portfolio) && portfolio.length
    ? portfolio
    : [{ ...EMPTY_PORTFOLIO_OBJECT }];

  const handleOnExperienceEdit = React.useCallback(
    (editOn) => {
      if (editOn && !experience.length) {
        setEditedExperienceData([{ ...EMPTY_EXPERIENCE_OBJECT }]);
      }
    },
    [experience.length]
  );

  const addMoreExperience = React.useCallback(() => {
    if (!editedExperienceData.length) {
      setEditedExperienceData([...experience, { ...EMPTY_EXPERIENCE_OBJECT }]);
    } else {
      setEditedExperienceData([
        ...editedExperienceData,
        { ...EMPTY_EXPERIENCE_OBJECT },
      ]);
    }
  }, [editedExperienceData, experience]);

  const handleDeleteExperience = React.useCallback(
    (i) => {
      const newExperience = editedExperienceData.length
        ? [...editedExperienceData]
        : [...experience];
      setEditedExperienceData(newExperience.filter((_, index) => i !== index));
    },
    [editedExperienceData, experience]
  );

  const addMoreEducation = React.useCallback(() => {
    const newEducation = [...educationValue, { ...EMPTY_EDUCATION_OBJECT }];
    setEditedEducationData(newEducation);
  }, [educationValue]);

  const handleDeleteEducation = React.useCallback(
    (i) => {
      const newEducation = editedEducationData.length
        ? [...editedEducationData]
        : [...education];

      setEditedEducationData(newEducation.filter((_, index) => i !== index));
    },
    [editedEducationData, education]
  );

  const addMorePortfolio = React.useCallback(() => {
    const newPortfolio = [...portfolioValue, { ...EMPTY_PORTFOLIO_OBJECT }];
    setEditedPortfolioData(newPortfolio);
  }, [portfolioValue]);

  const handleDeletePortfolio = React.useCallback(
    (i) => {
      const newPortfolio = [...portfolioValue];
      setEditedPortfolioData(newPortfolio.filter((_, index) => index !== i));
    },
    [portfolioValue]
  );

  const handleOnChange = React.useCallback(
    (e, inputData) => {
      const { name, value } = inputData;
      setEditedContactData({ ...editedContactData, [name]: value });
    },
    [editedContactData]
  );

  const handleOnChangeImage = React.useCallback(
    (event) => {
      setIsDimmerActive(true);

      // Create an object of formData
      const formData = new FormData();
      // Update the formData object
      formData.append(
        "file",
        event.target.files[0],
        event.target.files[0].name
      );

      imageData(userId, formData)
        .then((resp) => {
          setIsDimmerActive(false);
          setProfileData({ imageUrl: resp.data.location });
          toast.success("Image uploaded successfully!");
        })
        .catch((error) => {
          setIsDimmerActive(false);
          toast.error("Image upload failed! Please try again.");
          console.error(error);
        });
    },
    [setProfileData, userId]
  );

  const handleOnChangeform = React.useCallback(
    (e, i, inputData) => {
      const { name, value, checked } = inputData;
      const experienceArr = !editedExperienceData.length
        ? [...experience]
        : [...editedExperienceData];

      setEditedExperienceData(
        experienceArr.map((item, index) =>
          i === index ? { ...item, [name]: value || checked } : item
        )
      );
    },
    [editedExperienceData, experience]
  );

  const handleOnChangeEducation = React.useCallback(
    (e, i, inputData) => {
      const { name, value, checked } = inputData;
      const educationArr = [...educationValue];
      setEditedEducationData(
        educationArr.map((item, index) =>
          i === index ? { ...item, [name]: value || checked } : item
        )
      );
    },
    [educationValue]
  );

  const handleOnChangePortfolio = React.useCallback(
    (e, i, inputData) => {
      const { name, value } = inputData;
      const portfolioArr = [...portfolioValue];
      setEditedPortfolioData(
        portfolioArr.map((item, index) =>
          i === index ? { ...item, [name]: value } : item
        )
      );
    },
    [portfolioValue]
  );

  const handleRemoveDocument = React.useCallback(
    (itemIndex, documentIndex) => {
      const portfolioArr = [...portfolioValue];
      setEditedPortfolioData(
        portfolioArr.map((item, index) =>
          itemIndex === index
            ? {
                ...item,
                documents: item.documents.filter(
                  (_, dIndex) => documentIndex !== dIndex
                ),
              }
            : item
        )
      );
    },
    [portfolioValue]
  );

  const handleOnChangeforList = (e) => {
    setIsSkillDataEdited(true);
    setEditedSkillData(e);
  };

  const titleValue = Object.prototype.hasOwnProperty.call(
    editedContactData,
    "title"
  )
    ? editedContactData["title"]
    : title;

  const skillsValue = isSkillDataEdited
    ? editedSkillData
    : skills.map(({ _id }) => _id);

  const descValue = Object.prototype.hasOwnProperty.call(
    editedContactData,
    "summary"
  )
    ? editedContactData["summary"]
    : description;

  const update = React.useCallback(
    (cb) => {
      if (!Object.keys(editedContactData).length) {
        toast.error("Nothing to save!");
        cb();
        return;
      }

      setIsContactInfoSubmitting(true);
      saveProfileData(userId, {
        title: editedContactData.title,
        description: editedContactData.summary,
        action: "updateIntroductionInfo",
      })
        .then(() => {
          setIsContactInfoSubmitting(false);
          setProfileData({
            title: editedContactData.title,
            description: editedContactData.summary,
          });
          cb();
          toast.success("Successfully saved!");
        })
        .catch((error) => {
          setIsContactInfoSubmitting(false);
          toast.error("Updated failed! Please try again.");
          console.error(error);
        });
    },
    [editedContactData, setProfileData, userId]
  );

  const updateSkill = React.useCallback(
    (cb) => {
      if (!editedSkillData.length) {
        toast.error(
          isSkillDataEdited ? "Add atleast one skill" : "Nothing to save"
        );
        return;
      }

      setIsContactInfoSubmitting(true);
      saveProfileData(userId, {
        skills: editedSkillData,
        action: "updateSkillsInfo",
      })
        .then(() => {
          setIsSkillDataEdited(false);
          setIsContactInfoSubmitting(false);
          setProfileData({
            skills: editedSkillData.map((skill) => ({
              _id: skill,
            })),
          });
          resetSkillData();
          cb();

          toast.success("Successfully saved!");
        })
        .catch((error) => {
          setIsContactInfoSubmitting(false);
          toast.error("Update failed! Please try again.");
          console.error(error);
        });
    },
    [isSkillDataEdited, editedSkillData, userId, setProfileData, resetSkillData]
  );

  const updateExperience = React.useCallback(
    (cb) => {
      if (!editedExperienceData.length) {
        toast.error(
          !experience.length ? "Add atleast one experience!" : "Nothing to save"
        );
        return;
      }

      let dateError = false;
      editedExperienceData.forEach((e) =>
        calculateExperience(e).diff <= 0 ? (dateError = true) : null
      );

      if (dateError) {
        toast.error(
          "Please ensure end date is not earlier/same as the start date"
        );
        return;
      }

      setIsContactInfoSubmitting(true);
      let data = {
        experience: editedExperienceData,
        action: "updateExperienceInfo",
      };
      saveProfileData(userId, data)
        .then((resp) => {
          setIsContactInfoSubmitting(false);
          setProfileData({
            experience: editedExperienceData,
          });

          cb();
          toast.success("Successfully saved!");
        })
        .catch((error) => {
          setIsContactInfoSubmitting(false);
          toast.error("Update failed! Please try again.");
          console.error(error);
        });
    },
    [experience.length, editedExperienceData, setProfileData, userId]
  );

  const updateEducation = React.useCallback(
    (cb) => {
      if (!editedEducationData.length) {
        toast.error(
          !education.length ? "Add atleast one education" : "Nothing to save!"
        );
        return;
      }

      setIsContactInfoSubmitting(true);
      saveProfileData(userId, {
        education: editedEducationData,
        action: "updateEducationInfo",
      })
        .then(() => {
          setIsContactInfoSubmitting(false);
          setProfileData({
            education: editedEducationData,
          });

          cb();
          toast.success("Successfully saved!");
        })
        .catch((error) => {
          setIsContactInfoSubmitting(false);
          toast.error("Update failed! Please try again.");
          console.error(error);
        });
    },
    [education.length, editedEducationData, setProfileData, userId]
  );

  const updatePortfolio = React.useCallback(
    (cb) => {
      if (!editedPortfolioData.length) {
        toast.error(
          !portfolio.length
            ? "Add atleast one portfolio item"
            : "Nothing to save!"
        );
        return;
      }

      setIsContactInfoSubmitting(true);

      const portfolioWithStrings = editedPortfolioData.map((p) => ({
        ...p,
        description: p.description
          ? typeof p.description === "string"
            ? p.description
            : p.description.toString("html")
          : "",
      }));

      saveProfileData(userId, {
        portfolio: portfolioWithStrings,
        action: "updatePortfolioInfo",
      })
        .then(() => {
          setIsContactInfoSubmitting(false);
          setProfileData({
            portfolio: portfolioWithStrings,
          });
          setEditedPortfolioData(portfolioWithStrings);

          cb();
          toast.success("Successfully saved!");
        })
        .catch((error) => {
          setIsContactInfoSubmitting(false);
          toast.error("Update failed! Please try again.");
          console.error(error);
        });
    },
    [portfolio.length, editedPortfolioData, userId, setProfileData]
  );

  //upload Document
  const uploadDocument = React.useCallback(
    async (e) => {
      if (!selectedDoc.length) {
        toast.error("Please select a valid file.");
        return;
      }

      if (
        !!Object.keys(selectedDoc).filter(
          (docKey) => selectedDoc[docKey].size > MAX_FILE_SIZE
        ).length
      ) {
        toast.error(`File size cannot be greater than 10mb`);
        return;
      }

      if (modalIndex === -1) {
        toast.error("Internal error occurred! Please try again.");
        return;
      }

      setIsUploading(true);
      uploadFile(selectedDoc)
        .then((response) => {
          setIsUploading(false);
          toast.success("Upload completed!");

          const newDocuments = [
            ...portfolioValue[modalIndex].documents,
            ...response.data.files.map((file) => ({
              title: file.originalname,
              url: file.location,
            })),
          ];

          handleOnChangePortfolio(e, modalIndex, {
            name: "documents",
            value: newDocuments,
          });
        })
        .catch((error) => {
          setIsUploading(false);
          toast.error("Uploading failed!");
        });
    },
    [portfolioValue, selectedDoc, handleOnChangePortfolio, modalIndex]
  );

  /*********** PORTOFLIO UPDATE ***********/
  React.useEffect(() => {
    //get skill
    getAllSkills().then((response) => {
      let skillArr = [];
      response.data.data.map(({ skill, _id }) =>
        skillArr.push({ text: skill, value: _id })
      );
      setskillsOption(skillArr);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ToastContainer
        className="toast"
        position="bottom-center"
        autoClose={2500}
        hideProgressBar
      />
      <Modal
        open={showModal}
        onClose={() => {
          setSelectedDoc([]);
          setShowModal(!showModal);
          setModalIndex(-1);
        }}
        closeIcon
      >
        <Modal.Header>Upload Media</Modal.Header>
        <Modal.Content>
          <label>Select Document (max filesize 10 MB)</label>
          <Segment placeholder>
            <Header icon>
              {Object.keys(selectedDoc).length ? (
                Object.keys(selectedDoc).map((docKey) => (
                  <p>{selectedDoc[docKey].name}</p>
                ))
              ) : (
                <p>No media selected.</p>
              )}
            </Header>
            <Button
              primary
              content="Choose Media"
              labelPosition="left"
              icon="file"
              onClick={() => fileInputRef.current.click()}
            />
            <input
              ref={fileInputRef}
              type="file"
              hidden
              multiple
              onChange={(e) => setSelectedDoc(e.target.files)}
            />
          </Segment>

          <div className="item">
            <label>Summary</label>
            <TextArea
              className="textArea"
              rows="2"
              placeholder="Description for document"
              value={docDescription}
              onChange={(e) => setDocDiscription(e.target.value)}
            ></TextArea>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={() => uploadDocument()}
            disabled={isUploading}
            primary
          >
            {isUploading ? "Uploading..." : "Upload"}
          </Button>
        </Modal.Actions>
      </Modal>

      {/************** */}

      <Dimmer.Dimmable as="">
        <Dimmer active={isDimmerActive} inverted>
          <Loader />
        </Dimmer>
        <Section key="intro-section">
          <Section.Header key="intro-header-1" heading="Introduction" />
          <Section.ReadOnly key="intro-readonly-1">
            <div className="item row myProfile-basic">
              <div className="item major">
                <div className="title">{title}</div>
                <div className="para">{description}</div>
              </div>
              <div className="item minor center">
                <img src={imageUrl || ProfileImagePlaceholder} alt="" />
              </div>
            </div>
          </Section.ReadOnly>
          <Section.Editable
            key="intro-editable-1"
            isUpdating={isContactInfoSubmitting}
            onCancel={resetContactData}
            onUpdate={update}
          >
            <div className="field row">
              <div className="field major">
                <div className="field">
                  <label>Title</label>
                  <Input
                    placeholder="Add some title..."
                    value={titleValue}
                    name="title"
                    onChange={handleOnChange}
                  />
                </div>
                <div className="field">
                  <label>Summary</label>
                  <TextArea
                    rows={6}
                    value={descValue}
                    spellCheck={false}
                    onChange={handleOnChange}
                    name="summary"
                  />
                </div>
              </div>
              <div className="field minor myProfile-file">
                <img src={imageUrl || ProfileImagePlaceholder} alt="" />
                <label className="myProfile-file-label">
                  Change picture
                  <Input
                    className="myProfile-file-input"
                    type="file"
                    onChange={handleOnChangeImage}
                  />
                </label>
                <div className="myProfile-file-limits">
                  <div>File size limit: 10mb</div>
                  <div>Only PNG, JPG allowed</div>
                </div>
              </div>
            </div>
          </Section.Editable>
        </Section>
      </Dimmer.Dimmable>
      <Section key="skills-section">
        <Section.Header key="skills-header-1" heading="Skills" />
        <Section.ReadOnly key="skills-readonly-1">
          <div className="myProfile-skills">
            {skillsValue.map((skill, index) => (
              <div key={`skill-readonly-${index}`} className="item skill">
                {
                  skillsOption.find((skillOpt) => skillOpt.value === skill)
                    ?.text
                }
              </div>
            ))}
          </div>
        </Section.ReadOnly>
        <Section.Editable
          key="skills-editable-1"
          isUpdating={isContactInfoSubmitting}
          onCancel={resetSkillData}
          onUpdate={updateSkill}
        >
          <div className="field major">
            <Dropdown
              value={skillsValue}
              options={skillsOption}
              onChange={(e, data) => handleOnChangeforList(data.value)}
              fluid
              search
              selection
              multiple
            />
          </div>
        </Section.Editable>
      </Section>

      {/* EXPERIENCE */}
      <Section key="experience-section" onEdit={handleOnExperienceEdit}>
        <Section.Header key="experience-header-1" heading="Experience" />
        <Section.ReadOnly key="experience-readonly-1">
          {experienceValue.length &&
          JSON.stringify(experienceValue[0]) !==
            JSON.stringify(EMPTY_EXPERIENCE_OBJECT) ? (
            experienceValue.map((experience, i) => (
              <div key={`experience-${i}`}>
                <div className="item myProfile-experience">
                  <h4 className="myProfile-experience-title">
                    {experience.title}
                  </h4>
                  <p className="myProfile-experience-company">
                    {experience.company}
                  </p>
                  <span className="myProfile-experience-date">
                    {`${
                      experience.startMonth
                        ? MONTH_OPTIONS.find(
                            (month) => month.value === experience.startMonth
                          ).text
                        : ""
                    }`}{" "}
                    {experience.startYear} -{" "}
                    {experience.isCurrent
                      ? "Present"
                      : `${
                          experience.endMonth
                            ? MONTH_OPTIONS.find(
                                (month) => month.value === experience.endMonth
                              ).text
                            : ""
                        } ${experience.endYear}`}
                  </span>
                  <p className="myProfile-experience-summary">
                    {experience.summary}
                  </p>
                </div>
                {i !== experienceValue.length - 1 ? (
                  <div className="line-separator"></div>
                ) : null}
              </div>
            ))
          ) : (
            <p>No experience information added yet</p>
          )}
        </Section.ReadOnly>
        <Section.Editable
          key="experience-editable-1"
          isUpdating={isContactInfoSubmitting}
          onCancel={resetExperienceData}
          onUpdate={updateExperience}
        >
          <>
            {experienceValue.map((experience, i) => (
              <div
                key={`experienceeditable-${i}`}
                className="field myProfile-experience-item"
              >
                <div className="myProfile-experience-heading"># {i + 1}</div>
                <div className="field row">
                  <div className="field no-margin minor">
                    <label>Title</label>
                    <Input
                      placeholder="Enter a title"
                      value={experience.title}
                      name="title"
                      onChange={(e, data) => handleOnChangeform(e, i, data)}
                    />
                  </div>
                  <div className="field no-margin major">
                    <label>Company/Organization</label>
                    <Input
                      placeholder="Enter company/organization name"
                      value={experience.company}
                      name="company"
                      onChange={(e, data) => handleOnChangeform(e, i, data)}
                    />
                  </div>
                </div>
                <div className="field row">
                  <div className="field no-margin minor">
                    <label>Start Date</label>
                    <div className="field no-margin row myProfile-experience-date-selection">
                      <Select
                        options={MONTH_OPTIONS}
                        value={experience.startMonth}
                        name="startMonth"
                        onChange={(e, data) => handleOnChangeform(e, i, data)}
                      />
                      <Select
                        className="left-gap"
                        options={YEAR_OPTIONS}
                        value={experience.startYear}
                        name="startYear"
                        onChange={(e, data) => handleOnChangeform(e, i, data)}
                      />
                    </div>
                  </div>
                  <div className="field no-margin major">
                    <label>End Date</label>
                    <div className="field no-margin row myProfile-experience-date-selection">
                      <Select
                        options={MONTH_OPTIONS}
                        value={experience.endMonth}
                        disabled={experience.isCurrent}
                        name="endMonth"
                        onChange={(e, data) => handleOnChangeform(e, i, data)}
                      />
                      <Select
                        className="left-gap"
                        options={YEAR_OPTIONS}
                        value={experience.endYear}
                        name="endYear"
                        onChange={(e, data) => handleOnChangeform(e, i, data)}
                        disabled={experience.isCurrent}
                      />
                    </div>
                  </div>
                </div>
                {experience.startMonth &&
                experience.startYear &&
                (experience.isCurrent ||
                  (experience.endMonth && experience.endYear)) ? (
                  calculateExperience(experience).diff <= 0 ? (
                    <div className="field step3-error">
                      Please ensure start date is not earlier/same as end date
                    </div>
                  ) : (
                    <div className="field" style={{ opacity: 0.7 }}>
                      {`Experience: ${
                        calculateExperience(experience).years
                      } Years ${
                        calculateExperience(experience).months
                      } Months`}{" "}
                    </div>
                  )
                ) : null}
                <div className="field">
                  <Checkbox
                    className="myProfile-experience-checkbox"
                    checked={experience.isCurrent}
                    name="isCurrent"
                    onChange={(e, data) => handleOnChangeform(e, i, data)}
                    label="I'm currently working here"
                  />
                </div>
                <div className="field row">
                  <div className="field no-margin major">
                    <label>Summary</label>
                    <TextArea
                      placeholder="Describe your work position"
                      value={experience.summary}
                      rows={4}
                      name="summary"
                      onChange={(e, data) => handleOnChangeform(e, i, data)}
                    />
                  </div>
                  <div className="field no-margin at-bottom">
                    {i !== 0 && (
                      <Button
                        className="alternative warning"
                        onClick={() => handleDeleteExperience(i)}
                      >
                        Delete
                      </Button>
                    )}
                  </div>
                </div>
                {i !== experienceValue.length - 1 ? (
                  <div className="line-separator"></div>
                ) : (
                  <div
                    className="myProfile-addmore"
                    onClick={addMoreExperience}
                  >
                    <h4>+ Add more</h4>
                  </div>
                )}
              </div>
            ))}
            {!experienceValue.length && (
              <div className="myProfile-addmore" onClick={addMoreExperience}>
                <h4>+ Add more</h4>
              </div>
            )}
          </>
        </Section.Editable>
      </Section>

      {/* EDUCATION */}
      <Section key="education-section">
        <Section.Header key="education-header-1" heading="Education" />
        <Section.ReadOnly key="education-readonly-1">
          <div className="item no-margin myProfile-education">
            {educationValue.length &&
            JSON.stringify(educationValue[0]) !==
              JSON.stringify(EMPTY_EDUCATION_OBJECT) ? (
              educationValue.map((education, i) => (
                <div key={`educationReadonly-${i}`}>
                  <div className="myProfile-education-item">
                    <h4 className="myProfile-education-item-heading">
                      {education.degreeName}
                    </h4>
                    <p className="myProfile-education-item-school">
                      {education.schoolName}
                    </p>
                    <span className="myProfile-education-item-date">
                      {education.startDate} -{" "}
                      {education.isCurrent ? "Present" : education.endDate}
                    </span>
                  </div>
                  {i !== educationValue.length - 1 && (
                    <div className="line-separator"></div>
                  )}
                </div>
              ))
            ) : (
              <p>No education information added yet</p>
            )}
          </div>
        </Section.ReadOnly>
        <Section.Editable
          key="education-editable-1"
          isUpdating={isContactInfoSubmitting}
          onCancel={resetEducationData}
          onUpdate={updateEducation}
        >
          <div>
            {educationValue.map((educationItem, i) => (
              <div
                key={`educationValue-${i}`}
                className="myProfile-education-item"
              >
                <div className="myProfile-education-item-heading">
                  # {i + 1}
                </div>
                <div className="field">
                  <div className="field row">
                    <div className="field minor no-margin">
                      <label>Country</label>
                      <Select
                        name="country"
                        options={DUMMY_COUNTRY_LIST}
                        value={educationItem.country}
                        onChange={(e, data) =>
                          handleOnChangeEducation(e, i, data)
                        }
                      />
                    </div>
                    <div className="field major no-margin">
                      <label>University/College/School</label>
                      <Input
                        name="schoolName"
                        placeholder="Name of your university/college/school"
                        value={educationItem.schoolName}
                        onChange={(e, data) =>
                          handleOnChangeEducation(e, i, data)
                        }
                      />
                    </div>
                  </div>
                  <div className="field myProfile-education-degree">
                    <label>Degree</label>
                    <Input
                      name="degreeName"
                      placeholder="Enter your degree"
                      value={educationItem.degreeName}
                      onChange={(e, data) =>
                        handleOnChangeEducation(e, i, data)
                      }
                    />
                  </div>
                  <div className="field row">
                    <div className="field no-margin row">
                      <div className="field no-margin">
                        <label>Start Year</label>
                        <Select
                          name="startDate"
                          value={educationItem.startDate}
                          options={YEAR_OPTIONS}
                          onChange={(e, data) =>
                            handleOnChangeEducation(e, i, data)
                          }
                        />
                      </div>
                      <div className="field no-margin left-gap">
                        <label>End Year</label>
                        <Select
                          name="endDate"
                          value={educationItem.endDate}
                          options={YEAR_OPTIONS}
                          disabled={educationItem.isCurrent}
                          onChange={(e, data) =>
                            handleOnChangeEducation(e, i, data)
                          }
                        />
                      </div>
                    </div>
                    <div className="field no-margin at-bottom">
                      {i !== 0 && (
                        <Button
                          className="alternative warning"
                          onClick={() => handleDeleteEducation(i)}
                        >
                          Delete
                        </Button>
                      )}
                    </div>
                  </div>
                  <div className="field">
                    <Checkbox
                      className="myProfile-experience-checkbox"
                      checked={educationItem.isCurrent}
                      name="isCurrent"
                      onChange={(e, data) =>
                        handleOnChangeEducation(e, i, data)
                      }
                      label="I'm currently studying here"
                    />
                  </div>
                </div>
                {i !== educationValue.length - 1 ? (
                  <div className="line-separator"></div>
                ) : (
                  <div className="myProfile-addmore" onClick={addMoreEducation}>
                    <h4>+ Add more</h4>
                  </div>
                )}
              </div>
            ))}
          </div>
        </Section.Editable>
      </Section>

      {/* PORTFOLIO */}
      <Section key="portfolio-section">
        <Section.Header key="portfolio-header-1" heading="Portfolio" />
        <Section.ReadOnly key="portfolio-readonly">
          {portfolioValue.length &&
          JSON.stringify(portfolioValue[0]) !==
            JSON.stringify(EMPTY_PORTFOLIO_OBJECT) ? (
            portfolioValue.map((portfolio, index) => {
              return (
                <div className="item" key={index}>
                  <div className="item">
                    <div className="title">
                      {portfolio.title
                        ? portfolio.title
                        : "[No title provided]"}
                    </div>
                    <div className="subtitle">{portfolio.category}</div>
                    <div className="para">
                      <h4 className="heading">Tools</h4>
                      {portfolio.toolsUsed.length !== 0 ? (
                        <p className="myProfile-portfolio-toolsUsed">
                          {portfolio.toolsUsed.join(", ")}
                        </p>
                      ) : (
                        <p className="noDataAdded">No tools added</p>
                      )}
                    </div>
                    {!!portfolio.description && (
                      <div className="item-portfolio-description">
                        <div className="heading">Description</div>
                        <RichTextEditor
                          readOnly
                          value={
                            typeof portfolio.description === "string"
                              ? RichTextEditor.createValueFromString(
                                  portfolio.description,
                                  "html"
                                )
                              : portfolio.description
                          }
                        />
                      </div>
                    )}
                    <div className="major" style={{ marginTop: 20 }}>
                      <h4 className="heading">Documents</h4>
                      <div style={{ display: "flex" }}>
                        {portfolio.documents.length !== 0 ? (
                          portfolio.documents.map((item, index) => {
                            const extension = item.title;
                            const split = extension.split(".");
                            return (
                              <a
                                key={index}
                                style={{
                                  justifyContent: "center",
                                  padding: "5px",
                                }}
                                href={item.url}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img
                                  src={getFileIcon(split[1])}
                                  alt="document"
                                  style={{
                                    height: "30px",
                                    margin: "auto",
                                    width: "auto",
                                  }}
                                />
                                <p style={{ fontSize: 10 }}>
                                  {split[0].length > 15
                                    ? `${split[0].substring(0, 15)}...`
                                    : split[0]}
                                </p>
                              </a>
                            );
                          })
                        ) : (
                          <p className="noDataAdded">No documents added</p>
                        )}
                      </div>
                    </div>
                  </div>
                  {index !== portfolioValue.length - 1 && (
                    <div className="line-separator"></div>
                  )}
                </div>
              );
            })
          ) : (
            <p>No portfolio added yet</p>
          )}
        </Section.ReadOnly>
        <Section.Editable
          key="portfolio-editable"
          isUpdating={isContactInfoSubmitting}
          onCancel={resetPortfolioData}
          onUpdate={updatePortfolio}
        >
          <>
            {portfolioValue.map((portfolioItem, i) => {
              let editorValue = portfolioItem.description;

              if (typeof portfolioItem.description === "string") {
                if (!portfolioItem.description) {
                  editorValue = RichTextEditor.createEmptyValue();
                } else {
                  editorValue = RichTextEditor.createValueFromString(
                    portfolioItem.description,
                    "html"
                  );
                }
              } else if (editorValue === undefined) {
                editorValue = RichTextEditor.createEmptyValue();
              } else {
                editorValue = portfolioItem.description;
              }

              return (
                <>
                  <div key={i} className="myProfile-education-item">
                    <div className="myProfile-education-item-heading">
                      # {i + 1}
                    </div>
                    <div className="field">
                      <div className="field">
                        <label>Title</label>
                        <Input
                          name="title"
                          placeholder="Enter a title"
                          style={{ width: "100%" }}
                          value={portfolioItem.title}
                          onChange={(e, data) =>
                            handleOnChangePortfolio(e, i, data)
                          }
                        />
                      </div>
                      <div className="field">
                        <label>Documents</label>
                        <div className="item">
                          {portfolioItem.documents &&
                            portfolioItem.documents.length !== 0 && (
                              <div
                                style={{
                                  flexDirection: "column",
                                  display: "flex",
                                }}
                              >
                                {portfolioItem.documents.map((item, index) => {
                                  const extension = item.title;
                                  const split = extension.split(".");
                                  return (
                                    <div
                                      key={index}
                                      style={{
                                        padding: "5px",
                                        display: "flex",
                                      }}
                                    >
                                      <a
                                        style={{
                                          display: "flex",
                                        }}
                                        href={item.url}
                                        target="_blank"
                                        rel="noreferrer noopener"
                                      >
                                        <img
                                          src={getFileIcon(split[1])}
                                          alt="document"
                                          style={{
                                            height: "30px",
                                            width: "auto",
                                          }}
                                        />
                                        <p
                                          style={{
                                            fontSize: 10,
                                            lineHeight: "30px",
                                          }}
                                        >
                                          {split[0].length > 50
                                            ? `${split[0].substring(0, 50)}...`
                                            : split[0]}
                                        </p>
                                      </a>
                                      <Icon
                                        className="myProfile-portfolio-document-remove"
                                        name="close"
                                        onClick={() =>
                                          handleRemoveDocument(i, index)
                                        }
                                      />
                                    </div>
                                  );
                                })}
                              </div>
                            )}
                        </div>
                        <Button
                          onClick={() => {
                            setShowModal(!showModal);
                            setModalIndex(i);
                          }}
                          className="myProfile-portfolio-addMedia"
                          primary
                        >
                          Add Media
                        </Button>
                      </div>
                      <div className="field row">
                        <div
                          className="field"
                          style={{ flex: "1", marginRight: "28px" }}
                        >
                          <label>Category</label>
                          <Dropdown
                            options={[
                              { text: "Dummy 1", value: "dummy-1" },
                              { text: "Dummy 2", value: "dummy-2" },
                            ]}
                            name="category"
                            value={portfolioItem.category || ""}
                            onChange={(e, data) =>
                              handleOnChangePortfolio(e, i, data)
                            }
                            selection
                          />
                        </div>
                        <div className="field" style={{ flex: "1" }}>
                          <label>Tools Used</label>
                          <Dropdown
                            options={TOOLS}
                            name="toolsUsed"
                            value={portfolioItem.toolsUsed || []}
                            onChange={(e, data) =>
                              handleOnChangePortfolio(e, i, data)
                            }
                            search
                            selection
                            multiple
                          />
                        </div>
                      </div>
                      <div className="field summary-field">
                        <label>Summary</label>
                        <RichTextEditor
                          name="description"
                          placeholder="Portfolio description"
                          toolbarConfig={TOOLBAR_CONFIG}
                          value={editorValue}
                          onChange={(value) =>
                            handleOnChangePortfolio(null, i, {
                              name: "description",
                              value,
                            })
                          }
                        />
                      </div>
                      {i !== 0 && (
                        <div className="field no-margin at-bottom">
                          <Button
                            className="alternative warning myProfile-portfolio-delete"
                            onClick={() => handleDeletePortfolio(i)}
                          >
                            Delete
                          </Button>
                        </div>
                      )}
                    </div>
                    {i !== portfolioValue.length - 1 && (
                      <div className="line-separator"></div>
                    )}
                  </div>
                </>
              );
            })}
            <div className="myProfile-addmore" onClick={addMorePortfolio}>
              <h4>+ Add more</h4>
            </div>
          </>
        </Section.Editable>
      </Section>
    </>
  );
}

MyProfile.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  imageUrl: PropTypes.string,
  profileImage: PropTypes.string,
  skills: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }),
  education: PropTypes.arrayOf(),
  experience: PropTypes.arrayOf(),
};

MyProfile.defaultProps = {
  title: "",
  description: "",
  profileImage: "",
  skills: [],
  imageUrl: "",
  education: [],
  experience: [],
};

export default MyProfile;
