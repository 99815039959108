import { MONTH_OPTIONS } from "constants/Options";
import pdfIcon from "static/icons/pdficon.png";
import docIcon from "static/icons/docicon.png";
import imgIcon from "static/icons/imgicon.png";

export const getToken = () => localStorage.getItem("token");
export const setToken = (token, cb) => {
  localStorage.setItem("token", token);
  cb();
};
export const removeToken = () => localStorage.removeItem("token");

export const firstLetterCapitalise = (string) => {
  if (typeof string !== "string") return "";
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const millisecondsToStr = (milliseconds) => {
  let temp = milliseconds / 1000;
  const years = Math.floor(temp / 31536000),
    days = Math.floor((temp %= 31536000) / 86400),
    hours = Math.floor((temp %= 86400) / 3600),
    minutes = Math.floor((temp %= 3600) / 60),
    seconds = temp % 60;

  if (days || hours || seconds || minutes) {
    return (years ? years + "y " : "") + (days ? days + "d " : "");
  }

  return "";
};

export const getMonthName = (id) =>
  MONTH_OPTIONS.find((m) => m.value === id)?.text;

export const calculateExperience = ({
  startYear,
  startMonth,
  endYear,
  endMonth,
  isCurrent,
}) => {
  const startMonthValue =
    parseInt(startYear * 12) + parseInt(parseInt(startMonth) + 1);
  const endMonthValue = isCurrent
    ? parseInt(new Date().getFullYear() * 12) +
      parseInt(new Date().getMonth() + 1)
    : parseInt(endYear * 12) + parseInt(parseInt(endMonth) + 1);
  const monthInterval = endMonthValue - startMonthValue;

  const yearsOfExperience = Math.floor(monthInterval / 12);
  const monthsOfExperience = monthInterval % 12;

  return {
    diff: parseInt(monthInterval),
    years: yearsOfExperience,
    months: monthsOfExperience,
  };
};

export const calculateTotalExperience = (experiences = []) => {
  let totalExperienceInMonths = 0;

  experiences.forEach(
    ({ startYear, startMonth, endYear, endMonth, isCurrent }) => {
      const startMonthValue =
        parseInt(startYear * 12) + parseInt(parseInt(startMonth) + 1);
      const endMonthValue = isCurrent
        ? parseInt(new Date().getFullYear() * 12) +
          parseInt(new Date().getMonth() + 1)
        : parseInt(endYear * 12) + parseInt(parseInt(endMonth) + 1);
      const monthInterval = endMonthValue - startMonthValue;
      totalExperienceInMonths += monthInterval;
    }
  );

  const yearsOfExperience = Math.floor(totalExperienceInMonths / 12);
  const monthsOfExperience = totalExperienceInMonths % 12;

  return {
    years: yearsOfExperience,
    months: monthsOfExperience,
  };
};

export const getFileIcon = (extension) => {
  switch (extension) {
    case "pdf":
      return pdfIcon;
    case "doc":
    case "docx":
      return docIcon;
    case "png":
    case "jpg":
    case "jpeg":
    case "svg":
    case "icon":
      return imgIcon;
    default:
      return docIcon;
  }
};
