import React from "react";

import "./RefundPolicy.scss";

function RefundPolicy() {
  return (
    <div className="privacyPolicy">
      <h3 className="privacyPolicy-heading">ExpertRight Refund Policy</h3>
      <div className="privacyPolicy-content">
        Our policy lasts 7 days from the date of submission of task/file. If 7
        days have gone by since your purchase, unfortunately we can’t offer you
        a refund or additional/change in service.
        <br /> <br />
        To be eligible for a refund, your service item must be unused and in the
        same condition that you received it. It must also be not asked for
        corrections/updates/changes. After any correction/change request you
        will not be entitled for refund.
        <br /> <br />
        Several types of services are exempt from being refunded. Creative
        services such as content writing, graphics designing, animation cannot
        be refunded.
        <br /> <br />
        Additional non-refundable items: * Services where one(1) or more round
        of corrections/changes have been requested * Downloadable software
        products
        <br /> <br />
        <b>
          To complete your refund, we require a receipt or proof of purchase.
          Please do not send your purchase back to the service provider.
        </b>
        <br /> <br />
        Once your return is received and inspected, we will send you an email to
        notify you that we have received your returned item. We will also notify
        you of the approval or rejection of your refund. If you are approved,
        then your refund will be processed, and a credit will automatically be
        applied to your credit card or original method of payment, within a
        certain amount of days.
        <br /> <br />
        <b>Late or missing refunds (if applicable)</b>
        <br />
        If you haven’t received a refund yet, first check your bank account
        again. Then contact your credit card company, it may take some time
        before your refund is officially posted. Next contact your bank. There
        is often some processing time before a refund is posted. If you’ve done
        all of this and you still have not received your refund yet, please
        contact us at support@expertright.com. Sale items (if applicable)
        <br />
        Only regular priced items may be refunded, unfortunately sale items
        cannot be refunded.
        <br /> <br />
        <b>Exchanges (if applicable)</b>
        <br />
        We only replace items if they are defective or damaged. If you need to
        exchange it for the same item, send us an email at
        support@expertright.com and send your item to: G-2 589, riddhi siddhi,
        jaipur, RJ, 302015, India. To return your product, you should mail your
        product to: G-2 589, riddhi siddhi, jaipur, RJ, 302015, India.
        <br />
        You will be responsible for paying for your own travelling/legal costs
        for any case or whatsoever. .
      </div>
    </div>
  );
}

export default RefundPolicy;
