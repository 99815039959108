import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Input, Dropdown, Label, TextArea } from "semantic-ui-react";

import ROUTES from "constants/Routes";

import SimpleCard from "components/SimpleCard";
import { JOB_DURATION } from "containers/JobPost/constants";

import NewWindow from "static/icons/new-window.svg";

import "./JobProposalBody.scss";

function JobProposalBody({
  _id: id,
  title,
  description,
  category,
  skills,
  isRemote,
  location,
  additionalQuestion1,
  additionalQuestion2,
  additionalQuestion3,

  bid,
  applicationDuration,
  cover,
  additionalAnswer1,
  additionalAnswer2,
  additionalAnswer3,

  onChange,
}) {
  const [bidAfter, setBidAfter] = React.useState();
  const [serviceFee, setServiceFee] = React.useState(0);

  const renderHeaderItem = React.useCallback(
    () => (
      <Link
        className="jobProposalBody-header-link"
        to={`${ROUTES.JOB_POST_DETAIL.replace(":id", id)}`}
        target="_blank"
      >
        <img src={NewWindow} alt="" />
        Open Job post in a new window
      </Link>
    ),
    [id]
  );

  const handleOnChange = React.useCallback(
    (e) => {
      onChange(e.target.name, e.target.value);

      if (e.target.name === "bid") {
        // as 10% fees is deducted by upwork
        setBidAfter((e.target.value * 0.9).toFixed(2));
        setServiceFee((e.target.value * 0.1).toFixed(2));
      }
    },
    [onChange]
  );

  const handleOnBidAfterChange = React.useCallback(
    (e) => {
      const bidAfterValue = ((e.target.value * 10) / 9).toFixed(2);
      onChange("bid", bidAfterValue);
      setServiceFee((bidAfterValue * 0.1).toFixed(2));
      setBidAfter(e.target.value);
    },
    [onChange]
  );

  return (
    <>
      <SimpleCard heading="Job Details" renderHeaderItem={renderHeaderItem}>
        <SimpleCard.Body>
          <div className="jobProposalBody jobProposalBody-wrapper">
            <div className="jobProposalBody-block">
              <div className="jobProposalBody-details">
                <p className="jobProposalBody-details-title">{title}</p>
                <span className="jobProposalBody-details-createdDate">
                  Posted on{" "}
                </span>
                <p className="jobProposalBody-details-description">
                  {description}
                </p>
              </div>
              <div className="jobProposalBody-summary">
                <div className="jobProposalBody-summary-field">
                  <p>{isRemote ? "Remote" : location}</p>
                  <span>Location</span>
                </div>
                <div className="jobProposalBody-summary-field">
                  <p>{category.title}</p>
                  <span>Category</span>
                </div>
              </div>
            </div>
            <div className="jobProposalBody">
              <h4 className="jobProposalBody-heading-small">
                Skills required:
              </h4>
              <div className="jobProposalBody-skills">
                {skills.map(({skill}) => (
                  <div className="jobProposalBody-skills-item">{skill}</div>
                ))}
              </div>
            </div>
          </div>
        </SimpleCard.Body>
      </SimpleCard>
      <SimpleCard heading="Bid Details">
        <SimpleCard.Body>
          <div className="jobProposalBody jobProposalBody-wrapper">
            <h4 className="jobProposalBody-heading-small">
              How much would you like to bid for this project?
            </h4>
            <div className="jobProposalBody-block-wrapper">
              <div className="jobProposalBody-block jobProposalBody-terms">
                <p className="jobProposalBody-terms-question">
                  Total amount the client will see on your proposal
                </p>
                <Input
                  name="bid"
                  labelPosition="right"
                  type="number"
                  placeholder="Amount"
                  className="jobProposalBody-terms-answer"
                  value={bid}
                  onChange={handleOnChange}
                >
                  <Label basic>₹</Label>
                  <input className="side-padding" />
                  <Label>.00</Label>
                </Input>
              </div>
              <div className="jobProposalBody-block jobProposalBody-block-margin-top jobProposalBody-terms">
                <p className="jobProposalBody-terms-question">
                  ExpertRight service fee
                </p>
                <div className="jobProposalBody-terms-answer jobProposalBody-terms-serviceFee">
                  <div className="jobProposalBody-terms-serviceFee-symbol">
                    ₹
                  </div>
                  <p className="jobProposalBody-terms-serviceFee-amount">
                    {serviceFee}
                  </p>
                </div>
              </div>
              <div className="jobProposalBody-block jobProposalBody-block-margin-top jobProposalBody-terms">
                <p className="jobProposalBody-terms-question">
                  Total amount you'll receive after service fees
                </p>
                <Input
                  name="bid"
                  labelPosition="right"
                  type="number"
                  placeholder="Amount"
                  className="jobProposalBody-terms-answer"
                  value={bidAfter}
                  onChange={handleOnBidAfterChange}
                >
                  <Label basic>₹</Label>
                  <input className="side-padding" />
                  <Label>.00</Label>
                </Input>
              </div>
            </div>
          </div>
        </SimpleCard.Body>
      </SimpleCard>
      <SimpleCard heading="Additional details">
        <SimpleCard.Body>
          <div className="jobProposalBody-wrapper">
            <div className="jobProposalBody">
              <h4 className="jobProposalBody-heading-small">
                How long will this project take?
              </h4>
              <Dropdown
                className="jobProposalBody-additional-dropdown"
                options={JOB_DURATION}
                value={applicationDuration}
                onChange={(e, data) =>
                  handleOnChange({
                    target: { name: "applicationDuration", value: data.value },
                  })
                }
                selection
              />
            </div>
            <div className="jobProposalBody">
              <h4 className="jobProposalBody-heading-small">Cover details</h4>
              <TextArea
                name="cover"
                rows={6}
                value={cover}
                spellCheck={false}
                onChange={handleOnChange}
              />
            </div>
          </div>
        </SimpleCard.Body>
      </SimpleCard>
      {(additionalQuestion1 || additionalQuestion2 || additionalQuestion3) && (
        <SimpleCard heading="Additional Questions">
          <SimpleCard.Body>
            <div className="jobProposalBody-wrapper">
              {additionalQuestion1 && (
                <div className="jobProposalBody">
                  <h4 className="jobProposalBody-heading-small">
                    {additionalQuestion1}
                  </h4>
                  <TextArea
                    name="additionalAnswer1"
                    rows={4}
                    value={additionalAnswer1}
                    spellCheck={false}
                    onChange={handleOnChange}
                  />
                </div>
              )}
              {additionalQuestion2 && (
                <div className="jobProposalBody">
                  <h4 className="jobProposalBody-heading-small">
                    {additionalQuestion2}
                  </h4>
                  <TextArea
                    name="additionalAnswer2"
                    rows={4}
                    value={additionalAnswer2}
                    spellCheck={false}
                    onChange={handleOnChange}
                  />
                </div>
              )}
              {additionalQuestion3 && (
                <div className="jobProposalBody">
                  <h4 className="jobProposalBody-heading-small">
                    {additionalQuestion3}
                  </h4>
                  <TextArea
                    name="additionalAnswer3"
                    rows={4}
                    value={additionalAnswer3}
                    spellCheck={false}
                    onChange={handleOnChange}
                  />
                </div>
              )}
            </div>
          </SimpleCard.Body>
        </SimpleCard>
      )}
    </>
  );
}

JobProposalBody.propTypes = {
  _id: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  category: PropTypes.string,
  skills: PropTypes.arrayOf(PropTypes.string),
  isRemote: PropTypes.bool,
  location: PropTypes.string,
  onChange: PropTypes.func,
};

JobProposalBody.defaultProps = {
  _id: "",
  title: "",
  description: "",
  category: "",
  skills: [],
  isRemote: false,
  location: "",
  onChange: () => {},
};

export default JobProposalBody;
