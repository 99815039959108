import React, { useCallback } from "react";
import { Link, useHistory } from "react-router-dom";
import PropTypes from "prop-types";

import ROUTES from "constants/Routes";
import ConfirmedImage from "static/icons/account-confirmed.svg";
import Failed from "static/icons/caution.svg";

import "./Confirmed.scss";

function Confirmed({ isValid, errorMessage, redirectUrl }) {
  const history = useHistory();

  const renderIsNotValid = useCallback(
    () => (
      <>
        <img className="confirmed-image" src={Failed} alt="" />
        <h3 className="confirmed-heading">Error occurred!</h3>
        <p className="confirmed-message">{errorMessage}</p>
      </>
    ),
    [errorMessage]
  );

  const renderIsValid = useCallback(
    () => (
      <>
        <img className="confirmed-image" src={ConfirmedImage} alt="" />
        <h3 className="confirmed-heading">Account Confimed!</h3>
        <Link
          className="confirmed-nextStep"
          to={{
            pathname: ROUTES.LOGIN,
            data: { redirectUrl: redirectUrl || "" },
          }}
        >
          <button
            className="primary"
            onClick={() => history.push(ROUTES.LOGIN)}
          >
            Proceed to Login
          </button>
        </Link>
      </>
    ),
    [redirectUrl, history]
  );
  return <>{isValid ? renderIsValid() : renderIsNotValid()}</>;
}

Confirmed.propTypes = {
  isValid: PropTypes.bool,
  errorMessage: PropTypes.string,
  redirectUrl: PropTypes.string,
};

Confirmed.defaultProps = {
  isValid: false,
  errorMessage: "Expired Link! Signup again.",
  redirectUrl: "",
};

export default Confirmed;
