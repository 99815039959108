import React from "react";
import Categories from "./components/Categories"
import "./ContactPage.scss";

function ContactUsPage() {
  return (
    <main className="homepage">
      <Categories />
    </main>
  );
}

export default ContactUsPage;
