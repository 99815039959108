export const getIsLoggedIn = (state) => state.persistedStore.isLoggedIn;
export const getIsProfileSetupDone = (state) =>
  state.persistedStore.isProfileSetupDone;
export const getUserId = (state) => state.persistedStore.id;
export const getIsClient = (state) => state.persistedStore.isClient;
export const getPhone = (state) => state.persistedStore.phone;
export const getEmail = (state) => state.persistedStore.email;
export const getName = (state) => state.persistedStore.name;
export const getProvider = (state) => state.persistedStore.provider;

export const getToken = (state) => state.persistedStore.token;
