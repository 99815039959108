import React from "react";
import PropTypes from "prop-types";
import { Input, Button, TextArea, Checkbox, Select } from "semantic-ui-react";

import { MONTH_OPTIONS, YEAR_OPTIONS } from "constants/Options";
import { calculateExperience } from "utils";

import "./Step3.scss";

function Step3({
  work,
  onChange,
  handlePreviousStep,
  handleNextStep,
  isSubmitting,
  formErrors,
  resetError
}) {
  const [workProfile, setWorkProfile] = React.useState(work);

  const handleOnChange = React.useCallback(
    (data) => {
      const profiles = [...workProfile];

      profiles[data.index] = {
        ...profiles[data.index],
        ...data.changes,
      };

      setWorkProfile(profiles);
      resetError("workDetails")
      onChange("workDetails", profiles);
    },
    [workProfile, onChange, resetError]
  );

  const handleAddMore = React.useCallback(() => {
    const newProfiles = [...workProfile, {}];

    onChange("workDetails", newProfiles);
    setWorkProfile(newProfiles);
  }, [workProfile, onChange]);

  const handleDelete = React.useCallback(
    (index) => {
      const workprofiles = [...workProfile];
      setWorkProfile(workprofiles.filter((value, i) => index !== i));
    },
    [workProfile]
  );

  return (
    <div className="profileStep1-wrapper">
      <div className="step3-box">
        <div className="myProfile-education-item-heading">Work details</div>
        {workProfile.map((workDetails, i) => (
          <div key={`workDetails-${i}`} className="myProfile-education-item">
            <div className="myProfile-education-item-heading"># {i + 1}</div>
            <div className="field">
              <div className="field row">
                <div className="field no-margin minor">
                  <label>Title</label>
                  <Input
                    placeholder="Enter a title"
                    value={workDetails.titleValueForm}
                    name="title"
                    onChange={(e) =>
                      handleOnChange({
                        index: i,
                        changes: { title: e.target.value },
                      })
                    }
                  />
                </div>
                <div className="field no-margin major">
                  <label>Company/Organization</label>
                  <Input
                    placeholder="Enter company/organization name"
                    value={workDetails.companyName}
                    name="company"
                    onChange={(e) =>
                      handleOnChange({
                        index: i,
                        changes: { company: e.target.value },
                      })
                    }
                  />
                </div>
              </div>
              <div className="field row">
                <div className="field no-margin">
                  <label>Start Date</label>
                  <div className="field no-margin row step3-date-selection">
                    <Select
                      options={MONTH_OPTIONS}
                      value={workDetails.startMonth}
                      name="startMonth"
                      onChange={(e, data) =>
                        handleOnChange({
                          index: i,
                          changes: { startMonth: data.value },
                        })
                      }
                    />
                    <Select
                      className="left-gap"
                      options={YEAR_OPTIONS}
                      value={workDetails.startYear}
                      name="startYear"
                      onChange={(e, data) =>
                        handleOnChange({
                          index: i,
                          changes: { startYear: data.value },
                        })
                      }
                    />
                  </div>
                </div>
                <div className="field no-margin">
                  <label>End Date</label>
                  <div className="field no-margin row step3-date-selection">
                    <Select
                      options={MONTH_OPTIONS}
                      value={workDetails.endMonth}
                      disabled={workDetails.isCurrent}
                      name="endMonth"
                      onChange={(e, data) =>
                        handleOnChange({
                          index: i,
                          changes: { endMonth: data.value },
                        })
                      }
                    />
                    <Select
                      className="left-gap"
                      options={YEAR_OPTIONS}
                      value={workDetails.endYear}
                      name="endYear"
                      onChange={(e, data) =>
                        handleOnChange({
                          index: i,
                          changes: { endYear: data.value },
                        })
                      }
                      disabled={workDetails.isCurrent}
                    />
                  </div>
                </div>
              </div>
              {workDetails.startMonth &&
              workDetails.startYear &&
              (workDetails.isCurrent ||
                (workDetails.endMonth && workDetails.endYear)) ? (
                calculateExperience(workDetails).diff <= 0 ? (
                  <div className="step3-error">
                    Please ensure start date is not earlier/same as end date
                  </div>
                ) : (
                  <div style={{ opacity: 0.7 }}>
                    {`Experience: ${
                      calculateExperience(workDetails).years
                    } Years ${
                      calculateExperience(workDetails).months
                    } Months`}{" "}
                  </div>
                )
              ) : null}
              <div>
                <div className="field">
                  <Checkbox
                    className="myProfile-experience-checkbox"
                    checked={workDetails.isCurrent}
                    name="isCurrent"
                    onChange={(e, data) =>
                      handleOnChange({
                        index: i,
                        changes: { isCurrent: data.checked },
                      })
                    }
                    label="I'm currently working here"
                  />
                </div>
                <div className="field">
                  <div className="field no-margin">
                    <label>Summary</label>
                    <TextArea
                      placeholder="Describe your work position"
                      value={workDetails.summary}
                      rows={4}
                      name="summary"
                      onChange={(e, data) =>
                        handleOnChange({
                          index: i,
                          changes: { summary: data.value },
                        })
                      }
                    />
                  </div>
                </div>
                {i !== 0 && (
                  <div className="field no-margin at-bottom step3-delete">
                    <Button
                      className="alternative warning"
                      onClick={() => handleDelete(i)}
                    >
                      Delete
                    </Button>
                  </div>
                )}
              </div>
            </div>
            {i !== workDetails.length - 1 && (
              <div className="line-separator"></div>
            )}
          </div>
        ))}
        <div
          className="myProfile-addmore step3-margin-top-0"
          onClick={handleAddMore}
        >
          <h4>+ Add more</h4>
        </div>
      </div>
      <div className="step3-buttons">
        <Button className="alternative" onClick={handlePreviousStep}>
          Previous
        </Button>
        <Button
          className="primary"
          onClick={handleNextStep}
          loading={isSubmitting}
          disabled={formErrors["workDetails"]}
        >
          Next
        </Button>
      </div>
    </div>
  );
}

Step3.propTypes = {
  onChange: PropTypes.func.isRequired,
  handlePreviousStep: PropTypes.func.isRequired,
  handleNextStep: PropTypes.func.isRequired,
};

export default Step3;
