import React from "react";
import PropTypes from "prop-types";

import SectionHeader from "./components/SectionHeader";
import ReadOnly from "./components/ReadOnly";
import Editable from "./components/Editable";
import "./Section.scss";

function Section({ children, alwaysEditable, onEdit }) {
  const [isEditOn, setIsEditOn] = React.useState(alwaysEditable || false);

  const handleOnEdit = React.useCallback(() => {
    onEdit(!isEditOn);
    setIsEditOn(!isEditOn);
  }, [isEditOn, onEdit]);

  const getChildrenWithProps = React.useCallback(
    (children) => {
      return children.map((child) => {
        if (child.type.name === ReadOnly.name) {
          if (isEditOn) return null;
        }

        if (child.type.name === Editable.name) {
          if (!isEditOn) return null;

          return React.cloneElement(child, {
            ...child.props,
            handleOnCancel: handleOnEdit,
            alwaysEditable,
          });
        }

        if (child.type.name === SectionHeader.name) {
          return React.cloneElement(child, {
            ...child.props,
            handleOnEdit,
            isEditOn,
          });
        }

        return React.cloneElement(child);
      });
    },
    [handleOnEdit, isEditOn, alwaysEditable]
  );

  const modifiedChildren = getChildrenWithProps(children);
  return <div className="section">{modifiedChildren}</div>;
}

Section.propTypes = {
  alwaysEditable: PropTypes.bool,
  onEdit: PropTypes.func,
};

Section.defaultProps = {
  alwaysEditable: false,
  onEdit: () => {},
};

Section.Header = SectionHeader;
Section.ReadOnly = ReadOnly;
Section.Editable = Editable;

export default Section;
