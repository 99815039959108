/* eslint-disable no-unused-vars */
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Dimmer, Loader } from "semantic-ui-react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ROUTES from "constants/Routes";
import FormWrapper from "components/FormWrapper";

import Step1 from "./components/Step1";
import Step2 from "./components/Step2";
import Step2Client from "./components/Step2Client";
import Step3 from "./components/Step3";
import Step4 from "./components/Step4";

import { imageData } from "containers/Settings/actions";
import { profileSetupAPI } from "./actions";
import {
  PROFILE_SETUP_STEPS_FREELANCER,
  PROFILE_SETUP_STEP_CLIENT,
} from "./constants";
import { stateToProps, dispatchToProps } from "./maps";
import { checkStepOne, checkStepTwo, checkStepThree } from "./validations";

import "./ProfileSetup.scss";

function ProfileSetup() {
  const history = useHistory();
  const location = useLocation();
  const { from } = location.state || { from: undefined };
  const { userId, isClient, isProfileSetupDone } = useSelector(stateToProps);
  const { setUserMeta } = dispatchToProps(useDispatch());
  const [currentStep, setCurrentStep] = React.useState(0);
  const [stepsCompleted, setStepsCompleted] = React.useState([0]);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [isDimmerActive, setIsDimmerActive] = React.useState();
  const [formData, setFormData] = React.useState({
    name: "",
    phone: "",
    profileType: "",
    profileImage: "",
    title: "",
    description: "",
    skills: [],
    imageUrl: "",
    workDetails: [{}],
    portfolioDetails: [{}],

    whatKindOfFreelancerYouAre: "",
    chooseLocationPreference: "",
    rateYourCommunicationSkills: "",
  });
  const [formErrors, setFormErrors] = React.useState({
    name: false,
    phone: false,
    profileType: false,
  });

  if (isProfileSetupDone && from) {
    history.replace(from);
  }

  const checkIsClient = React.useCallback(
    () => formData.profileType === "client" || isClient,
    [formData.profileType, isClient]
  );

  const updateFormData = React.useCallback(
    (key, value) => setFormData({ ...formData, [key]: value }),
    [formData]
  );

  const getProfileSteps = React.useCallback(
    () =>
      checkIsClient()
        ? PROFILE_SETUP_STEP_CLIENT
        : PROFILE_SETUP_STEPS_FREELANCER,
    [checkIsClient]
  );

  const changeCurrentStep = React.useCallback(
    (stepNumber) => {
      if (stepNumber < currentStep) {
        setCurrentStep(stepNumber);
        return;
      }

      const { profileType } = formData;

      switch (stepNumber) {
        case 1:
          const { hasError, errors: newErrors } = checkStepOne(formData);

          if (hasError) {
            setFormErrors((errors) => ({ ...errors, ...newErrors }));
            break;
          }

          setStepsCompleted([0, 1]);
          setCurrentStep(stepNumber);

          break;

        case 2:
          const { hasError: hasTwoError, errors: newTwoErrors } =
            checkStepTwo(formData);

          if (hasTwoError) {
            setFormErrors((errors) => ({ ...errors, ...newTwoErrors }));
            break;
          }

          if (profileType === "freelancer") {
            setStepsCompleted([0, 1, 2]);
            setCurrentStep(stepNumber);
          }

          break;

        case 3:
          if (profileType === "freelancer") {
            const { hasError: hasThreeError, errors: newThreeErrors } =
              checkStepThree(formData);

            if (hasThreeError) {
              setFormErrors((errors) => ({ ...errors, ...newThreeErrors }));
              break;
            }

            setStepsCompleted([0, 1, 2, 3]);
            setCurrentStep(stepNumber);
          }
          break;

        default:
          setCurrentStep(stepNumber);
          break;
      }
    },
    [currentStep, formData]
  );

  const handleResetError = React.useCallback((key) => {
    setFormErrors((errors) => ({ ...errors, [key]: false }));
  }, []);

  const onSubmit = React.useCallback(() => {
    if (isSubmitting) return;

    const { name, phone, profileType } = formData;
    if (!name || !phone || !profileType) return;

    setIsSubmitting(true);

    const isClient = profileType === "client" ? true : false;

    const data = isClient
      ? {
          id: userId,
          isClient,
          ...formData,
        }
      : {
          ...formData,
          id: userId,
          portfolioDetails: formData.portfolioDetails
            .filter((portfolio) => !!portfolio.title)
            .map((p) => ({
              ...p,
              description: p.description
                ? typeof p.description === "string"
                  ? p.description
                  : p.description.toString("html")
                : p.description,
            })),
          experience: formData.workDetails.filter((work) => !!work.title),
          isClient,
          freelancerQuestions: [
            {
              question: "whatKindOfFreelancerYouAre",
              answer: formData.whatKindOfFreelancerYouAre,
            },
            {
              question: "chooseLocationPreference",
              answer: formData.chooseLocationPreference,
            },
            {
              question: "rateYourCommunicationSkills",
              answer: formData.rateYourCommunicationSkills,
            },
          ],
        };

    profileSetupAPI(data)
      .then(() => {
        setIsSubmitting(false);
        setUserMeta({
          isProfileSetupDone: true,
          isClient: data.isClient,
          name,
          phone,
        });
      })
      .catch((error) => {
        setIsSubmitting(false);
        try {
          toast.error(error.response.data.error);
        } catch (e) {
          toast.error("Error occurred while saving!");
        }
      });
  }, [formData, userId, isSubmitting, setUserMeta]);

  const handleImageUpload = React.useCallback(
    (e) => {
      setIsDimmerActive(true);
      // Create an object of formData
      const formData = new FormData();
      // Update the formData object
      formData.append("file", e.target.files[0], e.target.files[0].name);
      imageData(userId, formData)
        .then((response) => {
          setIsDimmerActive(false);
          setFormData((form) => ({
            ...form,
            profileImage: response.data.location,
          }));
        })
        .catch((error) => {
          setIsDimmerActive(false);
          toast.error("Error occurred while image upload!");
        });
    },
    [userId]
  );

  let step;
  switch (currentStep) {
    case 1:
      if (formData.profileType === "freelancer") {
        step = (
          <Step2
            title={formData.title}
            description={formData.description}
            skills={formData.skills}
            whatKindOfFreelancerYouAre={formData.whatKindOfFreelancerYouAre}
            chooseLocationPreference={formData.chooseLocationPreference}
            rateYourCommunicationSkills={formData.rateYourCommunicationSkills}
            onChange={updateFormData}
            handlePreviousStep={() => changeCurrentStep(currentStep - 1)}
            handleNextStep={() => changeCurrentStep(currentStep + 1)}
            isSubmitting={isSubmitting}
            formErrors={formErrors}
            resetError={handleResetError}
          />
        );
      } else {
        step = (
          <Step2Client
            companyName={formData.companyName}
            companyWebsite={formData.companyWebsite}
            employeeStrength={formData.employeeStrength}
            keyClients={formData.keyClients}
            companyTags={formData.companyTags || []}
            companyLinkedin={formData.companyLinkedin}
            handlePreviousStep={() => changeCurrentStep(currentStep - 1)}
            onChange={updateFormData}
            handleSubmitForm={onSubmit}
            isSubmitting={isSubmitting}
          />
        );
      }
      break;

    case 2:
      if (formData.profileType === "freelancer") {
        step = (
          <Step3
            work={formData.workDetails}
            onChange={updateFormData}
            handlePreviousStep={() => changeCurrentStep(currentStep - 1)}
            handleNextStep={() => changeCurrentStep(currentStep + 1)}
            isSubmitting={isSubmitting}
            formErrors={formErrors}
            resetError={handleResetError}
          />
        );
      }
      break;

    case 3:
      if (formData.profileType === "freelancer") {
        step = (
          <Step4
            portfolio={formData.portfolioDetails}
            onChange={updateFormData}
            handlePreviousStep={() => changeCurrentStep(currentStep - 1)}
            handleSubmitForm={onSubmit}
            handleNextStep={() => changeCurrentStep(currentStep + 1)}
            isSubmitting={isSubmitting}
          />
        );
      }
      break;

    default:
      step = (
        <Step1
          name={formData.name}
          phone={formData.phone}
          profileType={formData.profileType}
          profileImage={formData.profileImage}
          onChange={updateFormData}
          handleImageUpload={handleImageUpload}
          handleNextStep={() => changeCurrentStep(currentStep + 1)}
          isSubmitting={isSubmitting}
          formErrors={formErrors}
          resetError={handleResetError}
        />
      );
  }

  return (
    <FormWrapper
      heading="Profile Setup"
      subHeading="Complete your profile to unlock features!"
    >
      <ToastContainer
        className="toast"
        position="bottom-center"
        autoClose={2500}
        hideProgressBar
      />
      {isProfileSetupDone ? (
        <FormWrapper.Success
          successMessage="Profile setup completed"
          primaryButtonMessage="Go to dashboard"
          secondaryButtonMessage={
            checkIsClient() ? "Post a new Job" : "View job posts"
          }
          primaryLink={ROUTES.DASHBOARD}
          secondaryLink={
            checkIsClient() ? ROUTES.JOB_POST_NEW : ROUTES.JOB_POST_SEARCH
          }
        />
      ) : (
        <div className="profileSetup-wrapper">
          <FormWrapper.StepSidebar
            steps={getProfileSteps()}
            currentStep={currentStep}
            stepsCompleted={stepsCompleted}
            onClick={changeCurrentStep}
            enableAnimation
          />
          <Dimmer.Dimmable>
            <Dimmer active={isDimmerActive} inverted>
              <Loader />
            </Dimmer>
            <div className="profileSetup-steps">{step}</div>
          </Dimmer.Dimmable>
        </div>
      )}
    </FormWrapper>
  );
}

export default ProfileSetup;
