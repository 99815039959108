const initialState = {
    data: [
      {
        title: "",
        documents: [],
        toolsUsed: [],
        description: "",
      },
    ],
  };
  
  const getPortfolio = (state = initialState, action) => {
    switch (action.type) {
      case "ADD_PORTFOLIO":
        const newExp = {
          title: "",
          documents: [],
          toolsUsed: [],
          description: "",
        };
        const newData = state.data;
        newData.push(newExp);
        return {
          ...state,
          data: newData,
        };
      case "SET_PORTFOLIO":
        return {
          ...state,
          data: action.payload
        }
      case "CHANGE_PORTFOLIO":
        const index = action.payload.index;
        const changeData = action.payload;
        delete changeData["index"]
        state.data[index] = changeData;
        return {
          ...state,
          data: state.data
        };
      case "REMOVE_PORTFOLIO":
        const removeData = state.data;
        removeData.splice(action.payload, 1);
        return {
          ...state,
          data: state.data,
        };
      default:
        return state;
    }
  };
  
  export default getPortfolio;
  