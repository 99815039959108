import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Input, Dropdown, Button, Form } from "semantic-ui-react";

import { EMPLOYEE_STRENGTH } from "../../constants";
import { getAllSkills } from "../../actions/index";

import "./Step2Client.scss";

function Step2Client({
  companyName,
  companyWebsite,
  employeeStrength,
  keyClients,
  companyTags,
  companyLinkedin,
  onChange,
  handlePreviousStep,
  isSubmitting,
  handleSubmitForm,
}) {
  const [skillsOption, setskillsOption] = useState();

  const handleOnChange = React.useCallback(
    (e, data) => {
      onChange(e.target.name, e.target.value);
    },
    [onChange]
  );

  useEffect(() => {
    getAllSkills()
      .then((response) => {
        const skills = [];
        response.data.data.map(({ skill, _id }) =>
          skills.push({ text: skill, value: _id })
        );

        setskillsOption(skills);
      })
      .catch((err) => console.log("ERROR OCCURED!", err));
  }, []);

  return (
    <div className="profileStep1-wrapper">
      <div className="profileStep1-fields">
        <Input
          name="companyName"
          placeholder="Enter your company name"
          value={companyName}
          spellCheck={false}
          onChange={handleOnChange}
          fluid
        />
        <label htmlFor="companyName">Company Name</label>
      </div>
      <div className="profileStep1-fields">
        <Form className="profileStep1-textarea">
          <Input
            name="companyWebsite"
            placeholder="https://example.com"
            value={companyWebsite}
            spellCheck={false}
            onChange={handleOnChange}
            fluid
          />
        </Form>
        <label htmlFor="companyWebsite">Company Website</label>
      </div>
      <div className="profileStep1-fields">
        <Dropdown
          name="employeeStrength"
          options={EMPLOYEE_STRENGTH}
          value={employeeStrength}
          placeholder="Select employee strength of your company"
          onChange={(e, data) => onChange("employeeStrength", data.value)}
          fluid
          search
          selection
        />
        <label htmlFor="employeeStrength">Employee Strength</label>
      </div>
      <div className="profileStep1-fields">
        <Input
          name="keyClients"
          placeholder="Add your key clients. Ex - Google, Facebook"
          onChange={handleOnChange}
          value={keyClients}
          fluid
        />
        <label htmlFor="keyClients">Key Clients</label>
      </div>
      <div className="profileStep1-fields">
        <Dropdown
          name="companyTags"
          placeholder="Select tags related to the company"
          onChange={(e, data) => onChange(data.name, data.value)}
          options={skillsOption}
          value={companyTags}
          fluid
          selection
          multiple
        />
        <label htmlFor="name">Add company tags</label>
      </div>
      <div className="profileStep1-fields">
        <Input
          name="companyLinkedin"
          placeholder="Add your company linkedin profile"
          value={companyLinkedin}
          onChange={handleOnChange}
          fluid
        />
        <label htmlFor="name">Company Linkedin Profile</label>
      </div>
      <div className="step3-buttons">
        <Button className="alternative" onClick={handlePreviousStep}>
          Previous
        </Button>
        <Button
          className="primary"
          onClick={() => handleSubmitForm()}
          loading={isSubmitting}
        >
          Submit
        </Button>
      </div>
    </div>
  );
}

Step2Client.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  skills: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
  handlePreviousStep: PropTypes.func.isRequired,
  handleNextStep: PropTypes.func.isRequired,
};

Step2Client.defaultProps = {
  title: "",
  description: "",
  skills: [],
  formErrors: {},
};

export default Step2Client;
