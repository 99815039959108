import React from "react";
import PropTypes from "prop-types";

import "./Card.scss";

function Card({
  customClassName,
  heading,
  details,
  selected,
  onClick,
  id,
  children,
}) {
  const selectedClassName = selected ? "selected" : "";
  return (
    <div
      className={`card ${selectedClassName} ${customClassName}`}
      onClick={() => onClick(id)}
    >
      {children || (
        <>
          <h4 className="card-heading">{heading}</h4>
          <p className="card-details">{details}</p>
        </>
      )}
    </div>
  );
}

Card.propTypes = {
  customClassName: PropTypes.string,
  heading: PropTypes.string,
  details: PropTypes.string,
  selected: PropTypes.bool,
  onClick: PropTypes.string,
  id: PropTypes.string,
};

Card.defaultProps = {
  customClassName: "",
  heading: "",
  details: "",
  selected: false,
  onClick: () => {},
  id: "",
};

export default Card;
