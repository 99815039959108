export const ACTIVATE_ACCOUNT = `${process.env.REACT_APP_API_BASE_URL}api/auth/account-activation`;
export const SIGNUP_URL = `${process.env.REACT_APP_API_BASE_URL}api/auth/signup`;
export const LOGIN_URL = `${process.env.REACT_APP_API_BASE_URL}api/auth/login`;
export const LOGIN_GOOGLE_URL = `${process.env.REACT_APP_API_BASE_URL}api/auth/google/login`;
export const LOGIN_FACEBOOK_URL = `${process.env.REACT_APP_API_BASE_URL}api/auth/facebook/login`;
export const SIGNUP_GOOGLE_URL = `${process.env.REACT_APP_API_BASE_URL}api/auth/google/signup`;
export const SIGNUP_FACEBOOK_URL = `${process.env.REACT_APP_API_BASE_URL}api/auth/facebook/signup`;

export const SEND_RESET_PASSWORD_EMAIL = `${process.env.REACT_APP_API_BASE_URL}api/auth/reset/email`;
export const SET_RESET_PASSWORD = `${process.env.REACT_APP_API_BASE_URL}api/auth/reset/password`;
