import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Loader, Tab } from "semantic-ui-react";
import { ToastContainer, toast } from "react-toastify";

import ROUTES from "constants/Routes";
import { PROPOSAL_STATUS } from "constants/Proposal";

import JobDetailBody from "./components/JobDetailBody";
import JobDetailSidebar from "./components/JobDetailSidebar";
import JobDetailProposals from "./components/JobDetailProposals";

import {
  getJobPost,
  getJobProposal,
  acceptRejectProposal,
  createContract,
  deactivateJob,
} from "./actions";
import { PROPOSAL_ACTIONS } from "./constants";
import { stateToProps } from "./maps";
import "./JobDetail.scss";

const getPanes = ({ isClient, job, proposals, handleAcceptReject, userId }) => [
  {
    menuItem: "Job Details",
    render: () => (
      <Tab.Pane className="jobDetail-tabs-pane" attached={false}>
        <JobDetailBody isClient={isClient} job={job} />
      </Tab.Pane>
    ),
  },
  {
    menuItem: `Proposals (${proposals.length})`,
    render: () => (
      <Tab.Pane className="jobDetail-tabs-pane" attached={false}>
        <JobDetailProposals
          job={job}
          proposals={proposals}
          handleAcceptReject={handleAcceptReject}
          userId={userId}
        />
      </Tab.Pane>
    ),
  },
];

function JobDetail({ jobId }) {
  const { id } = useParams();
  const history = useHistory();
  const { isClient, userId } = useSelector(stateToProps);
  const [job, setJob] = React.useState({ category: {}, skills: [] });
  const [proposals, setProposals] = React.useState([]);
  const [isFetching, setIsFetching] = React.useState(false);
  const [isProposalFetching, setIsProposalFetching] = React.useState(false);

  const handleOnSubmit = React.useCallback(() => {
    if (isClient) {
      setIsFetching(true);

      deactivateJob(userId, id || jobId)
        .then(() => {
          setJob((job) => ({ ...job, active: false }));
          setIsFetching(false);
          toast.success("Job deactivated!");
        })
        .catch((error) => {
          setIsFetching(false);
          try {
            toast.error(`Error occurred! ${error.response.data.error}`);
          } catch (e) {
            toast.error(`Error occurred!`);
          }
        });
      return;
    }

    window.open(
      `${ROUTES.JOB_POST_APPLY.replace(":id", id || jobId)}`,
      "_blank"
    );
  }, [id, isClient, userId, jobId]);

  React.useEffect(() => {
    if ((!id && !jobId) || isFetching) return;

    setIsFetching(true);

    getJobPost(id || jobId)
      .then((response) => {
        setIsFetching(false);

        if (response.data.redirect) {
          const { path, _id } = response.data.additionalInfo;

          if (path === "proposal") {
            history.push(ROUTES.JOB_PROPOSAL.replace(":id", _id));
          } else if (path === "contract") {
            history.push(ROUTES.JOB_POST_HIRE.replace(":id", _id));
          }
        }

        setJob({ ...response.data.post });

        if (isClient && userId === response.data.post.ownerId) {
          setIsProposalFetching(true);

          const query = { userId };
          getJobProposal(id || jobId, query)
            .then((response) => {
              setIsProposalFetching(false);
              setProposals(response.data.proposals || []);
            })
            .catch((error) => {
              setIsProposalFetching(false);
              try {
                toast.error(`Error occurred! ${error.response.data.error}`);
              } catch (e) {
                toast.error(`Error occurred!`);
              }
            });
        }
      })
      .catch((error) => {
        setIsFetching(false);
        try {
          toast.error(`Error occurred! ${error.response.data.error}`);
        } catch (e) {
          toast.error(`Error occurred!`);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobId, id, userId]);

  const handleAcceptReject = (
    action,
    proposalId,
    freelanceId,
    bidValue,
    cb = () => {}
  ) => {
    const data = {
      action,
      userId,
      jobId: id || jobId,
      proposalId,
      bidValue,
      freelanceId,
    };

    switch (action) {
      case PROPOSAL_ACTIONS.ACCEPT:
        createContract(data)
          .then((response) =>
            history.push(
              ROUTES.JOB_POST_HIRE.replace(":id", response.data.contractId)
            )
          )
          .catch((error) => {
            try {
              toast.error(`Error occurred! ${error.response.data.error}`);
            } catch (e) {
              toast.error(`Error occurred!`);
            }

            cb();
          });
        break;

      case PROPOSAL_ACTIONS.REJECT:
        acceptRejectProposal(proposalId, data)
          .then(() => {
            let updatedProposals = proposals;
            updatedProposals = proposals.map((proposal) =>
              proposal._id === proposalId
                ? { ...proposal, status: PROPOSAL_STATUS.REJECTED }
                : proposal
            );

            setProposals(updatedProposals);
            toast.success(`Proposal rejected! `);
            cb();
          })
          .catch((error) => {
            try {
              toast.error(`Error occurred! ${error.response.data.error}`);
            } catch (e) {
              toast.error(`Error occurred!`);
            }

            cb();
          });
        break;

      default:
        break;
    }
  };

  const isOwnerClient = isClient && userId === job.ownerId;

  return (
    <div className="jobDetail-wrapper">
      <ToastContainer
        className="toast"
        position="bottom-center"
        autoClose={2500}
        hideProgressBar
      />
      <Loader active={isFetching} />
      {!isFetching && !isProposalFetching && (
        <div className="jobDetail">
          {isOwnerClient ? (
            <Tab
              className="jobDetail-tabs"
              menu={{
                secondary: false,

                fluid: true,
              }}
              panes={getPanes({
                isClient,
                job,
                proposals,
                handleAcceptReject,
                userId,
              })}
            />
          ) : (
            <JobDetailBody
              isClient={isClient}
              job={job}
              proposals={proposals}
            />
          )}
          <JobDetailSidebar
            job={job}
            isClient={isClient}
            isOwner={isOwnerClient}
            onSubmit={handleOnSubmit}
          />
        </div>
      )}
    </div>
  );
}

export default JobDetail;
