import { authorizedPost, authorizedGet } from "api";
import { USER_QUERY } from "api/user";
import { GET_NOTIFICATION, DISMISS_NOTIFICATION } from "api/notification";

export const raiseQuery = (id, data) =>
  authorizedPost(`${USER_QUERY.replace(":id", id)}`, data);

export const getNotifications = (userId) =>
  authorizedGet(`${GET_NOTIFICATION.replace(":userId", userId)}`);

export const dismissNotification = (notificationId) =>
  authorizedPost(
    `${DISMISS_NOTIFICATION.replace(":notificationId", notificationId)}`
  );
