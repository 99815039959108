import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { Loader, Label } from "semantic-ui-react";

import ROUTES from "constants/Routes";
import { firstLetterCapitalise } from "utils";
import { getProposalStatusColor } from "utils/job";

import SimpleCard from "components/SimpleCard";

import { getSubmittedProposals } from "../../actions";
import { stateToProps } from "../../maps";
import "./SubmittedProposal.scss";

function SubmittedProposal() {
  const history = useHistory();

  const { isClient, userId } = useSelector(stateToProps);
  const [isFetching, setIsFetching] = React.useState(false);
  const [proposals, setProposals] = React.useState([]);

  React.useEffect(() => {
    if (isClient) return;

    const query = { userId };

    setIsFetching(true);

    getSubmittedProposals(query)
      .then((response) => {
        setIsFetching(false);
        setProposals(response.data.proposals || []);
      })
      .catch((error) => {
        setIsFetching(false);
        try {
          toast.error(
            `Fetching proposals failed! ${error.response.data.error}`
          );
        } catch (e) {
          toast.error(`Fetching proposals failed!`);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openJobProposal = React.useCallback(
    (id) => history.push(ROUTES.JOB_PROPOSAL.replace(":id", id)),
    [history]
  );

  return (
    <SimpleCard heading="Proposals">
      <ToastContainer
        className="toast"
        position="bottom-center"
        autoClose={2500}
        hideProgressBar
      />
      <Loader active={isFetching} />
      <SimpleCard.Body>
        {proposals.length ? (
          proposals.map((proposal) => {
            const createdDate = new Date(proposal.createdAt);

            return (
              <div
                className="submittedProposal"
                onClick={() => openJobProposal(proposal._id)}
              >
                <p className="submittedProposal-date">
                  Initiated on
                  <br />
                  {`${createdDate.toDateString()} ${createdDate.getHours()}:${createdDate.getMinutes()}:${createdDate.getSeconds()}`}
                </p>
                <p className="submittedProposal-bid">
                  Bid<br />
                  {proposal.bid}
                </p>
                <p className="submittedProposal-title">
                  {proposal.jobId.title}
                </p>
                <div className="submittedProposal-status">
                  <Label color={getProposalStatusColor(proposal.status)}>
                    {firstLetterCapitalise(proposal.status)}
                  </Label>
                </div>
              </div>
            );
          })
        ) : (
          <div>Hi, Looks like you're yet to submit a proposal!</div>
        )}
      </SimpleCard.Body>
    </SimpleCard>
  );
}

export default SubmittedProposal;
