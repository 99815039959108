import { SET_PROFILE } from "actions/user";

const initialState = {
  title: "",
  description: "",
  skills: [],
  imageUrl: "",
  experience: [],
  experienceLevel: 1,
  provider: null,
};

export default function profileReducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_PROFILE:
      return { ...state, ...action.data };

    default:
      return state;
  }
}
