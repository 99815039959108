import { authorizedPost, authorizedGet } from "api";
import {
  FILE_UPLOAD,
  SUBMIT_NEW_JOB,
  GET_ALL_CATEGORIES,
  GET_ALL_SKILLS,
} from "api/job";

export const fileUploadApi = ({ data }) => {
  return authorizedPost(FILE_UPLOAD, data, {
    "Content-Type": "multipart/form-data",
  });
};

export const submitJobPostApi = (data) => {
  return authorizedPost(SUBMIT_NEW_JOB, data);
};

export const getAllCategory = () => {
  return authorizedGet(GET_ALL_CATEGORIES);
};

export const getAllSkills = () => {
  return authorizedGet(GET_ALL_SKILLS);
};
