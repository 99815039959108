import React from "react";
import PropTypes from "prop-types";
import { Input, Pagination } from "semantic-ui-react";

import JobView from "../JobView";

import "./JobList.scss";

function JobList({
  children,
  activePage,
  totalPages,
  jobPosts,
  handleOnClick,
  handlePageChange,
  handleOnSearch,
}) {
  return (
    <div className="jobList">
      {children}
      <Input
        fluid
        action={{ icon: "search" }}
        placeholder="Search Jobs..."
        onChange={handleOnSearch}
      />
      <div className="jobList-list">
        <div className="jobList-list-wrapper">
          {jobPosts.map((job) => (
            <JobView onClick={handleOnClick} job={job} />
          ))}
        </div>
      </div>
      <Pagination
        className="jobList-pagination"
        siblingRange={1}
        firstItem={null}
        lastItem={null}
        activePage={activePage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
    </div>
  );
}

JobList.propTypes = {
  children: PropTypes.node,
  activePage: PropTypes.number,
  totalPages: PropTypes.number,
  jobPosts: PropTypes.arrayOf(PropTypes.shape({})),
  handleOnClick: PropTypes.func.isRequired,
  handlePageChange: PropTypes.func.isRequired,
};

JobList.defaultProps = {
  children: null,
  activePage: 1,
  totalPages: 1,
  jobPosts: [],
};

export default JobList;
