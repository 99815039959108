import React from "react";
import { Link } from "react-router-dom";

import "./Categories.scss";

const CATEGORIES = [
  {
    heading: "Content Writing",
    text: "Blogs, Website Content, Product Descriptions, A+ Content, Ad copies, Emailer Copies, Whitepapers and others.",
  },
  {
    heading: "Web/App Development",
    text: "Node, React, Java, Net, Php, html, Wordpress, Angular, Salesforce, Devops, GCP and others.",
  },
  {
    heading: "Design",
    text: "Ui/UX designing, social media posters, website banners, newsletter designing, business card, stationary & branding designing.",
  },
  {
    heading: "Animation Videos",
    text: "2D & 3D Product explainer videos (infographic & motions graphic), whiteboard animation, video editing and other video related task.",
  },
  {
    heading: "Photography",
    text: "Ecommerce photoshoot, product photoshoot, branding videos, testimonial & interview videos, company profile & other camera related task.",
  },
  {
    heading: "Digital Marketing",
    text: "Lead generation campaign, social media marketing & management, SEO, Google Ads and other marketing related activities.",
  },
  {
    heading: "Miscellaneous/Others",
    text: "Wikipedia pages, Google Knowledge Panel, IMDB Profiles, and other miscellaneous task which can't be defined in a category.",
  },
  {
    heading: "Translation",
    text: "Tamil, Telugu, Spanish, German, French Kannada, Oriya, Punjabi, Marathi & 14 other Indian & foreign language translation.",
  },
];

function Categories() {
  return (
    <div className="categories">
      <div className="categories-wrapper">
        <h2 className="categories-title">Find your freelancer or agency</h2>
        {/* <p className="categories-subHeading">Professional by categories</p> */}
        <div className="categories-types-grid">
          {CATEGORIES.map((category) => (
            <Link to={`/login`} key={category.heading}>
              <div key={category.id} className="categories-types-item">
                <div className="categories-types-item-image">
                  <div>{category.text}</div>
                </div>
                <p className="categories-types-item-title">
                  {category.heading}
                </p>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Categories;
