import React from "react";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";

import PasswordInput from "components/PasswordInput";
import Section from "components/Section";

import { resetPasswordData } from "../../actions";
import { stateToProps } from "../../maps";

import "./PasswordSettings.scss";

function PasswordSettings() {
  const [oldPassword, setOldPassword] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const { email } = useSelector(stateToProps);

  const handleOnChange = React.useCallback((e, data) => {
    const { name, value } = data;
    switch (name) {
      case "oldPassword":
        setOldPassword(value);
        break;
      case "newPassword":
        setNewPassword(value);
        break;
      default:
        setConfirmPassword(value);
    }
  }, []);

  const updatePassword = React.useCallback(
    (cb) => {
      if (newPassword !== confirmPassword) {
        toast.error(
          "New passwords do not match. Please re-enter the new password."
        );
        return;
      }

      setLoading(true);

      resetPasswordData({
        email: email,
        oldPassword: oldPassword,
        newPassword: newPassword,
      })
        .then(() => {
          setLoading(false);
          toast.success("Password successfully updated!");
          cb();
        })
        .catch((error) => {
          setLoading(false);
          console.error(error);
          try {
            toast.error(error.response.data.error);
          } catch (e) {
            toast.error(`Password change failed! Try again`);
          }
        });
    },
    [email, oldPassword, newPassword, confirmPassword]
  );

  return (
    <>
      <ToastContainer
        className="toast"
        position="bottom-center"
        autoClose={2500}
        hideProgressBar
      />
      <Section>
        <Section.Header heading="Security" />
        <Section.ReadOnly>
          <div className="item">
            <h4 className="heading">Password</h4>
            <p className="para">******</p>
          </div>
        </Section.ReadOnly>
        <Section.Editable onUpdate={updatePassword} isUpdating={loading}>
          <div className="field">
            <label>Old Password</label>
            <PasswordInput
              name="oldPassword"
              className="maxWidth-60"
              placeholder="Enter old password"
              value={oldPassword}
              onChange={handleOnChange}
            />
          </div>
          <div className="field">
            <label>New Password</label>
            <PasswordInput
              name="newPassword"
              className="maxWidth-60"
              placeholder="Enter new password"
              value={newPassword}
              onChange={handleOnChange}
            />
          </div>
          <div className="field">
            <label>Confirm New Password</label>
            <PasswordInput
              name="confirmPassword"
              className="maxWidth-60"
              placeholder="Confirm your new password"
              value={confirmPassword}
              onChange={handleOnChange}
            />
          </div>
        </Section.Editable>
      </Section>
    </>
  );
}

export default PasswordSettings;
